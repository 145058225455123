import React from "react";

import TopHead from "../Layouts/TopHead";
import Sidemenu from "../Layouts/Sidemenu";
import TableGrid from "../../components/TableGrid";
import LoadingBackdrop from "../../components/LoadingBackdrop";
import {
  Input,
  Button,
  ButtonError,
  ButtonSuccess,
  ButtonBack,
} from "../../components/Form";

import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Config from "../../config/Config";
import Api from "../../services/Api";

function DiscountEdit() {
  const navigate = useNavigate();

  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [discountPercentage, setDiscountPercentage] = useState("");
  const [productType, setProductType] = useState("All");

  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [productFilteredList, setProductFilteredList] = useState([]);

  const [customerGroups, setCustomerGroups] = useState([]);
  const [customerGroup, setCustomerGroup] = useState("All");

  const [filter, setFilter] = useState("");

  const getDiscount = async () => {
    setLoading(true);

    const response = await Api.get(Config.api_url + "/data/discount/" + id);
    setName(response.name);
    setDiscountPercentage(response.discount_percentage);
    setProductType(response.product_type);
    setCustomerGroup(response.customer_group_id);

    let selectedProducts = [];
    response.items.map((item) => {
      selectedProducts.push(item.product);
    });
    setSelectedProducts(selectedProducts);

    setLoading(false);
  };

  const getCustomerGroups = async () => {
    setLoading(true);

    const response = await Api.get(Config.api_url + "/data/customergroup");
    setCustomerGroups(response);
    setLoading(false);
  };

  useEffect(() => {
    getCustomerGroups();
    getDiscount();
  }, []);

  const filterProductList = (e) => {
    const response = Api.get(
      Config.api_url + "/data/product/?page=1&filter=" + e.target.value
    ).then((response) => {
      console.log(response);
      setProducts(response);
      setProductFilteredList(response);
    });
  };

  const submit = () => {
    setLoading(true);

    // save put discount
    Api.put(Config.api_url + "/data/discount/" + id, {
      name: name,
      discount_percentage: discountPercentage,
      product_type: productType,
      customer_group_id: customerGroup,
      products: selectedProducts,
    }).then((response) => {
      console.log(response);

      if (response) {
        toast.success("Diskon berhasil dibuat");
        navigate("/discount");
      } else {
        toast.error("Gagal membuat diskon");
      }
      setLoading(false);
    });
  };

  const autocompleteOn = () => {
    // search product
    const response = Api.get(
      Config.api_url + "/data/product/?page=1&filter=" + filter
    ).then((response) => {
      console.log(response);
      setProducts(response);
      setProductFilteredList(response);
    });

    const dropdown = document.querySelector(`#autocomplete`);
    dropdown.classList.remove("hidden");
  };

  const autocompleteOff = () => {
    setTimeout(() => {
      const dropdown = document.querySelector(`#autocomplete`);
      dropdown.classList.add("hidden");
    }, 50);
  };

  const addItem = (item) => {
    const newSelectedProducts = [...selectedProducts];
    newSelectedProducts.push(item);
    setSelectedProducts(newSelectedProducts);
  };

  const removeItem = (id) => {
    const newItems = selectedProducts.filter((item) => item.id !== id);
    setSelectedProducts(newItems);
  };

  return (
    <div>
      {/* side menu tailwind */}
      <TopHead />
      <ToastContainer />
      <div className="flex flex-col md:flex-row h-screen">
        <Sidemenu />
        <div className="flex-1 overflow-hidden">
          <div className="p-4 pt-16">
            <div className="flex justify-between items-center mb-4">
              <div>
                <h3 className="py-2 font-bold text-rose-700">Tambah diskon</h3>
              </div>
            </div>

            <div className="flex gap-8">
              <div className="w-1/4">
                <Input
                  label="Nama"
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />

                <label className="block py-2 text-sm">Cakupan diskon</label>
                <select
                  onChange={(e) => setProductType(e.target.value)}
                  className="w-full block px-2 py-1 border border-gray-300 text-sm rounded-md focus:outline-none focus:border-rose-500"
                >
                  <option
                    value="All"
                    selected={productType == "All" ? "selected" : ""}
                  >
                    Semua produk
                  </option>
                  <option
                    value="Selection"
                    selected={productType == "Selection" ? "selected" : ""}
                  >
                    Pilihan
                  </option>
                </select>

                <label className="block py-2 text-sm">Berlaku untuk</label>
                <select
                  onChange={(e) => setCustomerGroup(e.target.value)}
                  defaultValue={customerGroup}
                  className="w-full block px-2 py-1 border border-gray-300 text-sm rounded-md focus:outline-none focus:border-rose-500"
                >
                  <option value="All">Semua Grup pelanggan</option>

                  {customerGroups.map((item) => {
                    return (
                      <option
                        value={item.id}
                        selected={item.id == customerGroup ? "selected" : ""}
                      >
                        {item.name}
                      </option>
                    );
                  })}
                </select>

                <Input
                  label="Nominal diskon"
                  type="number"
                  value={discountPercentage}
                  onChange={(e) => setDiscountPercentage(e.target.value)}
                />

                <div className="py-2"></div>
                {loading ? (
                  <LoadingBackdrop />
                ) : (
                  <>
                    <ButtonBack
                      label="Kembali"
                      onClick={() => {
                        navigate("/discount");
                      }}
                    />
                    <ButtonSuccess label="Simpan" onClick={submit} />
                  </>
                )}
              </div>

              {productType === "Selection" && (
                <div className="grow">
                  <div className="flex flex-col md:flex-row">
                    <div className="w-full md:w-2/4 pr-4">
                      <label className="block py-2 text-sm">
                        Tambah pilihan produk
                      </label>

                      <input
                        type="text"
                        onFocus={autocompleteOn}
                        onChange={(e) => filterProductList(e)}
                        onBlur={autocompleteOff}
                        placeholder="Cari nama produk untuk menambahkan item"
                        className="w-full px-2 py-1 border border-gray-300 text-sm rounded-md focus:outline-none focus:border-rose-500"
                      />

                      <div id="autocomplete" className="relative hidden">
                        <div className="absolute w-full overflow-y-scroll h-48 top-100 p-4 border  text-xs rounded z-10 bg-white">
                          <ul>
                            {productFilteredList.length > 0 &&
                              productFilteredList.map((item) => (
                                <li className="py-1 ">
                                  <button
                                    class="flex flex-col text-indigo-800"
                                    onClick={() => {
                                      addItem(item);
                                    }}
                                  >
                                    <div className="flex justify-start items-center">
                                      <img
                                        src={Config.api_url + "/" + item.image}
                                        width="30"
                                        className="mr-2"
                                      />{" "}
                                      {item.name}
                                    </div>
                                  </button>
                                </li>
                              ))}
                          </ul>
                          <div className="flex justify-end">
                            <button
                              onClick={autocompleteOff}
                              className="bg-gray-200 px-2 py-1 rounded text-xs"
                            >
                              Tutup
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="border h-80  w-3/4 mt-4">
                    <table className="table-auto text-xs w-full">
                      <thead className="bg-gray-100">
                        <tr>
                          <th className="w-2 px-4 py-2">No</th>
                          <th className="w-48 text-left px-4 py-2">Nama</th>
                          <th className="w-32 text-left  px-4 py-2">SKU</th>

                          <th className="w-32 px-4 py-2">Aksi</th>
                        </tr>
                      </thead>

                      <tbody>
                        {selectedProducts.map((item, index) => (
                          <tr className="">
                            <td className="text-center  px-4">{index + 1}</td>
                            <td className=" px-4">{item.name}</td>
                            <td className=" px-4">{item.sku}</td>

                            <td className=" px-4 text-center">
                              <button
                                onClick={() => removeItem(item.id)}
                                className="bg-red-500 text-xs hover:bg-red-700 text-white font-bold py-1 px-2 rounded"
                              >
                                Hapus
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DiscountEdit;

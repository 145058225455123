import React from "react";

import TopHead from "../Layouts/TopHead";
import Sidemenu from "../Layouts/Sidemenu";
import { ToastContainer } from "react-toastify";

import { useState, useEffect } from "react";

import Select from "react-select";

import Config from "../../config/Config";
import Api from "../../services/Api";

import {
  Input,
  Button,
  ButtonError,
  ButtonSuccess,
  ButtonBack,
  ButtonGray,
} from "../../components/Form";

function AccountingSetting() {
  const [loading, setLoading] = useState(false);
  const [coa, setCoa] = useState([]);

  useEffect(() => {
    setLoading(true);

    // show data by id
    Api.get(Config.api_url + "/data/coa?limit=100").then((response) => {
      console.log(response);
      setCoa(response);
      setLoading(false);
    });
  }, []);

  return (
    <div>
      {/* side menu tailwind */}
      <TopHead />
      <ToastContainer />
      <div className="flex flex-col md:flex-row h-screen">
        <Sidemenu />
        <div className="flex-1 overflow-hidden">
          <div className="p-8 pt-12"></div>

          <div className="p-4 pt-2">Pengaturan akunting</div>

          <div className="p-4">
            <ButtonGray label="Penjualan" />
            <Button label="Pembelian" />
            <Button label="Metode Pembayaran" />
          </div>

          <div className="px-4 w-1/2">
            <div className="text-sm text-gray-600 py-2">Akun Penjualan</div>
            <Select
              options={coa}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              onChange={(e) => {}}
              value={coa}
              styles={{
                control: (base) => ({
                  ...base,
                  height: "36px",
                  minHeight: "36px",
                  // marginTop: "15px",
                  padding: "0 10px",
                  fontSize: "12px",
                  alignItems: "center",
                  borderRadius: "5px",
                }),
              }}
            />
            <div className="text-sm text-gray-600 py-2">
              Akun Diskon Penjualan
            </div>
            <Select
              options={coa}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              onChange={(e) => {}}
              value={coa}
              styles={{
                control: (base) => ({
                  ...base,
                  height: "36px",
                  minHeight: "36px",
                  // marginTop: "15px",
                  padding: "0 10px",
                  fontSize: "12px",
                  alignItems: "center",
                  borderRadius: "5px",
                }),
              }}
            />
            <div className="text-sm text-gray-600 py-2">
              Akun Pajak Keluaran Penjualan
            </div>
            <Select
              options={coa}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              onChange={(e) => {}}
              value={coa}
              styles={{
                control: (base) => ({
                  ...base,
                  height: "36px",
                  minHeight: "36px",
                  // marginTop: "15px",
                  padding: "0 10px",
                  fontSize: "12px",
                  alignItems: "center",
                  borderRadius: "5px",
                }),
              }}
            />
            <div className="text-sm text-gray-600 py-2">
              Akun Uang Muka Penjualan
            </div>
            <Select
              options={coa}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              onChange={(e) => {}}
              value={coa}
              styles={{
                control: (base) => ({
                  ...base,
                  height: "36px",
                  minHeight: "36px",
                  // marginTop: "15px",
                  padding: "0 10px",
                  fontSize: "12px",
                  alignItems: "center",
                  borderRadius: "5px",
                }),
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AccountingSetting;

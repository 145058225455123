import React, { Component } from "react";

import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import LoadingBackdrop from "../../components/LoadingBackdrop";

import Api from "../../services/Api";
import Config from "../../config/Config";

function SalesReceipt() {
  const { id } = useParams();

  const [loading, setLoading] = useState(true);

  const [order, setOrder] = useState({});

  const getSalesDetail = async (id) => {
    setLoading(true);
    const response = await Api.get(Config.api_url + "/data/sales/" + id);

    setOrder(response);
    setLoading(false);
    console.log(response);
  };

  useEffect(() => {
    getSalesDetail(id);

    setTimeout(() => {
      window.print();
    }, 1000);
  }, []);

  return (
    <>
      {!loading && (
        <>
          <div className="print flex justify-start items-center px-4 py-2 gap-2"></div>
          <div className="px-4 py-2 text-sm">
            <div className="flex w-full">
              <div className="w-1/2 pr-4">No Pesanan</div>
              <div className="w-1/2 pr-4">{order.number}</div>
            </div>
            <div className="flex w-full">
              <div className="w-1/2 pr-4">Pelanggan</div>
              <div className="w-1/2 pr-4">{order.total}</div>
            </div>
            <div className="flex w-full">
              <div className="w-1/2 pr-4">Gudang/Toko</div>
              <div className="w-1/2 pr-4">{order.warehouse.name}</div>
            </div>
            <div className="flex w-full">
              <div className="w-1/2 pr-4">Pelanggan</div>
              <div className="w-1/2 pr-4">{order.customer.name}</div>
            </div>
          </div>

          <div className="px-4 py-2 mt-4 text-sm border-t ">
            <div className="flex w-full">
              <div className="w-1/2 pr-4 font-bold">Item</div>
            </div>
            {order.items.map((item, index) => (
              <div className="flex w-full py-1">
                <div className="w-1/2 pr-4">
                  {item.product.name}
                  <div className="text-xs">
                    {item.qty} x {item.price}{" "}
                    {item.discount_percentage > 0 &&
                      "(Disc " + item.discount_percentage + "%)"}
                  </div>
                </div>
                <div className="w-1/2 pr-4 text-right">{item.subtotal}</div>
              </div>
            ))}
          </div>

          <div className="px-4 py-1 flex text-sm ">
            <div className=" w-1/2 font-bold">Subtotal</div>
            <div className="w-1/2 pr-4 text-right">{order.subtotal}</div>
          </div>
          <div className="px-4 flex text-sm ">
            <div className=" w-1/2 ">Subtotal</div>
            <div className="w-1/2 pr-4 text-right">{order.subtotal}</div>
          </div>
          <div className="px-4 flex text-sm ">
            <div className=" w-1/2 ">
              Diskon tambahan
              {order.discount_percentage > 0 && (
                <span className="text-xs"> ({order.discount_percentage}%)</span>
              )}
            </div>
            <div className="w-1/2 pr-4 text-right">{order.discount_amount}</div>
          </div>
          <div className="px-4 flex text-sm ">
            <div className=" w-1/2 ">
              Pajak
              {order.tax_percentage > 0 && (
                <span className="text-xs"> ({order.tax_percentage}%)</span>
              )}
            </div>
            <div className="w-1/2 pr-4 text-right">{order.tax_amount}</div>
          </div>
          <div className="px-4 mt-4 py-4 flex text-sm border-t ">
            <div className=" w-1/2 font-bold">Total</div>
            <div className="w-1/2 pr-4 text-right">{order.total}</div>
          </div>
        </>
      )}
    </>
  );
}

export default SalesReceipt;

import React from "react";

import { useLocation } from "react-router-dom";

function Sidemenu() {
  const location = useLocation();

  const dropdown = (menu) => {
    const dropdown = document.querySelector(`#sub-${menu}`);
    dropdown.classList.toggle("hidden");
  };

  const pathname = location.pathname.split("/")[1];

  return (
    <div className="bg-red-700 text-gray-100 flex flex-col justify-between md:w-64 overflow-auto">
      <div className="p-4 flex flex-col justify-between md:w-64 pt-8  ">
        <div className="overflow-auto">
          <ul className="mt-6">
            <li className="relative">
              <a
                href="/dashboard"
                className="p-2 inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 hover:bg-gray-100 rounded">
                <span>Dashboard</span>
              </a>
            </li>

            <li className="py-2 border-b border-gray-600 mb-4"></li>

            <li className="relative">
              <a
                href="#"
                onClick={() => {
                  dropdown("invoice");
                }}
                className="p-2 inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 hover:bg-gray-100 rounded">
                <span>Penjualan</span>
                {/* arrow down */}
                <svg
                  className="w-4 h-4 ml-auto"
                  id="arrow"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"></path>
                </svg>
              </a>
              <ul
                className={
                  pathname === "sales" || pathname == "pos"
                    ? "px-2 dropdown"
                    : "px-2 dropdown hidden"
                }
                id="sub-invoice">
                <li className="relative">
                  <a
                    href="/sales"
                    className="p-2 inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 hover:bg-gray-100 rounded">
                    <span>Daftar penjualan</span>
                  </a>
                </li>
                <li className="relative">
                  <a
                    href="/pos"
                    className="p-2 inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 hover:bg-gray-100 rounded">
                    <span>POS</span>
                  </a>
                </li>
              </ul>
            </li>
            <li className="relative">
              <a
                href="#"
                onClick={() => {
                  dropdown("purchase");
                }}
                className="p-2 inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 hover:bg-gray-100 rounded">
                <span>Pembelian</span>
                {/* arrow down */}
                <svg
                  className="w-4 h-4 ml-auto"
                  id="arrow"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"></path>
                </svg>
              </a>
              <ul
                className={
                  pathname === "purchaseorder" ||
                  pathname == "packinglist" ||
                  pathname == "purchasing"
                    ? "px-2 dropdown"
                    : "px-2 dropdown hidden"
                }
                id="sub-purchase">
                <li className="relative">
                  <a
                    href="/purchaseorder"
                    className="p-2 inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 hover:bg-gray-100 rounded">
                    <span>Purchase order (PO)</span>
                  </a>
                </li>
                <li className="relative">
                  <a
                    href="/packinglist"
                    className="p-2 inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 hover:bg-gray-100 rounded">
                    <span>Packing List</span>
                  </a>
                </li>
                <li className="relative">
                  <a
                    href="/purchasing"
                    className="p-2 inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 hover:bg-gray-100 rounded">
                    <span>Pembelian</span>
                  </a>
                </li>
              </ul>
            </li>

            <li className="relative">
              <button
                onClick={() => {
                  dropdown("items");
                }}
                className="p-2 inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 hover:bg-gray-100 rounded">
                <span>Item</span>
                {/* arrow down */}
                <svg
                  className="w-4 h-4 ml-auto"
                  id="arrow"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"></path>
                </svg>
              </button>
              <ul
                className={
                  pathname == "category" ||
                  pathname == "product" ||
                  pathname == "brand" ||
                  pathname == "subbrand" ||
                  pathname == "series" ||
                  pathname == "license" ||
                  pathname == "type"
                    ? "px-2 dropdown"
                    : "px-2 dropdown hidden"
                }
                id="sub-items">
                <li className="relative">
                  <a
                    href="/product"
                    className="p-2 inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 hover:bg-gray-100 rounded">
                    <span>Produk</span>
                  </a>
                </li>
                <li className="relative">
                  <a
                    href="/category"
                    className="p-2 inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 hover:bg-gray-100 rounded">
                    <span>Kategori</span>
                  </a>
                </li>
                <li className="relative">
                  <a
                    href="/brand"
                    className="p-2 inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 hover:bg-gray-100 rounded">
                    <span>Brand</span>
                  </a>
                </li>
                <li className="relative">
                  <a
                    href="/subbrand"
                    className="p-2 inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 hover:bg-gray-100 rounded">
                    <span>Sub-Brand</span>
                  </a>
                </li>
                <li className="relative">
                  <a
                    href="/series"
                    className="p-2 inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 hover:bg-gray-100 rounded">
                    <span>Series</span>
                  </a>
                </li>
                <li className="relative">
                  <a
                    href="/license"
                    className="p-2 inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 hover:bg-gray-100 rounded">
                    <span>Lisensi</span>
                  </a>
                </li>
                <li className="relative">
                  <a
                    href="/type"
                    className="p-2 inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 hover:bg-gray-100 rounded">
                    <span>Tipe</span>
                  </a>
                </li>
              </ul>
            </li>

            <li className="relative">
              <button
                onClick={() => {
                  dropdown("stock");
                }}
                className="p-2 inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 hover:bg-gray-100 rounded">
                <span>Stock</span>
                {/* arrow down */}
                <svg
                  className="w-4 h-4 ml-auto"
                  id="arrow"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"></path>
                </svg>
              </button>
              <ul
                className={
                  pathname == "stock" ||
                  pathname == "opname" ||
                  pathname == "warehouse" ||
                  pathname == "templateshare" ||
                  pathname == "templatetransfer" ||
                  pathname == "itemrequest"
                    ? "px-2 dropdown"
                    : "px-2 dropdown hidden"
                }
                id="sub-stock">
                <li className="relative">
                  <a
                    href="/stock"
                    className="p-2 inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 hover:bg-gray-100 rounded">
                    <span>Stock produk</span>
                  </a>
                </li>
                <li className="relative">
                  <a
                    href="/opname"
                    className="p-2 inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 hover:bg-gray-100 rounded">
                    <span>Stock opname</span>
                  </a>
                </li>
                <li className="relative">
                  <a
                    href="/templateshare"
                    className="p-2 inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 hover:bg-gray-100 rounded">
                    <span>Template pembagian barang</span>
                  </a>
                </li>
                <li className="relative">
                  <a
                    href="/templatetransfer"
                    className="p-2 inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 hover:bg-gray-100 rounded">
                    <span>Template transfer barang</span>
                  </a>
                </li>
                <li className="relative">
                  <a
                    href="/itemrequest"
                    className="p-2 inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 hover:bg-gray-100 rounded">
                    <span>Permintaan barang</span>
                  </a>
                </li>
                <li className="relative">
                  <a
                    href="/warehouse"
                    className="p-2 inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 hover:bg-gray-100 rounded">
                    <span>Gudang</span>
                  </a>
                </li>
              </ul>
            </li>

            <li className="relative">
              <button
                onClick={() => {
                  dropdown("accounting");
                }}
                className="p-2 inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 hover:bg-gray-100 rounded">
                <span>Akunting</span>
                {/* arrow down */}
                <svg
                  className="w-4 h-4 ml-auto"
                  id="arrow"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"></path>
                </svg>
              </button>
              <ul
                className={
                  pathname == "coa" ||
                  pathname == "journal" ||
                  pathname == "acc_report"
                    ? "px-2 dropdown"
                    : "px-2 dropdown hidden"
                }
                id="sub-accounting">
                <li className="relative">
                  <a
                    href="/journal"
                    className="p-2 inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 hover:bg-gray-100 rounded">
                    <span>Jurnal entry</span>
                  </a>
                </li>
                <li className="relative">
                  <a
                    href="/coa"
                    className="p-2 inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 hover:bg-gray-100 rounded">
                    <span>COA</span>
                  </a>
                </li>
                <li className="relative">
                  <a
                    href="/acc_report"
                    className="p-2 inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 hover:bg-gray-100 rounded">
                    <span>Laporan</span>
                  </a>
                </li>
              </ul>
            </li>

            <li className="relative">
              <button
                onClick={() => {
                  dropdown("contact");
                }}
                className="p-2 inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 hover:bg-gray-100 rounded">
                <span>Kontak</span>
                {/* arrow down */}
                <svg
                  className="w-4 h-4 ml-auto"
                  id="arrow"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"></path>
                </svg>
              </button>
              <ul
                className={
                  pathname === "customer" ||
                  pathname == "customergroup" ||
                  pathname == "supplier"
                    ? "px-2 dropdown"
                    : "px-2 dropdown hidden"
                }
                id="sub-contact">
                <li className="relative">
                  <a
                    href="/customer"
                    className="p-2 inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 hover:bg-gray-100 rounded">
                    <span>Pelanggan</span>
                  </a>
                </li>
                <li className="relative">
                  <a
                    href="/customergroup"
                    className="p-2 inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 hover:bg-gray-100 rounded">
                    <span>Grup pelanggan</span>
                  </a>
                </li>
                <li className="relative">
                  <a
                    href="/supplier"
                    className="p-2 inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 hover:bg-gray-100 rounded">
                    <span>Supplier</span>
                  </a>
                </li>
              </ul>
            </li>

            <li className="py-2 border-b border-gray-600 mb-4"></li>

            <li className="relative">
              <button
                onClick={() => {
                  dropdown("users");
                }}
                className="p-2 inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 hover:bg-gray-100 rounded">
                <span>User Manajemen</span>
                {/* arrow down */}
                <svg
                  className="w-4 h-4 ml-auto"
                  id="arrow"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"></path>
                </svg>
              </button>
              <ul
                className={
                  pathname === "user" || pathname == "group"
                    ? "px-2 dropdown"
                    : "px-2 dropdown hidden"
                }
                id="sub-users">
                <li className="relative">
                  <a
                    href="/user"
                    className="p-2 inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 hover:bg-gray-100 rounded">
                    <span>User</span>
                  </a>
                </li>
                <li className="relative">
                  <a
                    href="/group"
                    className="p-2 inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 hover:bg-gray-100 rounded">
                    <span>Grup User</span>
                  </a>
                </li>
              </ul>
            </li>

            <li className="relative">
              <button
                onClick={() => {
                  dropdown("setting");
                }}
                className="p-2 inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 hover:bg-gray-100 rounded">
                <span>Pengaturan</span>
                {/* arrow down */}
                <svg
                  className="w-4 h-4 ml-auto"
                  id="arrow"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"></path>
                </svg>
              </button>
              <ul
                className={
                  pathname == "currency" ||
                  pathname == "discount" ||
                  pathname == "tax" ||
                  pathname == "voucher" ||
                  pathname == "accountingsetting"
                    ? "px-2 dropdown"
                    : "px-2 dropdown hidden"
                }
                id="sub-setting">
                <li className="relative">
                  <a
                    href="/currency"
                    className="p-2 inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 hover:bg-gray-100 rounded">
                    <span>Mata uang</span>
                  </a>
                </li>
                <li className="relative">
                  <a
                    href="/discount"
                    className="p-2 inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 hover:bg-gray-100 rounded">
                    <span>Diskon</span>
                  </a>
                </li>
                {/* <li className="relative">
                  <a
                    href="/voucher"
                    className="p-2 inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 hover:bg-gray-100 rounded">
                    <span>Voucher</span>
                  </a>
                </li> */}
                <li className="relative">
                  <a
                    href="/tax"
                    className="p-2 inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 hover:bg-gray-100 rounded">
                    <span>Pajak</span>
                  </a>
                </li>
                <li className="relative">
                  <a
                    href="/accountingsetting"
                    className="p-2 inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 hover:bg-gray-100 rounded">
                    <span>Akunting</span>
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Sidemenu;

import React from "react";

import TopHead from "../Layouts/TopHead";
import Sidemenu from "../Layouts/Sidemenu";
import TableGrid from "../../components/TableGrid";
import LoadingBackdrop from "../../components/LoadingBackdrop";
import {
  Input,
  Button,
  ButtonError,
  ButtonSuccess,
  ButtonBack,
} from "../../components/Form";

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Config from "../../config/Config";
import Api from "../../services/Api";

function CoaAdd() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [parentId, setParentId] = useState("");
  const [category, setCategory] = useState(1);
  const [defaultStatement, setDefaultStatement] = useState("Debit");
  const [defaultBalance, setDefaultBalance] = useState(0);

  const [parents, setParents] = useState([]);

  const [categories, setCategories] = useState([]);

  useEffect(() => {
    setLoading(true);

    // show coa category
    Api.get(Config.api_url + "/data/coacategory?limit=100").then((response) => {
      console.log(response);
      setCategories(response);
      setLoading(false);
    });

    // show data by id
    Api.get(Config.api_url + "/data/coa?limit=100").then((response) => {
      console.log(response);
      setParents(response);
      setLoading(false);
    });
  }, []);

  const submit = () => {
    setLoading(true);

    // check if code prefix is not same with category
    if (code.substring(0, 1) != category) {
      toast("Kode harus dimulai dengan kode kategori yang dipilih", {
        type: "error",
      });
      setLoading(false);
      return;
    }

    // create post coa
    Api.post(Config.api_url + "/data/coa", {
      name: name,
      code: code,
      parent_id: parentId,
      category_id: category,
      default_statement: defaultStatement,
    }).then((response) => {
      console.log(response);
      setLoading(false);

      if (response) {
        toast("Data berhasil disimpan", {
          type: "success",
        });
      } else {
        toast("Data gagal disimpan", {
          type: "error",
        });
      }
    });

    setName("");
    setCode("");
    setParentId("");
  };

  return (
    <div>
      {/* side menu tailwind */}
      <TopHead />
      <ToastContainer />
      <div className="flex flex-col md:flex-row h-screen">
        <Sidemenu />
        <div className="flex-1 overflow-hidden">
          <div className="p-4 pt-16">
            <div className="flex justify-between items-center mb-4">
              <div>
                <h3 className="py-2 font-bold text-rose-700">Tambah coa</h3>
              </div>
            </div>

            <div className="flex gap-8">
              <div className="w-1/4">
                <label className="block py-2 text-sm">Kategori</label>
                <select
                  onChange={(e) => setCategory(e.target.value)}
                  className="w-full block px-2 py-1 border border-gray-300 text-sm rounded-md focus:outline-none focus:border-rose-500"
                >
                  {categories.map((category) => (
                    <option value={category.id}>
                      {category.id} - {category.name}
                    </option>
                  ))}
                </select>

                <Input
                  label="Nama"
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <Input
                  label="Kode"
                  type="text"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                />
              </div>
              <div className="w-1/4">
                <label className="block py-2 text-sm">Akun Induk</label>

                <select
                  onChange={(e) => setParentId(e.target.value)}
                  className="w-full block px-2 py-1 border border-gray-300 text-sm rounded-md focus:outline-none focus:border-rose-500"
                >
                  <option value="All">Pilih Akun Induk</option>

                  {parents.map((parent) => (
                    <option value={parent.id}>
                      {parent.code} - {parent.name}
                    </option>
                  ))}
                </select>
                <label className="block py-2 text-sm">
                  Posisi saldo positif
                </label>
                <select
                  onChange={(e) => setDefaultStatement(e.target.value)}
                  className="w-full block px-2 py-1 border border-gray-300 text-sm rounded-md focus:outline-none focus:border-rose-500"
                >
                  <option value="Debit">Debit</option>
                  <option value="Credit">Credit</option>
                </select>

                {/* <Input
                  label="Saldo awal"
                  type="number"
                  value={defaultBalance}
                  onChange={(e) => setDefaultBalance(e.target.value)}
                /> */}
              </div>
            </div>

            <div className="py-2"></div>
            {loading ? (
              <LoadingBackdrop />
            ) : (
              <>
                <ButtonBack
                  label="Kembali"
                  onClick={() => {
                    navigate("/coa");
                  }}
                />
                <ButtonSuccess label="Simpan" onClick={submit} />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CoaAdd;

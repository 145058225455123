import React from "react";

import TopHead from "../Layouts/TopHead";
import Sidemenu from "../Layouts/Sidemenu";
import TableGrid from "../../components/TableGrid";
import LoadingBackdrop from "../../components/LoadingBackdrop";
import { NumericFormat } from "react-number-format";
import { PatternFormat } from "react-number-format";

import {
  Input,
  InputXs,
  Button,
  ButtonError,
  ButtonSuccess,
  ButtonBack,
  Textarea,
} from "../../components/Form";

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Config from "../../config/Config";
import Api from "../../services/Api";

import ItemRequestPdf from "../../components/PDF/ItemRequestPdf";

import ReactDOM from "react-dom";
import { PDFViewer } from "@react-pdf/renderer";

function ItemRequestAdd() {
  const navigate = useNavigate();

  const today = new Date();

  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(
    today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate()
  );

  const [suppliers, setSuppliers] = useState([]);
  const [supplier, setSupplier] = useState("");

  const [currencies, setCurrencies] = useState([]);
  const [currency, setCurrency] = useState("");

  const [products, setProducts] = useState([]);
  const [productFilteredList, setProductFilteredList] = useState([]);

  const [items, setItems] = useState([]);

  const [note, setNote] = useState("");

  const [total, setTotal] = useState(0);

  const [templates, setTemplates] = useState([]);
  const [template, setTemplate] = useState({});
  const [templateId, setTemplateId] = useState("");

  const getTemplate = async () => {
    setLoading(true);
    const response = await Api.get(
      Config.api_url + "/data/template/uncompleted"
    );
    setTemplates(response);
    setLoading(false);
  };

  useEffect(() => {
    getTemplate();
  }, []);

  const updateItemCollected = (value, id) => {
    const qty = value;
    console.log(qty);

    const newItems = items.map((item) =>
      item.id === id ? { ...item, collected: qty } : item
    );
    setItems(newItems);
  };

  const recalculateTotal = () => {
    let total = 0;
    items.forEach((item) => {
      total += parseInt(item.collected);
    });

    setItems(items);
    setTotal(total);
  };

  useEffect(() => {
    recalculateTotal();
  }, [items]);

  const getTemplateItem = async (id) => {
    setLoading(true);
    const response = await Api.get(Config.api_url + "/data/template/" + id);

    const newItems = response.items.map((item) => {
      return {
        id: item.id,
        product: item.product,
        qty: item.qty,
        collected: 0,
      };
    });

    setItems(newItems);

    setLoading(false);
  };

  const getPdf = () => {
    ReactDOM.render(<ItemRequestPdf />, document.getElementById("root"));
  };

  const submit = async (status) => {
    // validation
    if (items.length === 0) {
      toast.error("Item harus diisi");
      return false;
    }

    let error = false;
    items.map((item) => {
      if (item.collected < 1) {
        toast.error("Qty tersedia tidak boleh 0");
        error = true;
        return;
      }

      if (item.collected > item.qty) {
        toast.error("Qty tersedia tidak boleh melebihi qty permintaan");
        error = true;
        return;
      }
    });
    if (error) return;

    setLoading(true);

    setTimeout(() => {
      // create post itemrequest

      Api.post(Config.api_url + "/data/itemrequest", {
        template_id: templateId,
        items: items,
        status: status,
      }).then((response) => {
        console.log(response);
        setLoading(false);

        if (response) {
          toast("Data berhasil disimpan", {
            type: "success",
          });

          setTimeout(() => {
            navigate("/itemrequest");
          }, 1000);
        } else {
          toast("Data gagal disimpan", {
            type: "error",

            autoClose: 2000,
          });
        }
      });
    }, 500);
  };

  const removeItem = (id) => {
    const newItems = items.filter((item) => item.id !== id);
    setItems(newItems);
  };

  return (
    <div>
      {/* side menu tailwind */}
      <TopHead />
      <ToastContainer />
      <div className="flex flex-col md:flex-row h-screen">
        <Sidemenu />
        <div className="flex-1 overflow-scroll">
          <div className="p-4 pt-16">
            <div className="flex justify-between items-center mb-4">
              <div>
                <h3 className="py-2 font-bold text-rose-700">
                  Buat permintaan barang
                </h3>
              </div>
            </div>

            <div className="flex flex-col md:flex-row">
              <div className="w-full md:w-1/4 pr-4">
                <label className="block py-2 text-sm">Template</label>
                <select
                  onChange={(e) => {
                    setItems([]);
                    setTemplateId(e.target.value);
                    templates.map((item) => {
                      if (item.id === e.target.value) {
                        setTemplate(item);
                      }
                    });
                    getTemplateItem(e.target.value);
                  }}
                  className="w-full block px-2 py-1 border border-gray-300 text-sm rounded-md focus:outline-none focus:border-rose-500"
                >
                  <option value="">- Pilih template -</option>
                  {templates.length > 0 &&
                    templates.map((item) => (
                      <option value={item.id}>{item.number}</option>
                    ))}
                </select>
              </div>
              <div className="w-full md:w-1/4 pr-4">
                <label className="block py-2 text-sm">Gudang asal</label>
                <p>{template.from_warehouse && template.from_warehouse.name}</p>
              </div>
              <div className="w-full md:w-1/4 pr-4">
                <label className="block py-2 text-sm">Gudang tujuan</label>
                <p>{template.to_warehouse && template.to_warehouse.name}</p>
              </div>
            </div>

            <div className="flex flex-col md:flex-row mt-4">
              <div className="w-full md:w-3/4 pr-4">
                <div className="border h-80">
                  <table className="table-auto text-xs w-full">
                    <thead className="bg-gray-100">
                      <tr>
                        <th className="w-2 px-4 py-2">No</th>
                        <th className="w-48 text-left px-4 py-2">Nama</th>
                        <th className="w-32 text-left  px-4 py-2">SKU</th>
                        <th className="w-24 text-center  px-4 py-2">
                          Qty permintaan
                        </th>
                        <th className="w-24 text-center  px-4 py-2">
                          Qty tersedia
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {items.map((item, index) => (
                        <tr className="">
                          <td className="text-center  px-4">{index + 1}</td>
                          <td className=" px-4">{item.product.name}</td>
                          <td className=" px-4">{item.product.sku}</td>
                          <td className="text-center px-4">
                            <NumericFormat
                              decimalSeparator=","
                              thousandsGroupStyle="thousand"
                              thousandSeparator="."
                              value={item.qty}
                              className="text-center"
                            />
                          </td>
                          <td className=" px-4 text-center">
                            <InputXs
                              type="number"
                              value={item.collected}
                              onChange={(e) =>
                                updateItemCollected(e.target.value, item.id)
                              }
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                <div className=" my-4 py-2">
                  <table className="table-auto text-sm w-full">
                    <tbody>
                      <tr className="">
                        <td className="text-right px-4 font-bold " colSpan="4">
                          Total
                        </td>
                        <td className="w-32 text-right px-4 ">
                          <NumericFormat
                            decimalSeparator=","
                            thousandsGroupStyle="thousand"
                            thousandSeparator="."
                            value={total}
                            className="font-bold text-right"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div className="my-4">
                  <Textarea
                    label="Catatan"
                    placeholder="Tulis catatan"
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                  ></Textarea>
                </div>
              </div>
            </div>

            <div className="flex-1"></div>

            {loading ? (
              <LoadingBackdrop />
            ) : (
              <>
                <div className="flex w-3/4 pr-4 justify-between">
                  <div>
                    <ButtonBack label="Kembali" />
                  </div>

                  <div>
                    <Button
                      label="Simpan Draf"
                      onClick={() => {
                        {
                          submit("Draft");
                        }
                      }}
                    />
                    <span className="px-1"></span>
                    <ButtonSuccess
                      label="Selesaikan"
                      onClick={() => {
                        submit("Completed");
                      }}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ItemRequestAdd;

import React from "react";

import TopHead from "../Layouts/TopHead";
import Sidemenu from "../Layouts/Sidemenu";
import TableGrid from "../../components/TableGrid";
import LoadingBackdrop from "../../components/LoadingBackdrop";
import {
  Input,
  Button,
  ButtonError,
  ButtonSuccess,
  ButtonBack,
} from "../../components/Form";

import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Config from "../../config/Config";
import Api from "../../services/Api";

function UserEdit() {
  const navigate = useNavigate();
  const { id } = useParams();

  const [loading, setLoading] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [groups, setGroups] = useState([]);
  const [group, setGroup] = useState("");

  const getGroups = async () => {
    const response = await Api.get(Config.api_url + "/data/group");

    setGroups(response);
  };

  console.log(id);

  useEffect(() => {
    setLoading(true);

    getGroups();

    // show data by id
    Api.get(Config.api_url + "/data/user/" + id).then((response) => {
      console.log(response);
      setName(response.name);
      setEmail(response.email);
      setGroup(response.group_id);
      setLoading(false);
    });
  }, []);

  const submit = () => {
    setLoading(true);

    // create post user
    Api.put(Config.api_url + "/data/user/" + id, {
      name: name,
      email: email,
      password: password,
      group_id: group,
    }).then((response) => {
      console.log(response);
      setLoading(false);
    });

    toast("Data berhasil disimpan", {
      type: "success",
    });
  };

  return (
    <div>
      {/* side menu tailwind */}
      <TopHead />
      <ToastContainer />
      <div className="flex flex-col md:flex-row h-screen">
        <Sidemenu />
        <div className="flex-1 overflow-hidden">
          <div className="p-4 pt-16">
            <div className="flex justify-between items-center mb-4">
              <div>
                <h3 className="py-2 font-bold text-rose-700">Edit user</h3>
              </div>
            </div>

            <div className="w-1/4">
              <Input
                label="Nama"
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <Input
                label="Email"
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <Input
                label="Password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <label className="block py-2 text-sm">Grup akses</label>
              <select
                className="w-full block px-2 py-1 border border-gray-300 text-sm rounded-md focus:outline-none focus:border-rose-500"
                value={group}
                onChange={(e) => setGroup(e.target.value)}
              >
                <option value="">Pilih grup akses</option>
                {groups.length > 0 &&
                  groups.map((item) => (
                    <option
                      key={item.id}
                      value={item.id}
                      selected={item.id == group ? true : false}
                    >
                      {item.name}
                    </option>
                  ))}
              </select>
              <div className="py-2"></div>
              {loading ? (
                <LoadingBackdrop />
              ) : (
                <>
                  <ButtonBack
                    label="Kembali"
                    onClick={() => {
                      navigate("/user");
                    }}
                  />
                  <ButtonSuccess label="Simpan" onClick={submit} />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserEdit;

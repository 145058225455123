import React from "react";
import TopHead from "../Layouts/TopHead";
import Sidemenu from "../Layouts/Sidemenu";
import TableGrid from "../../components/TableGrid";

import { useState, useEffect } from "react";
import Config from "../../config/Config";
import Api from "../../services/Api";
import { NumericFormat } from "react-number-format";
import { Input, Button } from "../../components/Form";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Stock() {
  const [items, setItems] = useState([]);

  const [warehouses, setWarehouses] = useState([]);
  const [warehouse, setWarehouse] = useState("");

  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState("");

  const [brands, setBrands] = useState([]);
  const [brand, setBrand] = useState("");

  const [page, setPage] = useState(1);

  const [loading, setLoading] = useState(true);

  const [modalItemStock, setModalItemStock] = useState(false);
  const [itemStocks, setItemStocks] = useState([]);
  const [itemStockName, setItemStockName] = useState("");

  const today = new Date();

  const date =
    today.getFullYear() +
    "-" +
    (today.getMonth() + 1) +
    "-" +
    today.getDate().toString().padStart(2, "0");

  const [fromDate, setFromDate] = useState(date);
  const [toDate, setToDate] = useState(date);

  const getCategory = async () => {
    const response = await Api.get(Config.api_url + "/data/category");
    setCategories(response);
  };

  const getBrand = async () => {
    const response = await Api.get(Config.api_url + "/data/brand");
    setBrands(response);
  };

  const getWarehouse = async () => {
    const response = await Api.get(Config.api_url + "/data/warehouse");
    setWarehouses(response);
  };

  const getStock = async () => {
    const response = await Api.get(
      Config.api_url +
        "/data/stock?warehouse_id=" +
        warehouse +
        "&category_id=" +
        category +
        "&brand_id=" +
        brand +
        "&from_date=" +
        fromDate +
        "&to_date=" +
        toDate +
        "&page=" +
        page
    );

    setItems(response);
  };

  useEffect(() => {
    setLoading(true);

    getCategory();
    getBrand();
    getWarehouse();
    getStock();
    setLoading(false);

    console.log("ok");
  }, [fromDate, toDate, warehouse, category, brand, page]);

  const itemStock = async (id, name) => {
    const response = await Api.get(Config.api_url + "/data/stock/item/" + id);

    setItemStocks(response);
    setItemStockName(name);
    console.log(response);

    setTimeout(() => {
      setModalItemStock(true);
    }, 100);
  };

  return (
    <div>
      {/* side menu tailwind */}
      <TopHead />
      <ToastContainer />
      <div className="flex flex-col md:flex-row h-screen">
        <Sidemenu />
        <div className="flex-1 overflow-scroll">
          <div className="p-8 pt-8"></div>

          <div className="p-4 pt-2">Stock</div>

          <div className="flex flex-col md:flex-row px-4">
            <div className="w-full md:w-48 pr-4">
              <Input
                label="Dari tanggal"
                type="date"
                value={fromDate}
                defaultValue={fromDate}
                onChange={(e) => {
                  setFromDate(e.target.value);
                }}
              />
            </div>
            <div className="w-full md:w-48 pr-4">
              <Input
                label="Sampai tanggal"
                type="date"
                value={toDate}
                defaultValue={toDate}
                onChange={(e) => {
                  setToDate(e.target.value);
                }}
              />
            </div>
            <div className="w-full md:w-48 pr-4">
              <label className="block py-2 text-sm">Kategori</label>
              <select
                onChange={(e) => setCategory(e.target.value)}
                className="w-full block px-2 py-1 border border-gray-300 text-sm rounded-md focus:outline-none focus:border-rose-500">
                <option value="">Semua</option>
                {!loading &&
                  categories.map((item) => (
                    <option value={item.id}>{item.name}</option>
                  ))}
              </select>
            </div>
            <div className="w-full md:w-48 pr-4">
              <label className="block py-2 text-sm">Brand</label>
              <select
                onChange={(e) => setBrand(e.target.value)}
                className="w-full block px-2 py-1 border border-gray-300 text-sm rounded-md focus:outline-none focus:border-rose-500">
                <option value="">Semua</option>
                {!loading &&
                  brands.map((item) => (
                    <option value={item.id}>{item.name}</option>
                  ))}
              </select>
            </div>
            <div className="w-full md:w-48 pr-4">
              <label className="block py-2 text-sm">Gudang</label>
              <select
                onChange={(e) => setWarehouse(e.target.value)}
                className="w-full block px-2 py-1 border border-gray-300 text-sm rounded-md focus:outline-none focus:border-rose-500">
                <option value="">Semua</option>
                {!loading &&
                  warehouses.map((item) => (
                    <option value={item.id}>{item.name}</option>
                  ))}
              </select>
            </div>

            <div className="w-full md:w-48 pr-4">
              <br />
              <Button label="Tampilkan" />
            </div>
          </div>

          <div className="w-full px-4 mt-4">
            <table className="w-full">
              <thead>
                <tr>
                  <th
                    rowspan="2"
                    className="border px-2 py-1 w-8 text-xs text-center">
                    No
                  </th>
                  <th
                    rowspan="2"
                    className="border w-64 px-2 py-1 text-xs text-left">
                    Nama
                  </th>
                  <th
                    rowspan="2"
                    className="border w-32 px-2 py-1 text-xs text-left">
                    SKU
                  </th>
                  <th
                    rowspan="2"
                    className="border bg-yellow-200 w-12 px-2 py-1 text-xs text-center">
                    Stok awal
                  </th>
                  <th
                    colSpan="3"
                    className="border px-2 py-1 text-xs text-center">
                    Stok masuk
                  </th>

                  <th
                    colSpan="3"
                    className="border px-2 py-1 text-xs text-center">
                    Stok keluar
                  </th>

                  <th
                    rowSpan="2"
                    className="bg-orange-200 border w-12 px-2 py-1 text-xs text-center">
                    Stok akhir
                  </th>
                  <th
                    rowSpan="2"
                    className="border w-12 px-2 py-1 text-xs text-center">
                    Sisa stok
                  </th>
                </tr>
                <tr>
                  <th className="border px-2 py-1 text-xs text-center">
                    Pembelian
                  </th>
                  <th className="border px-2 py-1 text-xs text-center">
                    Penyesuaian
                  </th>
                  <th className="border px-2 bg-gray-200 py-1 text-xs text-center">
                    Total
                  </th>
                  <th className="border px-2 py-1 text-xs text-center">
                    Penjualan
                  </th>
                  <th className="border px-2 py-1 text-xs text-center">
                    Penyesuaian
                  </th>
                  <th className="border bg-gray-200 px-2 py-1 text-xs text-center">
                    Total
                  </th>
                </tr>
              </thead>
              <tbody>
                {!loading &&
                  items.map((item, index) => (
                    <tr>
                      <td className="border px-2 py-1 text-xs text-center">
                        {index + 1}
                      </td>
                      <td className="border px-2 py-1 text-xs text-left">
                        <a
                          className="text-blue-500 cursor-pointer"
                          onClick={() => {
                            itemStock(item.id, item.name);
                          }}>
                          {item.name}
                        </a>
                      </td>
                      <td className="border px-2 py-1 text-xs text-left">
                        {item.sku}
                      </td>
                      <td className="border bg-yellow-50 px-2 py-1 text-xs text-center">
                        {item.stock_awal}
                      </td>
                      <td className="border px-2 py-1 text-xs text-center">
                        {item.stock_masuk_pembelian}
                      </td>
                      <td className="border px-2 py-1 text-xs text-center">
                        {item.stock_masuk_penyesuaian}
                      </td>
                      <td className="border bg-gray-50 px-2 py-1 text-xs text-center">
                        {item.total_stock_masuk}
                      </td>
                      <td className="border px-2 py-1 text-xs text-center">
                        {item.stock_keluar_penjualan}
                      </td>
                      <td className="border px-2 py-1 text-xs text-center">
                        {item.stock_keluar_penyesuaian}
                      </td>
                      <td className="border bg-gray-50 px-2 py-1 text-xs text-center">
                        {item.total_stock_keluar}
                      </td>
                      <td className="border bg-orange-50 px-2 py-1 text-xs text-center">
                        {item.stock_akhir}
                      </td>
                      <td className="border px-2 py-1 text-xs text-center">
                        {item.stock}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {modalItemStock && (
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50 flex items-center justify-center">
          <div className="bg-white w-1/3 rounded-md shadow-lg">
            <div className="flex justify-between items-center px-4 py-2 border-b border-gray-200">
              <div>Stock : {itemStockName} </div>
            </div>
            <div className="px-4 py-2 flex flex-col">
              <div className="border">
                <table className="table-auto text-xs w-full">
                  <thead className="bg-gray-100">
                    <tr>
                      <th className="w-2 px-4 py-2">No</th>
                      <th className="text-left w-64 px-4 py-2">Gudang</th>
                      <th className="text-left px-4 py-2">Stock</th>
                    </tr>
                  </thead>

                  <tbody>
                    {itemStocks.length > 0 &&
                      itemStocks.map((item, index) => (
                        <tr className="" key={index}>
                          <td className="text-center py-1  px-4">
                            {index + 1}
                          </td>
                          <td className="py-1 px-4">{item.warehouse.name}</td>
                          <td className="py-1 px-4">{item.qty}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="flex gap-2 items-center  justify-end px-4 py-2 border-t border-gray-200">
              <Button label="Tutup" onClick={() => setModalItemStock(false)} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Stock;

import React from "react";

import TopHead from "../Layouts/TopHead";
import Sidemenu from "../Layouts/Sidemenu";
import TableGrid from "../../components/TableGrid";
import LoadingBackdrop from "../../components/LoadingBackdrop";
import Modal from "../../components/Modal";
import { NumericFormat } from "react-number-format";
import { PatternFormat } from "react-number-format";

import {
  Input,
  InputXs,
  Button,
  ButtonError,
  ButtonSuccess,
  ButtonBack,
  Textarea,
} from "../../components/Form";

import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Config from "../../config/Config";
import Api from "../../services/Api";

function TemplateTransferView() {
  const navigate = useNavigate();
  const { id } = useParams();

  const [loading, setLoading] = useState(true);
  const [template, setTemplate] = useState({});

  const [modalAddPayment, setModalAddPayment] = useState(false);

  const [totalQty, setTotalQty] = useState(0);
  const [totalCollected, setTotalCollected] = useState(0);

  const today = new Date();

  const [modalCompleted, setModalCompleted] = useState(false);

  const [payment, setPayment] = useState({
    purchase_order_id: id,
    date:
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate(),
    amount: "",
    note: "",
  });

  const getTemplate = async () => {
    setLoading(true);

    const response = await Api.get(Config.api_url + "/data/template/" + id);
    console.log(response);

    // set total qty and total collected
    let totalQty = 0;
    let totalCollected = 0;
    response.items.map((item) => {
      totalQty += item.qty;
      totalCollected += item.collected;
    });

    setTotalQty(totalQty);
    setTotalCollected(totalCollected);

    setTemplate(response);
    setLoading(false);
  };

  useEffect(() => {
    getTemplate();
  }, []);

  const changeStatus = async (status) => {
    setLoading(true);

    const response = await Api.post(
      Config.api_url + "/data/template/changestatus/",
      {
        id: id,
        status: status,
      }
    );

    if (response) {
      toast("Status berhasil diubah", {
        type: "success",
      });
      getTemplate();
      setModalCompleted(false);
    } else {
      toast("Status gagal diubah", {
        type: "error",
      });
    }

    setLoading(false);
  };

  return (
    <div>
      {/* side menu tailwind */}
      <TopHead />
      <ToastContainer />
      <div className="flex flex-col md:flex-row h-screen">
        <Sidemenu />
        <div className="flex-1 overflow-scroll">
          <div className="p-4 pt-16">
            {loading ? (
              <LoadingBackdrop />
            ) : (
              <>
                <div className="flex justify-between items-center mb-4">
                  <div className="flex flex-1 flex-row">
                    <div className="grow">
                      <h3 className="py-2 font-bold text-rose-700">
                        Template : {template.number}
                      </h3>
                    </div>

                    <div className=""></div>
                  </div>
                </div>

                <div className="flex flex-col md:flex-row mt-4">
                  <div className="w-full md:w-3/4 pr-4">
                    <div className="flex flex-col md:flex-row mb-4">
                      <div className="w-full md:w-1/4 pr-4">
                        <label className="block py-1 text-sm">Tanggal</label>
                        {new Date(template.created_at).toLocaleDateString()}
                      </div>
                      <div className="w-full md:w-1/4 pr-4">
                        <label className="block py-1 text-sm">
                          Gudang asal
                        </label>
                        {template.from_warehouse && (
                          <>{template.from_warehouse.name}</>
                        )}
                      </div>
                      <div className="w-full md:w-1/4 pr-4">
                        <label className="block py-1 text-sm">
                          Gudang tujuan
                        </label>
                        {template.to_warehouse && (
                          <>{template.to_warehouse.name}</>
                        )}
                      </div>
                      <div className="w-full md:w-1/4 pr-4">
                        <label className="block py-1 text-sm">Status</label>
                        {template.status}
                      </div>
                    </div>

                    <div className="border">
                      <table className="table-auto text-xs w-full">
                        <thead className="bg-gray-100">
                          <tr>
                            <th className="w-2 px-4 py-2">No</th>
                            <th className="text-left px-4 py-2">Nama</th>
                            <th className="w-32 text-left  px-4 py-2">SKU</th>
                            <th className="w-28 text-left  px-4 py-2">Qty</th>
                            <th className="w-32 text-right  px-4 py-2">
                              Terpenuhi
                            </th>
                          </tr>
                        </thead>

                        <tbody>
                          {template.items.map((item, index) => (
                            <tr className="">
                              <td className="text-center py-1  px-4">
                                {index + 1}
                              </td>
                              <td className="py-1 px-4">{item.product.name}</td>
                              <td className="py-1 px-4">{item.product.sku}</td>
                              <td className="py-1 px-4">{item.qty}</td>
                              <td className="py-1 px-4 text-right">
                                {item.collected}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div className=" my-4 py-2">
                      <table className="table-auto text-sm w-full">
                        <tbody>
                          <tr className="">
                            <td className="text-right px-4 font-bold">Total</td>
                            <td className="w-48 text-right px-4 ">
                              <NumericFormat
                                decimalSeparator=","
                                thousandsGroupStyle="thousand"
                                thousandSeparator="."
                                value={totalQty}
                                className="font-bold text-right"
                              />
                            </td>
                            <td className="w-32 text-right px-4 ">
                              <NumericFormat
                                decimalSeparator=","
                                thousandsGroupStyle="thousand"
                                thousandSeparator="."
                                value={totalQty}
                                className="font-bold text-right"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className="my-4">
                      <label className="block py-1 text-sm">Keterangan</label>
                      {template.note}
                    </div>

                    <div className="my-4 text-right justify-between flex">
                      <Button
                        label="Kembali"
                        onClick={() => {
                          navigate("/template");
                        }}
                      />
                      {template.status === "Pending" && (
                        <ButtonSuccess
                          label="Selesaikan transfer barang"
                          onClick={() => {
                            setModalCompleted(true);
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}

            <div className="flex-1"></div>
          </div>
        </div>
      </div>

      {/** modal discount */}
      {modalCompleted && (
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50 flex items-center justify-center">
          <div className="bg-white md:w-64 w-64 rounded-md shadow-lg">
            <div className="flex justify-between items-center px-4 py-2 border-b border-gray-200 gap-2">
              <div className="h-6 grow">Konfirmasi</div>
            </div>

            <div className="px-4 py-2 flex">
              <div className="w-full pr-4">
                Yakin akan selesaikan transfer barang?
              </div>
            </div>
            <div className="flex gap-2 items-center  justify-end px-4 py-2 border-t border-gray-200">
              <Button label="Tutup" onClick={() => setModalCompleted(false)} />
              <ButtonSuccess
                label="Simpan"
                onClick={() => {
                  changeStatus("Completed");
                }}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default TemplateTransferView;

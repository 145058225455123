import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Layout from "./Layout";

import Login from "./screens/Login/Login";
import Dashboard from "./screens/Dashboard/Dashboard";

import Category from "./screens/Category/Category";
import CategoryAdd from "./screens/Category/CategoryAdd";
import CategoryEdit from "./screens/Category/CategoryEdit";

import Brand from "./screens/Brand/Brand";
import BrandAdd from "./screens/Brand/BrandAdd";
import BrandEdit from "./screens/Brand/BrandEdit";

import SubBrand from "./screens/SubBrand/SubBrand";
import SubBrandAdd from "./screens/SubBrand/SubBrandAdd";
import SubBrandEdit from "./screens/SubBrand/SubBrandEdit";

import Series from "./screens/Series/Series";
import SeriesAdd from "./screens/Series/SeriesAdd";
import SeriesEdit from "./screens/Series/SeriesEdit";

import License from "./screens/License/License";
import LicenseAdd from "./screens/License/LicenseAdd";
import LicenseEdit from "./screens/License/LicenseEdit";

import Type from "./screens/Type/Type";
import TypeAdd from "./screens/Type/TypeAdd";
import TypeEdit from "./screens/Type/TypeEdit";

import Currency from "./screens/Currency/Currency";
import CurrencyAdd from "./screens/Currency/CurrencyAdd";
import CurrencyEdit from "./screens/Currency/CurrencyEdit";

import Product from "./screens/Product/Product";
import ProductAdd from "./screens/Product/ProductAdd";
import ProductEdit from "./screens/Product/ProductEdit";
import ProductExportExcel from "./screens/Product/ProductExportExcel";
import ProductImport from "./screens/Product/ProductImport";

import Customer from "./screens/Customer/Customer";
import CustomerAdd from "./screens/Customer/CustomerAdd";
import CustomerEdit from "./screens/Customer/CustomerEdit";

import CustomerGroup from "./screens/CustomerGroup/CustomerGroup";
import CustomerGroupAdd from "./screens/CustomerGroup/CustomerGroupAdd";
import CustomerGroupEdit from "./screens/CustomerGroup/CustomerGroupEdit";

import Supplier from "./screens/Supplier/Supplier";
import SupplierAdd from "./screens/Supplier/SupplierAdd";
import SupplierEdit from "./screens/Supplier/SupplierEdit";

import PurchaseOrder from "./screens/PurchaseOrder/PurchaseOrder";
import PurchaseOrderAdd from "./screens/PurchaseOrder/PurchaseOrderAdd";
import PurchaseOrderEdit from "./screens/PurchaseOrder/PurchaseOrderEdit";
import PurchaseOrderView from "./screens/PurchaseOrder/PurchaseOrderView";

import Purchasing from "./screens/Purchasing/Purchasing";
import PurchasingAdd from "./screens/Purchasing/PurchasingAdd";
import PurchasingEdit from "./screens/Purchasing/PurchasingEdit";
import PurchasingView from "./screens/Purchasing/PurchasingView";

import PackingList from "./screens/PackingList/PackingList";
import PackingListAdd from "./screens/PackingList/PackingListAdd";
import PackingListEdit from "./screens/PackingList/PackingListEdit";
import PackingListView from "./screens/PackingList/PackingListView";

import Warehouse from "./screens/Warehouse/Warehouse";
import WarehouseAdd from "./screens/Warehouse/WarehouseAdd";
import WarehouseEdit from "./screens/Warehouse/WarehouseEdit";

import Sales from "./screens/Sales/Sales";
import SalesAdd from "./screens/Sales/SalesAdd";
import SalesEdit from "./screens/Sales/SalesEdit";
import SalesView from "./screens/Sales/SalesView";
import SalesReceipt from "./screens/Sales/SalesReceipt";

import POS from "./screens/POS/POS";

import Stock from "./screens/Stock/Stock";

import Opname from "./screens/Opname/Opname";
import OpnameAdd from "./screens/Opname/OpnameAdd";
import OpnameEdit from "./screens/Opname/OpnameEdit";
import OpnameView from "./screens/Opname/OpnameView";

import TemplateShare from "./screens/TemplateShare/TemplateShare";
import TemplateShareAdd from "./screens/TemplateShare/TemplateShareAdd";
import TemplateShareEdit from "./screens/TemplateShare/TemplateShareEdit";
import TemplateShareView from "./screens/TemplateShare/TemplateShareView";

import TemplateTransfer from "./screens/TemplateTransfer/TemplateTransfer";
import TemplateTransferAdd from "./screens/TemplateTransfer/TemplateTransferAdd";
import TemplateTransferEdit from "./screens/TemplateTransfer/TemplateTransferEdit";
import TemplateTransferView from "./screens/TemplateTransfer/TemplateTransferView";

import ItemRequest from "./screens/ItemRequest/ItemRequest";
import ItemRequestAdd from "./screens/ItemRequest/ItemRequestAdd";
import ItemRequestView from "./screens/ItemRequest/ItemRequestView";
import ItemRequestPdf from "./components/PDF/ItemRequestPdf";

import Discount from "./screens/Discount/Discount";
import DiscountAdd from "./screens/Discount/DiscountAdd";
import DiscountEdit from "./screens/Discount/DiscountEdit";

import Voucher from "./screens/Voucher/Voucher";
import VoucherAdd from "./screens/Voucher/VoucherAdd";
import VoucherEdit from "./screens/Voucher/VoucherEdit";

import Tax from "./screens/Tax/Tax";
import TaxAdd from "./screens/Tax/TaxAdd";
import TaxEdit from "./screens/Tax/TaxEdit";

import User from "./screens/User/User";
import UserAdd from "./screens/User/UserAdd";
import UserEdit from "./screens/User/UserEdit";

import Group from "./screens/Group/Group";
import GroupAdd from "./screens/Group/GroupAdd";
import GroupEdit from "./screens/Group/GroupEdit";

import Coa from "./screens/Coa/Coa";
import CoaAdd from "./screens/Coa/CoaAdd";
import CoaEdit from "./screens/Coa/CoaEdit";

// create Journal import scheme from above
import Journal from "./screens/Journal/Journal";
import JournalAdd from "./screens/Journal/JournalAdd";
import JournalEdit from "./screens/Journal/JournalEdit";
import JournalView from "./screens/Journal/JournalView";

import AccountingSetting from "./screens/AccountingSetting/AccountingSetting";

document.title = "Multitoys App";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Login />} />
          <Route path="login" element={<Login />} />
          <Route path="dashboard" element={<Dashboard />} />

          <Route path="category" element={<Category />} />
          <Route path="category/add" element={<CategoryAdd />} />
          <Route path="category/edit/:id" element={<CategoryEdit />} />

          <Route path="brand" element={<Brand />} />
          <Route path="brand/add" element={<BrandAdd />} />
          <Route path="brand/edit/:id" element={<BrandEdit />} />

          <Route path="subbrand" element={<SubBrand />} />
          <Route path="subbrand/add" element={<SubBrandAdd />} />
          <Route path="subbrand/edit/:id" element={<SubBrandEdit />} />

          <Route path="series" element={<Series />} />
          <Route path="series/add" element={<SeriesAdd />} />
          <Route path="series/edit/:id" element={<SeriesEdit />} />

          <Route path="License" element={<License />} />
          <Route path="License/add" element={<LicenseAdd />} />
          <Route path="License/edit/:id" element={<LicenseEdit />} />

          <Route path="Type" element={<Type />} />
          <Route path="Type/add" element={<TypeAdd />} />
          <Route path="Type/edit/:id" element={<TypeEdit />} />

          <Route path="Currency" element={<Currency />} />
          <Route path="Currency/add" element={<CurrencyAdd />} />
          <Route path="Currency/edit/:id" element={<CurrencyEdit />} />

          <Route path="Product" element={<Product />} />
          <Route path="Product/add" element={<ProductAdd />} />
          <Route path="Product/edit/:id" element={<ProductEdit />} />
          <Route path="Product/export" element={<ProductExportExcel />} />
          <Route path="Product/import" element={<ProductImport />} />

          <Route path="Customer" element={<Customer />} />
          <Route path="Customer/add" element={<CustomerAdd />} />
          <Route path="Customer/edit/:id" element={<CustomerEdit />} />

          <Route path="customergroup" element={<CustomerGroup />} />
          <Route path="customergroup/add" element={<CustomerGroupAdd />} />
          <Route
            path="customergroup/edit/:id"
            element={<CustomerGroupEdit />}
          />

          <Route path="Supplier" element={<Supplier />} />
          <Route path="Supplier/add" element={<SupplierAdd />} />
          <Route path="Supplier/edit/:id" element={<SupplierEdit />} />

          <Route path="PurchaseOrder" element={<PurchaseOrder />} />
          <Route path="PurchaseOrder/add" element={<PurchaseOrderAdd />} />
          <Route
            path="PurchaseOrder/edit/:id"
            element={<PurchaseOrderEdit />}
          />
          <Route
            path="PurchaseOrder/view/:id"
            element={<PurchaseOrderView />}
          />

          <Route path="Purchasing" element={<Purchasing />} />
          <Route path="Purchasing/add" element={<PurchasingAdd />} />
          <Route path="Purchasing/edit/:id" element={<PurchasingEdit />} />
          <Route path="Purchasing/view/:id" element={<PurchasingView />} />

          <Route path="PackingList" element={<PackingList />} />
          <Route path="PackingList/add" element={<PackingListAdd />} />
          <Route path="PackingList/edit/:id" element={<PackingListEdit />} />
          <Route path="PackingList/view/:id" element={<PackingListView />} />

          <Route path="Warehouse" element={<Warehouse />} />
          <Route path="Warehouse/add" element={<WarehouseAdd />} />
          <Route path="Warehouse/edit/:id" element={<WarehouseEdit />} />

          <Route path="Sales" element={<Sales />} />
          <Route path="Sales/add" element={<SalesAdd />} />
          <Route path="Sales/edit/:id" element={<SalesEdit />} />
          <Route path="Sales/view/:id" element={<SalesView />} />
          <Route path="Sales/receipt/:id" element={<SalesReceipt />} />

          <Route path="pos" element={<POS />} />
          <Route path="stock" element={<Stock />} />

          <Route path="Opname" element={<Opname />} />
          <Route path="Opname/add" element={<OpnameAdd />} />
          <Route path="Opname/edit/:id" element={<OpnameEdit />} />
          <Route path="Opname/view/:id" element={<OpnameView />} />

          <Route path="TemplateShare" element={<TemplateShare />} />
          <Route path="TemplateShare/add" element={<TemplateShareAdd />} />
          <Route
            path="TemplateShare/edit/:id"
            element={<TemplateShareEdit />}
          />
          <Route
            path="TemplateShare/view/:id"
            element={<TemplateShareView />}
          />

          <Route path="TemplateTransfer" element={<TemplateTransfer />} />
          <Route
            path="TemplateTransfer/add"
            element={<TemplateTransferAdd />}
          />
          <Route
            path="TemplateTransfer/edit/:id"
            element={<TemplateTransferEdit />}
          />
          <Route
            path="TemplateTransfer/view/:id"
            element={<TemplateTransferView />}
          />

          <Route path="ItemRequest" element={<ItemRequest />} />
          <Route path="ItemRequest/add" element={<ItemRequestAdd />} />
          <Route path="ItemRequest/view/:id" element={<ItemRequestView />} />
          <Route path="ItemRequest/pdf/:id" element={<ItemRequestPdf />} />

          <Route path="Voucher" element={<Voucher />} />
          <Route path="Voucher/add" element={<VoucherAdd />} />
          <Route path="Voucher/edit/:id" element={<VoucherEdit />} />

          <Route path="Tax" element={<Tax />} />
          <Route path="Tax/add" element={<TaxAdd />} />
          <Route path="Tax/edit/:id" element={<TaxEdit />} />

          <Route path="Discount" element={<Discount />} />
          <Route path="Discount/add" element={<DiscountAdd />} />
          <Route path="Discount/edit/:id" element={<DiscountEdit />} />

          <Route path="User" element={<User />} />
          <Route path="User/add" element={<UserAdd />} />
          <Route path="User/edit/:id" element={<UserEdit />} />

          <Route path="Group" element={<Group />} />
          <Route path="Group/add" element={<GroupAdd />} />
          <Route path="Group/edit/:id" element={<GroupEdit />} />

          <Route path="Coa" element={<Coa />} />
          <Route path="Coa/add" element={<CoaAdd />} />
          <Route path="Coa/edit/:id" element={<CoaEdit />} />

          <Route path="Journal" element={<Journal />} />
          <Route path="Journal/add" element={<JournalAdd />} />
          <Route path="Journal/edit/:id" element={<JournalEdit />} />
          <Route path="Journal/view/:id" element={<JournalView />} />

          <Route path="AccountingSetting" element={<AccountingSetting />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

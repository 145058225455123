import React from "react";

import TopHead from "../Layouts/TopHead";
import Sidemenu from "../Layouts/Sidemenu";
import TableGrid from "../../components/TableGrid";
import LoadingBackdrop from "../../components/LoadingBackdrop";
import Modal from "../../components/Modal";
import { NumericFormat } from "react-number-format";
import { PatternFormat } from "react-number-format";

import {
  Input,
  InputXs,
  Button,
  ButtonError,
  ButtonSuccess,
  ButtonBack,
  Textarea,
} from "../../components/Form";

import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Config from "../../config/Config";
import Api from "../../services/Api";

function PurchaseOrderAdd() {
  const navigate = useNavigate();
  const { id } = useParams();

  const [loading, setLoading] = useState(true);
  const [purchaseorder, setPurchaseOrder] = useState({});

  const [modalAddPayment, setModalAddPayment] = useState(false);

  const today = new Date();

  const [payment, setPayment] = useState({
    purchase_order_id: id,
    date:
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate(),
    amount: "",
    note: "",
  });

  const getPurchaseOrder = async () => {
    setLoading(true);

    const response = await Api.get(
      Config.api_url + "/data/purchaseorder/" + id
    );
    console.log(response);

    setPurchaseOrder(response);
    setLoading(false);
  };

  const submitPayment = async () => {
    setLoading(true);

    const response = await Api.post(
      Config.api_url + "/data/purchaseorderpayment",
      payment
    );
    console.log(response);

    if (response) {
      toast.success("Payment added successfully");
      setModalAddPayment(false);
      getPurchaseOrder();

      setPayment({
        purchase_order_id: id,
        date:
          today.getFullYear() +
          "-" +
          (today.getMonth() + 1) +
          "-" +
          today.getDate(),
        amount: "",
        note: "",
      });
    } else {
      toast.error("Failed to add payment");
    }

    setLoading(false);
  };

  useEffect(() => {
    getPurchaseOrder();
  }, []);

  return (
    <div>
      {/* side menu tailwind */}
      <TopHead />
      <ToastContainer />
      <div className="flex flex-col md:flex-row h-screen">
        <Sidemenu />
        <div className="flex-1 overflow-scroll">
          <div className="p-4 pt-16">
            {loading ? (
              <LoadingBackdrop />
            ) : (
              <>
                <div className="flex justify-between items-center mb-4">
                  <div>
                    <h3 className="py-2 font-bold text-rose-700">
                      Purchase Order : {purchaseorder.number}
                    </h3>
                  </div>
                </div>

                <div className="flex flex-col md:flex-row">
                  <div className="w-full md:w-1/4 pr-4">
                    <label className="block py-1 text-sm">Tanggal</label>
                    {new Date(purchaseorder.date).toLocaleDateString()}
                  </div>
                  <div className="w-full md:w-1/4 pr-4">
                    <label className="block py-1 text-sm">Supplier</label>
                    {purchaseorder.supplier && (
                      <>{purchaseorder.supplier.name}</>
                    )}
                  </div>
                  <div className="w-full md:w-1/4 pr-4">
                    <label className="block py-1 text-sm">Mata uang</label>
                    {purchaseorder.currency && (
                      <>{purchaseorder.currency.code}</>
                    )}
                  </div>
                </div>

                <div className="flex flex-col md:flex-row mt-4">
                  <div className="w-full md:w-3/4 pr-4">
                    <div className="border">
                      <table className="table-auto text-xs w-full">
                        <thead className="bg-gray-100">
                          <tr>
                            <th className="w-2 px-4 py-2">No</th>
                            <th className="text-left px-4 py-2">Nama</th>
                            <th className="w-32 text-left  px-4 py-2">Harga</th>
                            <th className="w-24 text-left  px-4 py-2">
                              Jumlah
                            </th>
                            <th className="w-32 text-right  px-4 py-2">
                              Subtotal
                            </th>
                          </tr>
                        </thead>

                        <tbody>
                          {purchaseorder.items.map((item, index) => (
                            <tr className="">
                              <td className="text-center py-1  px-4">
                                {index + 1}
                              </td>
                              <td className="py-1 px-4">{item.product.name}</td>
                              <td className="py-1 px-4">{item.price}</td>
                              <td className="py-1 px-4">{item.qty}</td>
                              <td className="py-1 px-4 text-right">
                                {item.subtotal}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div className=" my-4 py-2">
                      <table className="table-auto text-sm w-full">
                        <tbody>
                          <tr className="">
                            <td
                              className="text-right px-4 font-bold "
                              colSpan="4"
                            >
                              Total
                            </td>
                            <td className="w-32 text-right px-4 ">
                              <NumericFormat
                                decimalSeparator=","
                                thousandsGroupStyle="thousand"
                                thousandSeparator="."
                                value={purchaseorder.total}
                                className="font-bold text-right"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className="my-4">
                      <label className="block py-1 text-sm">Keterangan</label>
                      {purchaseorder.note}
                    </div>
                  </div>
                </div>

                <div className="flex flex-col md:flex-row mt-4">
                  {modalAddPayment && (
                    <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50 flex items-center justify-center">
                      <div className="bg-white w-1/2 rounded-md shadow-lg">
                        <div className="flex justify-between items-center px-4 py-2 border-b border-gray-200">
                          Tambah pembayaran
                        </div>
                        <div className="px-4 py-2 flex">
                          <div className="w-1/2 pr-4">
                            <Input
                              label="Tanggal"
                              type="date"
                              value={payment.date}
                              onChange={(e) =>
                                setPayment({
                                  ...payment,
                                  date: e.target.value,
                                })
                              }
                            />
                          </div>
                          <div className="w-1/2 pr-4">
                            <Input
                              label="Jumlah pembayaran"
                              type="number"
                              value={payment.amount}
                              onChange={(e) =>
                                setPayment({
                                  ...payment,
                                  amount: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className="px-4 py-2 flex">
                          <div className="w-full pr-4">
                            <Textarea
                              label="Catatan"
                              value={payment.note}
                              onChange={(e) =>
                                setPayment({
                                  ...payment,
                                  note: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className="flex gap-2 items-center  justify-end px-4 py-2 border-t border-gray-200">
                          <Button
                            label="Tutup"
                            onClick={() => setModalAddPayment(false)}
                          />
                          <ButtonSuccess
                            label="Simpan pembayaran"
                            onClick={() => submitPayment()}
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="w-full md:w-3/4 pr-4">
                    <div className="flex justify-between items-center mb-4">
                      <div>
                        <h4 className="py-2 text-sm font-bold text-rose-700">
                          Riwayat pembayaran
                        </h4>
                      </div>
                      <div>
                        <span class="">
                          <Button
                            label="Tambah pembayaran"
                            onClick={() => setModalAddPayment(true)}
                          />
                        </span>
                      </div>
                    </div>

                    <div className="border">
                      <table className="table-auto text-xs w-full">
                        <thead className="bg-gray-100">
                          <tr>
                            <th className="w-2 px-4 py-2">No</th>
                            <th className="w-64 text-left px-4 py-2">
                              Nomor pembayaran
                            </th>
                            <th className="w-32 text-left  px-4 py-2">
                              Tanggal
                            </th>
                            <th className=" text-left  px-4 py-2">Catatan</th>
                            <th className="w-64 text-right  px-4 py-2">
                              Jumlah pembayaran
                            </th>
                          </tr>
                        </thead>

                        <tbody>
                          {purchaseorder.payments.map((item, index) => (
                            <tr className="">
                              <td className="text-center px-4">{index + 1}</td>
                              <td className=" px-4 py-1">{item.number}</td>
                              <td className=" px-4 py-1">
                                {new Date(item.date).toLocaleDateString()}
                              </td>
                              <td className=" px-4 py-1">{item.note}</td>
                              <td className=" px-4 py-1 text-right">
                                {item.amount}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div className=" my-4 py-2">
                      <table className="table-auto text-sm w-full">
                        <tbody>
                          <tr className="">
                            <td
                              className="text-right px-4 font-bold "
                              colSpan="4"
                            >
                              Total pembayaran
                            </td>
                            <td className="w-32 text-right px-4 ">
                              <NumericFormat
                                decimalSeparator=","
                                thousandsGroupStyle="thousand"
                                thousandSeparator="."
                                value={purchaseorder.payments.reduce(
                                  (a, b) => a + b.amount,
                                  0
                                )}
                                className="font-bold text-right"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </>
            )}

            <div className="flex-1"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PurchaseOrderAdd;

import React from "react";

import TopHead from "../Layouts/TopHead";
import Sidemenu from "../Layouts/Sidemenu";
import TableGrid from "../../components/TableGrid";
import LoadingBackdrop from "../../components/LoadingBackdrop";
import { NumericFormat } from "react-number-format";
import { PatternFormat } from "react-number-format";

import {
  Input,
  InputXs,
  Button,
  ButtonError,
  ButtonSuccess,
  ButtonBack,
  Textarea,
} from "../../components/Form";

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Config from "../../config/Config";
import Api from "../../services/Api";

import AsyncSelect from "react-select/async";

function TemplateShareAdd() {
  const navigate = useNavigate();

  const today = new Date();

  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(
    today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate()
  );

  const [suppliers, setSuppliers] = useState([]);
  const [supplier, setSupplier] = useState("");

  const [currencies, setCurrencies] = useState([]);
  const [currency, setCurrency] = useState("");

  const [products, setProducts] = useState([]);
  const [productFilteredList, setProductFilteredList] = useState([]);

  const [items, setItems] = useState([]);
  const [itemsQty, setItemsQty] = useState([]);

  const [note, setNote] = useState("");

  const [total, setTotal] = useState(0);

  const [warehouses, setWarehouses] = useState([]);
  const [fromWarehouse, setFromWarehouse] = useState("");
  const [toWarehouse, setToWarehouse] = useState("");

  const [filter, setFilter] = useState("");

  const [purchasings, setPurchasings] = useState([]);
  const [purchasing, setPurchasing] = useState("");
  const [purchasingItems, setPurchasingItems] = useState([]);

  const getWarehouse = async () => {
    const response = await Api.get(Config.api_url + "/data/warehouse");
    console.log(response);
    setWarehouses(response);
  };

  useEffect(() => {
    getWarehouse();
  }, []);

  const addItem = (product) => {
    const item = {
      id: product.id,
      name: product.name,
      sku: product.sku,
      stock: product.stock,
      qty: 1,
    };

    // check if item already exist
    const exist = items.find((x) => x.id === item.id);
    if (exist) {
      toast.error("Item sudah ada");
    } else {
      console.log(item);

      const newItems = [...items, item];
      setItems(newItems);
      autocompleteOff();
    }
  };

  const filterProductList = (e) => {
    const response = Api.get(
      Config.api_url +
        "/data/product/getbywarehouse?warehouse_id=" +
        fromWarehouse +
        "&filter=" +
        e.target.value
    ).then((response) => {
      console.log(response);
      setProducts(response);
      setProductFilteredList(response);
    });
  };

  const updateItemQty = (value, id) => {
    const qty = value;
    console.log(qty);

    const newItems = items.map((item) =>
      item.id === id ? { ...item, qty: qty } : item
    );
    setItems(newItems);
  };

  const submit = () => {
    // validation
    if (items.length === 0) {
      toast.error("Item harus diisi");
      return;
    }

    items.map((item) => {
      if (item.qty < 1) {
        toast.error("Qty tidak boleh 0");
        return;
      }

      if (item.qty > item.stock) {
        toast.error("Qty tidak boleh melebihi stok");
        return;
      }
    });

    setLoading(true);

    // create post template
    Api.post(Config.api_url + "/data/templateshare", {
      date: date,
      purchasing_id: purchasing,
      note: note,
      items: itemsQty,
    }).then((response) => {
      console.log(response);
      setLoading(false);

      if (response) {
        toast("Data berhasil disimpan", {
          type: "success",
        });

        setTimeout(() => {
          navigate("/templateshare");
        }, 1000);
      } else {
        toast("Data gagal disimpan", {
          type: "error",
          autoClose: 2000,
        });
      }
    });
  };

  const removeItem = (id) => {
    const newItems = items.filter((item) => item.id !== id);
    setItems(newItems);
  };

  const autocompleteOn = () => {
    // check if supplier is selected
    if (fromWarehouse === "") {
      toast.error("Pilih gudang asal terlebih dahulu");
      return;
    }

    // search product
    const response = Api.get(
      Config.api_url +
        "/data/product/getbywarehouse?warehouse_id=" +
        fromWarehouse +
        "&filter=" +
        filter
    ).then((response) => {
      console.log(response);
      setProducts(response);
      setProductFilteredList(response);
    });

    const dropdown = document.querySelector(`#autocomplete`);
    dropdown.classList.remove("hidden");
  };

  const autocompleteOff = () => {
    setTimeout(() => {
      const dropdown = document.querySelector(`#autocomplete`);
      dropdown.classList.add("hidden");
    }, 50);
  };

  const filterPurchasing = (inputValue) => {
    const response = Api.get(
      Config.api_url + "/data/purchasing/?filter=" + inputValue
    ).then((response) => {
      console.log(response);
      setPurchasings(response);

      return response.map((purchasing) => ({
        value: purchasing.id,
        label: purchasing.number,
      }));
    });

    return response;
  };

  const promisePurchasing = (inputValue) =>
    new Promise((resolve) => {
      resolve(filterPurchasing(inputValue));
    });

  const selectPurchasing = (e) => {
    console.log(e);
    const response = Api.get(
      Config.api_url + "/data/purchasing/" + e.value
    ).then((response) => {
      console.log(response);
      setPurchasing(e.value);
      setPurchasingItems(response.items);
      setItems(response.items);

      const itemsQty = {};

      response.items.map((item) => {
        var items = {};

        warehouses.map((warehouse) => {
          let thisQtyWarehouse = {};
          thisQtyWarehouse = {
            qty: 0,
          };

          items[warehouse.id] = thisQtyWarehouse;

          console.log("items", items);
        });

        itemsQty[item.product.id] = items;
      });

      console.log("itemQty", itemsQty);
      setItemsQty(itemsQty);
    });
  };

  return (
    <div>
      {/* side menu tailwind */}
      <TopHead />
      <ToastContainer />
      <div className="flex flex-col md:flex-row h-screen">
        <Sidemenu />
        <div className="flex-1 overflow-scroll">
          <div className="p-4 pt-16">
            <div className="flex justify-between items-center mb-4">
              <div>
                <h3 className="py-2 font-bold text-rose-700">
                  Tambah template
                </h3>
              </div>
            </div>

            <div className="flex flex-col md:flex-row">
              <div className="w-full md:w-1/4 pr-4">
                <label className="block py-2 text-sm">Nomor Pembelian</label>
                <AsyncSelect
                  cacheOptions
                  loadOptions={promisePurchasing}
                  onChange={(e) => selectPurchasing(e)}
                  defaultOptions
                  styles={{
                    control: (base) => ({
                      ...base,
                      height: "36px",
                      minHeight: "36px",
                      // marginTop: "15px",
                      padding: "0 10px",
                      fontSize: "12px",
                      alignItems: "center",
                      borderRadius: "5px",
                    }),
                  }}
                />
              </div>
            </div>

            <div className="flex flex-col md:flex-row mt-4">
              <div className="w-full md:w-full pr-4">
                <div className="border h-80">
                  <table className="table-auto text-xs w-full">
                    <thead className="bg-gray-100">
                      <tr>
                        <th className="w-2 px-4 py-2">No</th>
                        <th className="w-48 text-left px-4 py-2">Nama</th>
                        {warehouses.length > 0 &&
                          warehouses.map((warehouse, index) => (
                            <th
                              className="w-24 text-center px-4 py-2"
                              key={index}>
                              {warehouse.name}
                            </th>
                          ))}
                      </tr>
                    </thead>

                    <tbody>
                      {items.map((item, index) => (
                        <tr className="" key={index}>
                          <td className="text-center  px-4">{index + 1}</td>
                          <td className=" px-4">{item.product.name}</td>
                          {warehouses.length > 0 &&
                            warehouses.map((warehouse, index) => (
                              <td
                                className="w-24 text-center px-4 py-2"
                                key={index}>
                                <Input
                                  type="number"
                                  defaultValue={0}
                                  onChange={(e) => {
                                    console.log(e.target.value);
                                    const value = e.target.value;
                                    const newItemsQty = itemsQty;
                                    newItemsQty[item.product.id][
                                      warehouse.id
                                    ].qty = parseInt(value);
                                    setItemsQty(newItemsQty);
                                    console.log("newItemsQty", newItemsQty);
                                  }}
                                />
                              </td>
                            ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                {/* <div className=" my-4 py-2">
                  <table className="table-auto text-sm w-full">
                    <tbody>
                      <tr className="">
                        <td className="text-right px-4 font-bold " colSpan="4">
                          Total
                        </td>
                        <td className="w-32 text-right px-4 ">
                          <NumericFormat
                            decimalSeparator=","
                            thousandsGroupStyle="thousand"
                            thousandSeparator="."
                            value={total}
                            className="font-bold text-right"
                          />
                        </td>
                        <td className="w-32 px-4"></td>
                      </tr>
                    </tbody>
                  </table>
                </div> */}

                <div className="my-4">
                  <Textarea
                    label="Catatan"
                    placeholder="Tulis catatan"
                    value={note}
                    onChange={(e) => setNote(e.target.value)}></Textarea>
                </div>
              </div>
            </div>

            <div className="flex-1"></div>

            {loading ? (
              <LoadingBackdrop />
            ) : (
              <>
                <ButtonBack
                  label="Kembali"
                  onClick={() => {
                    navigate("/templateshare");
                  }}
                />
                <ButtonSuccess label="Simpan" onClick={submit} />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TemplateShareAdd;

import React from "react";

import TopHead from "../Layouts/TopHead";
import Sidemenu from "../Layouts/Sidemenu";
import TableGrid from "../../components/TableGrid";
import LoadingBackdrop from "../../components/LoadingBackdrop";
import { NumericFormat } from "react-number-format";
import { PatternFormat } from "react-number-format";

import {
  Input,
  InputXs,
  Button,
  ButtonError,
  ButtonSuccess,
  ButtonBack,
  Textarea,
} from "../../components/Form";

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Config from "../../config/Config";
import Api from "../../services/Api";

function SalesAdd() {
  const navigate = useNavigate();

  const today = new Date();

  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(
    today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate()
  );

  const [customers, setCustomers] = useState([]);
  const [customer, setCustomer] = useState("");
  const [customerObject, setCustomerObject] = useState({});

  const [currencies, setCurrencies] = useState([]);
  const [currency, setCurrency] = useState("");

  const [warehouses, setWarehouses] = useState([]);
  const [warehouse, setWarehouse] = useState("");

  const [discounts, setDiscounts] = useState([]);
  const [discount, setDiscount] = useState(0);
  const [discountPercentage, setDiscountPercentage] = useState(0);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [modalDiscount, setModalDiscount] = useState(false);

  const [taxes, setTaxes] = useState([]);
  const [tax, setTax] = useState(0);
  const [taxPercentage, setTaxPercentage] = useState(0);
  const [taxAmount, setTaxAmount] = useState(0);
  const [modalTax, setModalTax] = useState(false);

  const [products, setProducts] = useState([]);
  const [productFilteredList, setProductFilteredList] = useState([]);

  const [items, setItems] = useState([]);

  const [note, setNote] = useState("");

  const [subtotal, setSubtotal] = useState(0);
  const [total, setTotal] = useState(0);

  const [filter, setFilter] = useState("");

  // cash, transfer, debit, credit, ecommerce
  const [paymentMethods, setPaymentMethods] = useState([
    "Cash",
    "Transfer",
    "Debit",
    "Credit",
    "Ecommerce",
  ]);

  const [paymentMethod, setPaymentMethod] = useState("Cash");
  const [paymentAmount, setPaymentAmount] = useState(0);
  const [paymentReference, setPaymentReference] = useState("");

  const getCustomer = async () => {
    const response = await Api.get(Config.api_url + "/data/customer");
    console.log(response);
    setCustomers(response);
  };

  const getCurrency = async () => {
    const response = await Api.get(Config.api_url + "/data/currency");
    console.log(response);
    setCurrencies(response);

    response.map((item) => {
      if (item.is_default === 1) {
        setCurrency(item.id);
      }
    });
  };

  const getWarehouse = async () => {
    const response = await Api.get(Config.api_url + "/data/warehouse");
    console.log(response);
    setWarehouses(response);
    setWarehouse(response[0].id);
  };

  const getDiscount = async () => {
    const response = await Api.get(Config.api_url + "/data/discount");
    console.log(response);
    setDiscounts(response);
  };

  const getTax = async () => {
    const response = await Api.get(Config.api_url + "/data/tax");
    console.log(response);
    setTaxes(response);
  };

  const getProductBySupplier = async (supplier_id) => {
    // setSupplier(supplier_id);
    // const response = await Api.get(
    //   Config.api_url + "/data/product/supplier/" + supplier_id
    // );
    // console.log(response);
    // setProducts(response);
    // setProductFilteredList(response);
  };

  useEffect(() => {
    getCustomer();
    getCurrency();
    getWarehouse();
    getDiscount();
    getTax();
  }, []);

  const addItem = (product) => {
    const item = {
      id: product.id,
      name: product.name,
      price: product.cost,
      qty: 1,
      subtotal: product.cost,
      discounts: product.discounts,
    };

    // check if item already exist
    const exist = items.find((x) => x.id === item.id);
    if (exist) {
      toast.error("Item sudah ada");
    } else {
      console.log(item);

      const newItems = [...items, item];
      setItems(newItems);
      autocompleteOff();
    }
  };

  const filterProductList = (e) => {
    const response = Api.get(
      Config.api_url + "/data/product/?page=1&filter=" + e.target.value
    ).then((response) => {
      console.log(response);
      setProducts(response);
      setProductFilteredList(response);
    });
  };

  const updateItemPrice = (value, id) => {
    const price = value;
    console.log(price);

    const newItems = items.map((item) =>
      item.id === id ? { ...item, price: price } : item
    );
    setItems(newItems);
  };

  const updateItemQty = (value, id) => {
    const qty = value;
    console.log(qty);

    const newItems = items.map((item) =>
      item.id === id ? { ...item, qty: qty } : item
    );
    setItems(newItems);
  };

  const recalculateTotal = () => {
    let total = 0;
    let subtotal = 0;
    console.log("customer object 2: ", customerObject.customer_group_id);
    items.forEach((item) => {
      item.finalPrice = item.price;

      // check if discount exist
      if (item.discounts !== null) {
        item.discounts.map((discount) => {
          if (discount.customer_group_id === customerObject.customer_group_id) {
            console.log("discount found");

            let discountAmount = item.finalPrice * (discount.discount / 100);
            item.finalPrice = item.finalPrice - discountAmount;
            item.discountPercentage = discount.discount;

            let discountAmountPlus = 0;

            item.discountPercentagePlus = 0;
            if (discount.discount_plus > 0) {
              discountAmountPlus =
                item.finalPrice * (discount.discount_plus / 100);

              item.discountPercentagePlus = discount.discount_plus;
            }

            console.log("discountAmount: " + discountAmount);

            item.discount = discountAmount + discountAmountPlus;
            if (discount.from_date >= date || discount.to_date <= date) {
            }
          }
        });
      }

      item.subtotal = item.finalPrice * item.qty;

      subtotal += item.subtotal;
      total += item.subtotal;
    });

    if (discount != "") {
      setDiscountAmount(total * (discountPercentage / 100));
      total = total - total * (discountPercentage / 100);
    }

    if (tax != "") {
      setTaxAmount(total * (taxPercentage / 100));
      total = total + total * (taxPercentage / 100);
    }

    setItems(items);
    setSubtotal(subtotal);
    setTotal(total);

    setPaymentAmount(total);
  };

  useEffect(() => {
    recalculateTotal();
  }, [items]);

  const submit = () => {
    // validation
    if (date === "") {
      toast.error("Tanggal harus diisi");
      return;
    }

    if (customer === "") {
      toast.error("Supplier harus diisi");
      return;
    }

    if (currency === "") {
      toast.error("Mata uang harus diisi");
      return;
    }

    if (items.length === 0) {
      toast.error("Item harus diisi");
      return;
    }

    setLoading(true);

    // create post sales
    Api.post(Config.api_url + "/data/sales", {
      date: date,
      customer_id: customer,
      currency_id: currency,
      note: note,
      items: items,
      discount: discount,
      discount_amount: discountAmount,
      discount_percentage: discountPercentage,
      tax: tax,
      tax_amount: taxAmount,
      tax_percentage: taxPercentage,
      warehouse_id: warehouse,
      payment_method: paymentMethod,
      payment_amount: paymentAmount,
      payment_reference: paymentReference,
      subtotal: subtotal,
      total: total,
    })
      .then((response) => {
        console.log(response);

        if (response) {
          toast("Data berhasil disimpan", {
            type: "success",
          });

          setTimeout(() => {
            navigate("/sales");
          }, 1000);
        } else {
          toast("Data gagal disimpan", {
            type: "error",
          });
        }

        setLoading(false);
      })
      .catch((error) => {
        console.log(error);

        toast("Data gagal disimpan " + error, {
          type: "error",
        });

        setLoading(false);
      });
  };

  const removeItem = (id) => {
    const newItems = items.filter((item) => item.id !== id);
    setItems(newItems);
  };

  const autocompleteOn = () => {
    // check if supplier is selected
    if (customer === "") {
      toast.error("Pilih pelanggan dahulu");
      return;
    }

    const response = Api.get(
      Config.api_url + "/data/product/?page=1&filter=" + filter
    ).then((response) => {
      console.log(response);
      setProducts(response);
      setProductFilteredList(response);
    });

    const dropdown = document.querySelector(`#autocomplete`);
    dropdown.classList.remove("hidden");
  };

  const autocompleteOff = () => {
    const dropdown = document.querySelector(`#autocomplete`);
    dropdown.classList.add("hidden");
  };

  return (
    <div>
      {/* side menu tailwind */}
      <TopHead />
      <ToastContainer />
      <div className="flex flex-col md:flex-row h-screen">
        <Sidemenu />
        <div className="flex-1 overflow-scroll">
          <div className="p-4 pt-16">
            <div className="flex justify-between items-center mb-4">
              <div>
                <h3 className="py-2 font-bold text-rose-700">Tambah sales</h3>
              </div>
            </div>

            <div className="flex flex-col md:flex-row">
              <div className="w-full md:w-1/4 pr-4">
                <Input
                  label="Tanggal"
                  type="date"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                />
              </div>
              <div className="w-full md:w-1/4 pr-4">
                <label className="block py-2 text-sm">Pelanggan</label>
                <select
                  onChange={(e) => {
                    setCustomer(e.target.value);
                    setCustomerObject(
                      customers.find((item) => item.id === e.target.value)
                    );

                    console.log("customer object: ", customerObject);
                  }}
                  className="w-full block px-2 py-1 border border-gray-300 text-sm rounded-md focus:outline-none focus:border-rose-500"
                >
                  <option value="">- Pilih customer -</option>
                  {customers.length > 0 &&
                    customers.map((item) => (
                      <option value={item.id}>{item.name}</option>
                    ))}
                </select>
              </div>
              <div className="w-full md:w-1/4 pr-4">
                <label className="block py-2 text-sm">Mata uang</label>
                <select
                  defaultValue={currency}
                  onChange={(e) => setCurrency(e.target.value)}
                  className="w-full block px-2 py-1 border border-gray-300 text-sm rounded-md focus:outline-none focus:border-rose-500"
                >
                  {currencies.length > 0 &&
                    currencies.map((item) => (
                      <option
                        value={item.id}
                        selected={item.is_default == 1 ? "selected" : ""}
                      >
                        {item.code}
                      </option>
                    ))}
                </select>
              </div>

              <div className="w-full md:w-1/4 pr-4">
                <label className="block py-2 text-sm">Gudang</label>
                <select
                  defaultValue={warehouse}
                  onChange={(e) => setWarehouse(e.target.value)}
                  className="w-full block px-2 py-1 border border-gray-300 text-sm rounded-md focus:outline-none focus:border-rose-500"
                >
                  {warehouses.length > 0 &&
                    warehouses.map((item) => (
                      <option
                        value={item.id}
                        selected={item.is_default == 1 ? "selected" : ""}
                      >
                        {item.name}
                      </option>
                    ))}
                </select>
              </div>
            </div>

            <div className="flex flex-col md:flex-row mt-2">
              <div className="w-full md:w-2/4 pr-4">
                <label className="block py-2 text-sm">Tambah Item barang</label>

                <input
                  type="text"
                  onFocus={autocompleteOn}
                  onChange={(e) => filterProductList(e)}
                  // onBlur={autocompleteOff}
                  placeholder="Cari nama produk untuk menambahkan item"
                  className="w-full px-2 py-1 border border-gray-300 text-sm rounded-md focus:outline-none focus:border-rose-500"
                />

                <div
                  id="autocomplete"
                  className="absolute w-1/4 h-48 bg-white p-4 border -mt-1 text-xs rounded hidden"
                >
                  <div className="overflow-y-scroll h-32">
                    <ul>
                      {productFilteredList.length > 0 &&
                        productFilteredList.map((item) => (
                          <li className="py-1 ">
                            <button
                              class="flex flex-col text-indigo-800"
                              onClick={() => addItem(item)}
                            >
                              <div className="flex justify-start items-center">
                                <img
                                  src={Config.api_url + "/" + item.image}
                                  width="30"
                                  className="mr-2"
                                />{" "}
                                {item.name}
                              </div>
                            </button>
                          </li>
                        ))}
                    </ul>
                  </div>
                  <div className="flex justify-end">
                    <button
                      onClick={autocompleteOff}
                      className="bg-gray-200 px-2 py-1 rounded text-xs"
                    >
                      Tutup
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col md:flex-row mt-4">
              <div className="w-full md:w-full pr-4">
                <div className="border h-80">
                  <table className="table-auto text-xs w-full">
                    <thead className="bg-gray-100">
                      <tr>
                        <th className="w-2 px-4 py-2">No</th>
                        <th className="text-left px-4 py-2">Nama</th>
                        <th className="w-32 text-left  px-4 py-2">Harga</th>
                        <th className="w-24 text-left  px-4 py-2">Jumlah</th>
                        <th className="w-24 text-left  px-4 py-2">Diskon</th>
                        <th className="w-32 text-right  px-4 py-2">Subtotal</th>
                        <th className="w-16 px-4 py-2">Aksi</th>
                      </tr>
                    </thead>

                    <tbody>
                      {items.map((item, index) => (
                        <tr className="">
                          <td className="text-center  px-4">{index + 1}</td>
                          <td className=" px-4">{item.name}</td>
                          <td className=" px-4">
                            <InputXs
                              type="number"
                              value={item.price}
                              onChange={(e) =>
                                updateItemPrice(e.target.value, item.id)
                              }
                            />
                          </td>
                          <td className=" px-4">
                            <InputXs
                              type="number"
                              value={item.qty}
                              onChange={(e) =>
                                updateItemQty(e.target.value, item.id)
                              }
                            />
                          </td>
                          <td className=" px-4">{item.discount}</td>
                          <td className=" px-4 text-right">
                            <NumericFormat
                              decimalSeparator=","
                              thousandsGroupStyle="thousand"
                              thousandSeparator="."
                              value={item.subtotal}
                              className="text-right"
                            />
                          </td>
                          <td className=" px-4 text-center">
                            <button
                              onClick={() => removeItem(item.id)}
                              className="bg-red-500 text-xs hover:bg-red-700 text-white font-bold py-1 px-2 rounded"
                            >
                              Hapus
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                <div className="flex flex-col md:flex-row mt-2">
                  <div className="w-full md:w-1/2 pr-4">
                    <div className="my-4">
                      <Textarea
                        label="Catatan"
                        placeholder="Tulis catatan Sales"
                        value={note}
                        onChange={(e) => setNote(e.target.value)}
                      ></Textarea>
                    </div>
                  </div>
                  <div className="my-4 py-2">
                    <table className="table-auto text-sm w-full">
                      <tbody>
                        <tr>
                          <td className="w-80 text-right px-4 py-2 font-bold">
                            Diskon tambahan
                            <p>
                              <a
                                href="#"
                                className="text-xs font-normal text-red-600"
                                onClick={() => setModalDiscount(true)}
                              >
                                Tambahkan diskon
                              </a>
                            </p>
                          </td>
                          <td className=" text-right px-4 py-2 ">
                            <NumericFormat
                              decimalSeparator=","
                              thousandsGroupStyle="thousand"
                              thousandSeparator="."
                              value={discountAmount}
                              className="font-bold text-right"
                            />
                          </td>
                          <td className="w-16 px-4"></td>
                        </tr>

                        <tr className="">
                          <td className="w-full text-right px-4 font-bold">
                            Pajak
                            <p>
                              <a
                                href="#"
                                className="text-xs font-normal text-red-600"
                                onClick={() => setModalTax(true)}
                              >
                                Tambahkan pajak
                              </a>
                            </p>
                          </td>
                          <td className="text-right px-4 ">
                            <NumericFormat
                              decimalSeparator=","
                              thousandsGroupStyle="thousand"
                              thousandSeparator="."
                              value={taxAmount}
                              className="font-bold text-right"
                            />
                          </td>
                          <td className="w-16 px-4"></td>
                        </tr>
                        <tr className="">
                          <td className="w-full text-right px-4 py-4 font-bold">
                            Total
                          </td>
                          <td className=" text-right px-4 ">
                            <NumericFormat
                              decimalSeparator=","
                              thousandsGroupStyle="thousand"
                              thousandSeparator="."
                              value={total}
                              className="font-bold text-right text-green-600"
                            />
                          </td>
                          <td className="w-16 px-4"></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className=" mt-2">
                  <p className="font-bold">Pembayaran</p>

                  <div className="pt-2 pb-4 flex">
                    <div className="w-full md:w-1/4 pr-4">
                      <label className="block py-2 text-sm">
                        Metode pembayaran
                      </label>
                      <select
                        label="Metode Pembayaran"
                        value={paymentMethod}
                        className="w-full block px-2 py-1 border border-gray-300 text-sm rounded-md focus:outline-none focus:border-rose-500"
                        onChange={(e) => setPaymentMethod(e.target.value)}
                      >
                        <option value="">Pilih metode pembayaran</option>

                        {paymentMethods.map((paymentMethod) => (
                          <option value={paymentMethod}>{paymentMethod}</option>
                        ))}
                      </select>
                    </div>
                    <div className="w-full md:w-1/4 pr-4">
                      <Input
                        label="Jumlah pembayaran"
                        type="number"
                        value={paymentAmount}
                        onChange={(e) => setPaymentAmount(e.target.value)}
                      />
                    </div>
                    <div className="w-full md:w-1/4 pr-4">
                      <Input
                        label="Referensi pembayaran"
                        type="text"
                        value={paymentReference}
                        onChange={(e) => setPaymentReference(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {modalDiscount && (
              <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50 flex items-center justify-center">
                <div className="bg-white md:w-96 w-96 rounded-md shadow-lg">
                  <div className="flex justify-between items-center px-4 py-2 border-b border-gray-200 gap-2">
                    <div className="h-6 grow">Diskon</div>
                  </div>

                  <div className="h-16 overflow-auto py-2 px-4">
                    <div className="flex justify-between items-center">
                      <div className="grow">
                        <select
                          onChange={(e) => {
                            console.log(e.target.value);
                            setDiscount(e.target.value);
                            let discountObj = discounts.find(
                              (item) => item.id == e.target.value
                            );

                            setDiscountPercentage(
                              discountObj.discount_percentage
                            );
                          }}
                          className="w-full block px-2 py-1 border border-gray-300 text-sm rounded-md focus:outline-none focus:border-rose-500"
                        >
                          <option value="">- Pilih diskon -</option>
                          {discounts.length > 0 &&
                            discounts.map((item) => (
                              <option value={item.id}>
                                {item.name} ({item.discount_percentage} %)
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="px-4 py-2 flex">
                    <div className="w-full pr-4"></div>
                  </div>
                  <div className="flex gap-2 items-center  justify-end px-4 py-2 border-t border-gray-200">
                    <Button
                      label="Tutup"
                      onClick={() => setModalDiscount(false)}
                    />
                    <ButtonSuccess
                      label="Tambahkan"
                      onClick={() => {
                        setModalDiscount(false);

                        setTimeout(() => {
                          recalculateTotal();
                        }, 300);
                      }}
                    />
                  </div>
                </div>
              </div>
            )}

            {modalTax && (
              <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50 flex items-center justify-center">
                <div className="bg-white md:w-96 w-96 rounded-md shadow-lg">
                  <div className="flex justify-between items-center px-4 py-2 border-b border-gray-200 gap-2">
                    <div className="h-6 grow">Pajak</div>
                  </div>

                  <div className="h-16 overflow-auto py-2 px-4">
                    <div className="flex justify-between items-center">
                      <div className="grow">
                        <select
                          onChange={(e) => {
                            console.log(e.target.value);
                            setTax(e.target.value);
                            let taxObj = taxes.find(
                              (item) => item.id == e.target.value
                            );

                            setTaxPercentage(taxObj.tax_percentage);
                          }}
                          className="w-full block px-2 py-1 border border-gray-300 text-sm rounded-md focus:outline-none focus:border-rose-500"
                        >
                          <option value="">- Pilih pajak -</option>
                          {taxes.length > 0 &&
                            taxes.map((item) => (
                              <option value={item.id}>
                                {item.name} ({item.tax_percentage} %)
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="px-4 py-2 flex">
                    <div className="w-full pr-4"></div>
                  </div>
                  <div className="flex gap-2 items-center  justify-end px-4 py-2 border-t border-gray-200">
                    <Button label="Tutup" onClick={() => setModalTax(false)} />
                    <ButtonSuccess
                      label="Tambahkan"
                      onClick={() => {
                        setModalTax(false);

                        setTimeout(() => {
                          recalculateTotal();
                        }, 300);
                      }}
                    />
                  </div>
                </div>
              </div>
            )}

            <div className="flex-1"></div>

            {loading ? (
              <LoadingBackdrop />
            ) : (
              <>
                <ButtonBack label="Kembali" />
                <ButtonSuccess label="Simpan" onClick={submit} />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SalesAdd;

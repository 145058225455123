import React from "react";
import Sidemenu from "../Layouts/Sidemenu";
import TableGrid from "../../components/TableGrid";
import TopHead from "../Layouts/TopHead";
import LoadingBackdrop from "../../components/LoadingBackdrop";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Button, ButtonSuccess } from "../../components/Form";

import { useState, useEffect } from "react";

import Config from "../../config/Config";
import Api from "../../services/Api";

function ProductImport() {
  const [file, setFile] = useState("");
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState([]);

  const uploadFile = async (e) => {
    console.log("Upload");

    setLoading(true);

    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    const file = e.target.files[0];

    var formdata = new FormData();
    formdata.append("file", file, file.name);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(Config.api_url + "/data/product/import", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        var data = JSON.parse(result);
        setData(data);
        setLoading(false);
      })
      .catch((error) => console.log("error", error));
  };

  const submit = async () => {
    setLoading(true);

    Api.post(Config.api_url + "/data/product/importprocess", {
      data: data,
    })

      .then((response) => {
        console.log(response);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  return (
    <div>
      {/* side menu tailwind */}
      <TopHead />
      <ToastContainer />
      <div className="flex flex-col md:flex-row h-screen">
        <Sidemenu />
        <div className="flex-1 overflow-scroll">
          <div className="p-8 pt-12"></div>
          <div className="p-4 pt-2">Impor Produk</div>

          <div className="p-4">
            <input
              type="file"
              onChange={uploadFile}
              className="w-full block px-2 py-1 border border-gray-300 text-sm rounded-md focus:outline-none focus:border-rose-500"
            />
          </div>
          <div className="flex-1 ">
            <div className="p-4 text-sm pt-2">Preview Impor</div>

            <div className="p-4 border">
              <table className="table-auto border w-full text-xs">
                <thead>
                  <tr>
                    <th className="px-4 py-2 w-32">Kode</th>
                    <th className="px-4 py-2 w-32">Nama</th>
                    <th className="px-4 py-2 w-32">Supplier</th>
                    <th className="px-4 py-2 w-32">Kategori</th>
                    <th className="px-4 py-2 w-32">Brand</th>
                    <th className="px-4 py-2 w-32">Sub Brand</th>
                    <th className="px-4 py-2 w-32">Series</th>
                    <th className="px-4 py-2 w-32">Lisensi</th>
                    <th className="px-4 py-2 w-32">Tipe</th>
                    <th className="px-4 py-2 w-32">Grade</th>
                  </tr>
                </thead>
              </table>
            </div>

            <div className="p-4 overflow-scroll h-96 ">
              <table className="table-auto w-full text-xs">
                <tbody>
                  {data.map(
                    (item, index) =>
                      index > 0 && (
                        <tr key={index}>
                          <td className="border px-4 py-2 w-32">{item[0]}</td>
                          <td className="border px-4 py-2 w-32">{item[1]}</td>
                          <td className="border px-4 py-2 w-32">{item[2]}</td>
                          <td className="border px-4 py-2 w-32">{item[3]}</td>
                          <td className="border px-4 py-2 w-32">{item[4]}</td>
                          <td className="border px-4 py-2 w-32">{item[5]}</td>
                          <td className="border px-4 py-2 w-32">{item[6]}</td>
                          <td className="border px-4 py-2 w-32">{item[7]}</td>
                          <td className="border px-4 py-2 w-32">{item[8]}</td>
                          <td className="border px-4 py-2 w-32">{item[9]}</td>
                        </tr>
                      )
                  )}
                </tbody>
              </table>
            </div>

            <div className="p-4">
              <ButtonSuccess label="Proses Impor" onClick={submit} />
            </div>
          </div>
        </div>
      </div>

      {loading && <LoadingBackdrop />}
    </div>
  );
}

export default ProductImport;

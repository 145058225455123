import React from "react";

import TopHead from "../Layouts/TopHead";
import Sidemenu from "../Layouts/Sidemenu";
import TableGrid from "../../components/TableGrid";
import LoadingBackdrop from "../../components/LoadingBackdrop";
import {
  Input,
  Button,
  ButtonError,
  ButtonSuccess,
  ButtonBack,
} from "../../components/Form";

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Config from "../../config/Config";
import Api from "../../services/Api";

function SupplierAdd() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [attn, setAttn] = useState("");
  const [npwp, setNpwp] = useState("");
  const [area, setArea] = useState("");
  const [website, setWebsite] = useState("");

  const [discount, setDiscount] = useState(0);
  const [due, setDue] = useState(0);
  const [hutangAwal, setHutangAwal] = useState(0);

  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");

  const submit = () => {
    setLoading(true);

    // create post supplier
    Api.post(Config.api_url + "/data/supplier", {
      name: name,
      address: address,
      city: city,
      attn: attn,
      npwp: npwp,
      area: area,
      website: website,
      discount: discount,
      due: due,
      hutang_awal: hutangAwal,
      phone: phone,
      email: email,
    }).then((response) => {
      console.log(response);

      if (response) {
        toast("Data berhasil disimpan", {
          type: "success",
        });
        setName("");
        setAddress("");
        setCity("");
        setAttn("");
        setNpwp("");
        setArea("");
        setWebsite("");
        setDiscount(0);
        setDue(0);
        setHutangAwal(0);
        setPhone("");
        setEmail("");
      } else {
        toast("Data gagal disimpan", {
          type: "error",
        });
      }

      setLoading(false);
    });
  };

  return (
    <div>
      {/* side menu tailwind */}
      <TopHead />
      <ToastContainer />
      <div className="flex flex-col md:flex-row h-screen">
        <Sidemenu />
        <div className="flex-1 overflow-scroll">
          <div className="p-4 pt-16">
            <div className="flex justify-between items-center mb-4">
              <div>
                <h3 className="py-2 font-bold text-rose-700">
                  Tambah supplier
                </h3>
              </div>
            </div>

            <div className="flex flex-col md:flex-row gap-8">
              <div className="w-1/3">
                <Input
                  label="Nama"
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <Input
                  label="Alamat"
                  type="text"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
                <Input
                  label="Kota"
                  type="text"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                />
                <Input
                  label="Telepon"
                  type="tel"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
                <Input
                  label="Email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <Input
                  label="Attn"
                  type="text"
                  value={attn}
                  onChange={(e) => setAttn(e.target.value)}
                />
                <Input
                  label="NPWP"
                  type="text"
                  value={npwp}
                  onChange={(e) => setNpwp(e.target.value)}
                />
                <Input
                  label="Area"
                  type="text"
                  value={area}
                  onChange={(e) => setArea(e.target.value)}
                />
                <Input
                  label="Website"
                  type="text"
                  value={website}
                  onChange={(e) => setWebsite(e.target.value)}
                />

                <div className="py-2"></div>
                {loading ? (
                  <LoadingBackdrop />
                ) : (
                  <>
                    <ButtonBack
                      label="Kembali"
                      onClick={() => {
                        navigate("/supplier");
                      }}
                    />
                    <ButtonSuccess label="Simpan" onClick={submit} />
                  </>
                )}
              </div>
              <div className="w-1/4">
                <Input
                  label="Diskon"
                  type="number"
                  value={discount}
                  onChange={(e) => setDiscount(e.target.value)}
                />
                <Input
                  label="Jatuh tempo"
                  type="number"
                  value={due}
                  onChange={(e) => setDue(e.target.value)}
                />
                <Input
                  label="Hutang awal"
                  type="number"
                  value={hutangAwal}
                  onChange={(e) => setHutangAwal(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SupplierAdd;

import React from "react";

import TopHead from "../Layouts/TopHead";
import Sidemenu from "../Layouts/Sidemenu";
import TableGrid from "../../components/TableGrid";
import LoadingBackdrop from "../../components/LoadingBackdrop";
import {
  Input,
  Button,
  ButtonError,
  ButtonSuccess,
  ButtonBack,
} from "../../components/Form";

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Config from "../../config/Config";
import Api from "../../services/Api";

function TaxAdd() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [taxPercentage, setTaxPercentage] = useState(0);
  const [type, setType] = useState("Inclusive");

  const submit = () => {
    setLoading(true);

    // create post tax
    Api.post(Config.api_url + "/data/tax", {
      name: name,
      tax_percentage: taxPercentage,
      type: type,
    }).then((response) => {
      console.log(response);
      setLoading(false);
    });

    toast("Data berhasil disimpan", {
      tax: "success",
    });

    setName("");
    setTaxPercentage(0);
    setType("Inclusive");
  };

  return (
    <div>
      {/* side menu tailwind */}
      <TopHead />
      <ToastContainer />
      <div className="flex flex-col md:flex-row h-screen">
        <Sidemenu />
        <div className="flex-1 overflow-hidden">
          <div className="p-4 pt-16">
            <div className="flex justify-between items-center mb-4">
              <div>
                <h3 className="py-2 font-bold text-rose-700">Tambah pajak</h3>
              </div>
            </div>

            <div className="w-1/4">
              <Input
                label="Nama"
                tax="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />

              <Input
                label="Presentase (%)"
                tax="number"
                value={taxPercentage}
                onChange={(e) => setTaxPercentage(e.target.value)}
              />

              <label className="block py-2 text-sm">Tipe</label>
              <select
                onChange={(e) => {
                  setType(e.target.value);
                }}
                className="w-full block px-2 py-1 border border-gray-300 text-sm rounded-md focus:outline-none focus:border-rose-500"
              >
                <option value="Inclusive">Inklusif</option>
                <option value="Exclusive">Eksklusif</option>
              </select>

              <div className="py-2"></div>
              {loading ? (
                <LoadingBackdrop />
              ) : (
                <>
                  <ButtonBack
                    label="Kembali"
                    onClick={() => {
                      navigate("/tax");
                    }}
                  />
                  <ButtonSuccess label="Simpan" onClick={submit} />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TaxAdd;

import React from "react";

import TopHead from "../Layouts/TopHead";
import Sidemenu from "../Layouts/Sidemenu";
import TableGrid from "../../components/TableGrid";
import LoadingBackdrop from "../../components/LoadingBackdrop";
import {
  Input,
  Button,
  ButtonError,
  ButtonSuccess,
  ButtonBack,
} from "../../components/Form";

import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Config from "../../config/Config";
import Api from "../../services/Api";

function TaxEdit() {
  const navigate = useNavigate();
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [taxPercentage, setTaxPercentage] = useState(0);
  const [type, setType] = useState("Inclusive");

  console.log(id);

  useEffect(() => {
    setLoading(true);

    // show data by id
    Api.get(Config.api_url + "/data/tax/" + id).then((response) => {
      console.log(response);
      setName(response.name);
      setTaxPercentage(response.tax_percentage);
      setType(response.type);
      setLoading(false);
    });
  }, []);

  const submit = () => {
    setLoading(true);

    // create post tax
    Api.put(Config.api_url + "/data/tax/" + id, {
      name: name,
      tax_percentage: taxPercentage,
      type: type,
    }).then((response) => {
      console.log(response);
      setLoading(false);
    });

    toast("Data berhasil disimpan", {
      tax: "success",
    });
  };

  return (
    <div>
      {/* side menu tailwind */}
      <TopHead />
      <ToastContainer />
      <div className="flex flex-col md:flex-row h-screen">
        <Sidemenu />
        <div className="flex-1 overflow-hidden">
          <div className="p-4 pt-16">
            <div className="flex justify-between items-center mb-4">
              <div>
                <h3 className="py-2 font-bold text-rose-700">Edit pajak</h3>
              </div>
            </div>

            <div className="w-1/4">
              <Input
                label="Nama"
                tax="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />

              <Input
                label="Presentase (%)"
                tax="number"
                value={taxPercentage}
                onChange={(e) => setTaxPercentage(e.target.value)}
              />

              <label className="block py-2 text-sm">Tipe</label>
              <select
                onChange={(e) => {
                  setType(e.target.value);
                }}
                className="w-full block px-2 py-1 border border-gray-300 text-sm rounded-md focus:outline-none focus:border-rose-500"
              >
                <option
                  value="Inclusive"
                  selected={type == "Inclusive" ? true : false}
                >
                  Inklusif
                </option>
                <option
                  value="Exclusive"
                  selected={type == "Exclusive" ? true : false}
                >
                  Eksklusif
                </option>
              </select>

              <div className="py-2"></div>
              {loading ? (
                <LoadingBackdrop />
              ) : (
                <>
                  <ButtonBack
                    label="Kembali"
                    onClick={() => {
                      navigate("/tax");
                    }}
                  />
                  <ButtonSuccess label="Simpan" onClick={submit} />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TaxEdit;

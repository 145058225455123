import React from "react";

import TopHead from "../Layouts/TopHead";
import Sidemenu from "../Layouts/Sidemenu";
import TableGrid from "../../components/TableGrid";
import LoadingBackdrop from "../../components/LoadingBackdrop";
import Modal from "../../components/Modal";
import { NumericFormat } from "react-number-format";
import { PatternFormat } from "react-number-format";

import {
  Input,
  InputXs,
  Button,
  ButtonError,
  ButtonSuccess,
  ButtonBack,
  Textarea,
} from "../../components/Form";

import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Config from "../../config/Config";
import Api from "../../services/Api";

function PurchasingAdd() {
  const navigate = useNavigate();
  const { id } = useParams();

  const [loading, setLoading] = useState(true);
  const [purchasing, setPurchasing] = useState({});

  const [modalAnalisa, setModalAnalisa] = useState(false);

  const [analisaItems, setAnalisaItems] = useState([]);
  const [defaultCurrency, setDefaultCurrency] = useState({});

  const today = new Date();

  const [payment, setPayment] = useState({
    purchase_order_id: id,
    date:
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate(),
    amount: "",
    note: "",
  });

  const getPurchasing = async () => {
    setLoading(true);

    const response = await Api.get(Config.api_url + "/data/purchasing/" + id);
    console.log(response);

    setPurchasing(response);

    var items = response.items.map((item) => {
      return {
        id: item.product.id,
        sku: item.product.sku,
        name: item.product.name,
        buy: item.price,
        discount: item.discount,
        qty: item.qty,
        shippingCost: item.shipping_cost,
        total: item.total,
        cost: parseInt(item.total * response.kurs) / item.qty,
        sell: (parseInt(item.total * response.kurs) / item.qty) * 1.15,
        profit: (parseInt(item.total * response.kurs) / item.qty) * 0.15,
        profitPercent: 15,
      };
    });

    setAnalisaItems(items);

    setLoading(false);
  };

  const getDefaultCurrency = async () => {
    const response = await Api.get(Config.api_url + "/data/currency/default");
    console.log(response);

    setDefaultCurrency(response);
  };

  useEffect(() => {
    getPurchasing();
    getDefaultCurrency();
  }, []);

  const updateSell = (id, value) => {
    const newItems = analisaItems.map((item) => {
      if (item.id === id) {
        item = {
          ...item,
          sell: value,
          profit: value - item.cost,
          profitPercent: ((value - item.cost) / item.cost) * 100,
        };
      }

      return item;
    });

    console.log(newItems);

    setAnalisaItems(newItems);
  };

  const updatePrice = async () => {
    const response = await Api.post(
      Config.api_url + "/data/purchasing/update-price-product",
      {
        items: analisaItems,
        purchasing_id: id,
      }
    );

    console.log(response);
    if (response.status == "success") {
      toast.success("Berhasil update harga");
      setModalAnalisa(false);
      getPurchasing();
    } else {
      toast.error("Gagal update harga");
    }
  };

  return (
    <div>
      {/* side menu tailwind */}
      <TopHead />
      <ToastContainer />
      <div className="flex flex-col md:flex-row h-screen">
        <Sidemenu />
        <div className="flex-1 overflow-scroll">
          <div className="p-4 pt-16">
            {loading ? (
              <LoadingBackdrop />
            ) : (
              <>
                <div className="flex justify-between items-center mb-4">
                  <div>
                    <h3 className="py-2 font-bold text-rose-700">
                      Pembelian : {purchasing.number}
                    </h3>
                  </div>
                </div>

                <div className="flex flex-col md:flex-row">
                  <div className="w-full md:w-1/4 pr-4">
                    <label className="block py-1 text-sm">Tanggal</label>
                    {new Date(purchasing.date).toLocaleDateString()}
                  </div>
                  <div className="w-full md:w-1/4 pr-4">
                    <label className="block py-1 text-sm">Supplier</label>
                    {purchasing.supplier && <>{purchasing.supplier.name}</>}
                  </div>
                  <div className="w-full md:w-1/4 pr-4">
                    <label className="block py-1 text-sm">Packing List</label>
                    {purchasing.packinglist && (
                      <>{purchasing.packinglist.number}</>
                    )}
                  </div>
                  <div className="w-full md:w-1/4 pr-4">
                    <label className="block py-1 text-sm">Mata uang</label>
                    {purchasing.currency && <>{purchasing.currency.code}</>}
                  </div>
                </div>
                <div className="flex flex-col md:flex-row mt-2">
                  <div className="w-full md:w-1/4 pr-4">
                    <label className="block py-1 text-sm">Jatuh tempo</label>
                    {new Date(purchasing.due_date).toLocaleDateString()}
                  </div>
                  <div className="w-full md:w-1/4 pr-4">
                    <label className="block py-1 text-sm">
                      Metode pengiriman
                    </label>
                    {purchasing.shipping_method}
                  </div>
                  <div className="w-full md:w-1/4 pr-4">
                    <label className="block py-1 text-sm">Gudang</label>
                    {purchasing.warehouse && <>{purchasing.warehouse.name}</>}
                  </div>
                </div>

                <div className="flex flex-col md:flex-row mt-4">
                  <div className="w-full md:w-3/4 pr-4">
                    <div className="border">
                      <table className="table-auto text-xs w-full">
                        <thead className="bg-gray-100">
                          <tr>
                            <th className="w-2 px-4 py-2">No</th>
                            <th className="text-left px-4 py-2">Nama</th>
                            <th className="w-32 text-left  px-4 py-2">Harga</th>
                            <th className="w-32 text-left  px-4 py-2">
                              Diskon
                            </th>
                            <th className="w-32 text-left  px-4 py-2">
                              Ongkir
                            </th>
                            <th className="w-24 text-left  px-4 py-2">
                              Jumlah
                            </th>
                            <th className="w-32 text-right  px-4 py-2">
                              Subtotal
                            </th>
                          </tr>
                        </thead>

                        <tbody>
                          {purchasing.items.map((item, index) => (
                            <tr className="">
                              <td className="text-center py-1  px-4">
                                {index + 1}
                              </td>
                              <td className="py-1 px-4">{item.product.name}</td>
                              <td className="py-1 px-4">{item.price}</td>
                              <td className="py-1 px-4">{item.qty}</td>
                              <td className="py-1 px-4">{item.discount}</td>
                              <td className="py-1 px-4">
                                {item.shipping_cost}
                              </td>
                              <td className="py-1 px-4 text-right">
                                {item.total}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div className=" my-4 py-2">
                      <table className="table-auto text-sm w-full">
                        <tbody>
                          <tr className="">
                            <td
                              className="text-right px-4 font-bold "
                              colSpan="4"
                            >
                              Total
                            </td>
                            <td className="w-32 text-right px-4 ">
                              <NumericFormat
                                decimalSeparator=","
                                thousandsGroupStyle="thousand"
                                thousandSeparator="."
                                value={purchasing.total}
                                className="font-bold text-right"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className="my-4">
                      <label className="block py-1 text-sm">Keterangan</label>
                      {purchasing.note}
                    </div>
                  </div>
                </div>

                <div className="flex w-3/4 mt-4 justify-end">
                  <ButtonSuccess
                    label="Analisa Harga Jual"
                    onClick={() => setModalAnalisa(true)}
                  />
                </div>

                {modalAnalisa && (
                  <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50 flex items-center justify-center">
                    <div className="bg-white w-3/4 rounded-md shadow-lg">
                      <div className="flex justify-between items-center px-4 py-2 border-b border-gray-200">
                        <div>Analisa Harga Jual</div>
                        <div>
                          Kurs {purchasing.currency.code} -{" "}
                          {defaultCurrency.code} : {purchasing.kurs}
                        </div>
                      </div>
                      <div className="px-4 py-2 flex flex-col">
                        <div className="border">
                          <table className="table-auto text-xs w-full">
                            <thead className="bg-gray-100">
                              <tr>
                                <th className="w-2 px-4 py-2">No</th>
                                <th className=" text-left w-2 px-4 py-2">
                                  SKU
                                </th>
                                <th className="text-left px-4 py-2">Nama</th>
                                <th className="w-32 text-left  px-4 py-2">
                                  Harga ({purchasing.currency.code})
                                </th>
                                <th className="w-32 text-left  px-4 py-2">
                                  Qty
                                </th>
                                <th className="w-32 text-left  px-4 py-2">
                                  Diskon (%)
                                </th>
                                <th className="w-32 text-left  px-4 py-2">
                                  Ongkir
                                </th>

                                <th className="w-24 text-left  px-4 py-2">
                                  Modal/unit
                                </th>
                                <th className="w-48 text-right  px-4 py-2">
                                  Harga Jual
                                </th>
                                <th className="w-32 text-right  px-4 py-2">
                                  Margin (IDR)
                                </th>
                                <th className="w-32 text-right  px-4 py-2">
                                  Margin (%)
                                </th>
                              </tr>
                            </thead>

                            <tbody>
                              {analisaItems.map((item, index) => (
                                <tr className="">
                                  <td className="text-center py-1  px-4">
                                    {index + 1}
                                  </td>
                                  <td className="py-1 px-4">{item.sku}</td>
                                  <td className="py-1 px-4">{item.name}</td>
                                  <td className="py-1 px-4">{item.buy}</td>
                                  <td className="py-1 px-4">{item.qty}</td>
                                  <td className="py-1 px-4">{item.discount}</td>
                                  <td className="py-1 px-4">
                                    {item.shippingCost}
                                  </td>
                                  <td className="py-1 px-4">{item.cost}</td>
                                  <td className="py-1 px-4 text-right">
                                    <InputXs
                                      type="number"
                                      value={item.sell}
                                      onChange={(e) =>
                                        updateSell(item.id, e.target.value)
                                      }
                                    />
                                  </td>
                                  <td className="py-1 px-4 text-right">
                                    {item.profit}
                                  </td>
                                  <td className="py-1 px-4 text-right">
                                    {item.profitPercent}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>

                      <div className="flex gap-2 items-center  justify-end px-4 py-2 border-t border-gray-200">
                        <Button
                          label="Tutup"
                          onClick={() => setModalAnalisa(false)}
                        />
                        <ButtonSuccess
                          label="Update harga"
                          onClick={() => updatePrice()}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}

            <div className="flex-1"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PurchasingAdd;

import React from "react";

import TopHead from "../Layouts/TopHead";
import Sidemenu from "../Layouts/Sidemenu";
import TableGrid from "../../components/TableGrid";
import LoadingBackdrop from "../../components/LoadingBackdrop";
import {
  Input,
  Button,
  ButtonError,
  ButtonSuccess,
  ButtonBack,
} from "../../components/Form";

import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Config from "../../config/Config";
import Api from "../../services/Api";

function SubBrandEdit() {
  const navigate = useNavigate();
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");

  const [brand, setBrand] = useState(null);
  const [brands, setBrands] = useState([]);

  const getBrand = async () => {
    const response = await Api.get(Config.api_url + "/data/brand?limit=1000");
    console.log(response);
    setBrands(response);
  };

  useEffect(() => {
    getBrand();

    setLoading(true);

    // show data by id
    Api.get(Config.api_url + "/data/subbrand/" + id).then((response) => {
      console.log(response);
      setName(response.name);
      setBrand(response.brand_id);
      setLoading(false);
    });
  }, []);

  const submit = () => {
    setLoading(true);

    // create post subbrand
    Api.put(Config.api_url + "/data/subbrand/" + id, {
      name: name,
      brand_id: brand,
    }).then((response) => {
      console.log(response);
      setLoading(false);
    });

    toast("Data berhasil disimpan", {
      type: "success",
    });
  };

  return (
    <div>
      {/* side menu tailwind */}
      <TopHead />
      <ToastContainer />
      <div className="flex flex-col md:flex-row h-screen">
        <Sidemenu />
        <div className="flex-1 overflow-hidden">
          <div className="p-4 pt-16">
            <div className="flex justify-between items-center mb-4">
              <div>
                <h3 className="py-2 font-bold text-rose-700">Edit subbrand</h3>
              </div>
            </div>

            <div className="w-1/4">
              <Input
                label="Nama"
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />

              <label className="block py-2 text-sm">Brand</label>
              <select
                onChange={(e) => setBrand(e.target.value)}
                className="w-full block px-2 py-1 border border-gray-300 text-sm rounded-md focus:outline-none focus:border-rose-500">
                {brands.length > 0 &&
                  brands.map((item) => (
                    <option
                      value={item.id}
                      selected={item.id == brand ? true : false}>
                      {item.name}
                    </option>
                  ))}
              </select>
              <div className="py-2"></div>
              {loading ? (
                <LoadingBackdrop />
              ) : (
                <>
                  <ButtonBack
                    label="Kembali"
                    onClick={() => {
                      navigate("/subbrand");
                    }}
                  />
                  <ButtonSuccess label="Simpan" onClick={submit} />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubBrandEdit;

import React from "react";
import { ReactDOM } from "react";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css";

import { useNavigate } from "react-router-dom";

function TableGrid(props) {
  const navigate = useNavigate();

  const showConfirm = (dataConfirm, method, id) => {
    confirmAlert({
      title: dataConfirm.title,
      message: dataConfirm.message,

      buttons: [
        {
          label: "Yes",
          onClick: () => {
            if (method.type == "url") {
              navigate(method.url + "/" + id);
            } else if (method.type == "function") {
              method.function(id);
            }
          },
        },
        {
          label: "No",
          onClick: () => console.log("Void"),
        },
      ],
    });
  };

  return (
    <div className="flex flex-col">
      <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 inline-block min-w-full sm:px-4 lg:px-8">
          <div className="overflow-hidden">
            <table className="min-w-full border rounded">
              <thead className="border-b bg-gray-100">
                <tr>
                  {props.columns.map((column) => (
                    <>
                      {column.field != "id" && (
                        <th
                          scope="col"
                          className="text-sm font-bold text-gray-900 px-4 py-2 text-left"
                        >
                          {column.label}
                        </th>
                      )}
                    </>
                  ))}

                  <th
                    scope="col"
                    className="text-sm font-bold text-gray-900 px-4 py-2 text-left text-right"
                    colSpan={props.actions.length}
                  >
                    Action
                  </th>
                </tr>
              </thead>

              {props.data.length > 0 ? (
                <tbody>
                  {props.data.map((row) => (
                    <tr className="border-b">
                      {props.columns.map((column) => (
                        <>
                          {column.field != "id" && (
                            <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: column.field.includes(".")
                                    ? row[column.field.split(".")[0]][
                                        column.field.split(".")[1]
                                      ]
                                    : row[column.field],
                                }}
                              ></span>
                            </td>
                          )}
                        </>
                      ))}

                      <td className="px-4 py-2 whitespace-nowrap text-xs font-medium text-gray-900 text-right">
                        {props.actions.map((action) => (
                          <button
                            className="px-3 py-1 border border-gray-200 rounded ml-2"
                            onClick={() => {
                              {
                                action.confirm
                                  ? showConfirm(
                                      action.confirm,
                                      action.method,
                                      row.id
                                    )
                                  : action.method.type == "url"
                                  ? navigate(action.method.url + "/" + row.id)
                                  : action.method.type == "function"
                                  ? action.method.function(row.id)
                                  : console.log("Void");
                              }
                            }}
                          >
                            {action.label}
                          </button>
                        ))}
                      </td>
                    </tr>
                  ))}
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td
                      className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-900 text-center"
                      colSpan={props.columns.length + props.actions.length}
                    >
                      Tidak ada data untuk ditampilkan
                    </td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TableGrid;

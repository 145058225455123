import React from "react";

import TopHead from "../Layouts/TopHead";
import Sidemenu from "../Layouts/Sidemenu";
import TableGrid from "../../components/TableGrid";
import LoadingBackdrop from "../../components/LoadingBackdrop";
import Select from "react-select";
import { NumericFormat } from "react-number-format";

import {
  Input,
  Button,
  ButtonError,
  ButtonSuccess,
  ButtonBack,
  ButtonSm,
  Textarea,
} from "../../components/Form";

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Config from "../../config/Config";
import Api from "../../services/Api";

function JournalAdd() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState("");
  const [note, setNote] = useState("");

  const [coa, setCoa] = useState([]);

  const [item, setItem] = useState([{ coa_id: "", debit: 0, credit: 0 }]);

  const addItem = () => {
    setItem([...item, { coa_id: "", debit: 0, credit: 0 }]);
  };

  const [totalDebit, setTotalDebit] = useState(0);
  const [totalCredit, setTotalCredit] = useState(0);

  useEffect(() => {
    setLoading(true);

    // show data by id
    Api.get(Config.api_url + "/data/coa?limit=100").then((response) => {
      console.log(response);
      setCoa(response);
      setLoading(false);
    });
  }, []);

  const submit = () => {
    // check if total debit and credit is not equal
    if (totalDebit !== totalCredit) {
      toast("Total debit dan credit tidak sama", {
        type: "error",
      });
      return;
    }

    // check if item is empty
    if (item.length === 0) {
      toast("Item tidak boleh kosong", {
        type: "error",
      });
      return;
    }

    // check if item length is < 2
    if (item.length < 2) {
      toast("Item tidak boleh kurang dari 2 akun", {
        type: "error",
      });
      return;
    }

    setLoading(true);

    // create post journal
    Api.post(Config.api_url + "/data/journal", {
      date: date,
      note: note,
      item: item,
      total: totalDebit,
    }).then((response) => {
      console.log(response);
      setLoading(false);
      if (response) {
        toast("Data berhasil disimpan", {
          type: "success",
        });

        setTimeout(() => {
          navigate("/journal");
        }, 1000);
      } else {
        toast("Data gagal disimpan", {
          type: "error",
        });
      }
    });
  };

  const recalculateTotal = () => {
    let totalDebit = 0;
    let totalCredit = 0;

    item.map((i) => {
      totalDebit += i.debit ? parseInt(i.debit) : 0;
      totalCredit += i.credit ? parseInt(i.credit) : 0;
    });

    console.log(totalDebit);
    console.log(totalCredit);

    setTotalDebit(parseInt(totalDebit));
    setTotalCredit(parseInt(totalCredit));
  };

  // update total every item change
  useEffect(() => {
    recalculateTotal();
  }, [item]);

  return (
    <div>
      {/* side menu tailwind */}
      <TopHead />
      <ToastContainer />
      <div className="flex flex-col md:flex-row h-screen">
        <Sidemenu />
        <div className="flex-1 overflow-scroll">
          <div className="p-4 pt-16">
            <div className="flex justify-between items-center mb-4">
              <div>
                <h3 className="py-2 font-bold text-rose-700">Tambah jurnal</h3>
              </div>
            </div>

            <div className="w-full">
              <div className="flex flex-col md:flex-row mb-8">
                <div className="w-full md:w-1/4 pr-4">
                  <Input
                    label="Tanggal"
                    type="date"
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                  />
                </div>
                <div className="w-full md:w-3/4 pr-4">
                  <Input
                    label="Keterangan"
                    placeholder="Keterangan / referensi / no bukti"
                    type="text"
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                  />
                </div>
              </div>

              <p className="my-2 text-sm">Ayat jurnal</p>

              <table class="border w-full border-gray-400 text-sm">
                <thead>
                  <tr className="bg-gray-50">
                    <th class="border border-gray-400 py-2 px-6 w-1/2">Akun</th>
                    <th class="border border-gray-400 py-2 px-6">Debit</th>
                    <th class="border border-gray-400 py-2 px-6">Credit</th>
                    <th class="border border-gray-400 py-2 px-6"></th>
                  </tr>
                </thead>
                <tbody>
                  {item.map((i, index) => (
                    <tr key={index}>
                      <td className="border-x border-gray-400 py-2 px-6 ">
                        <Select
                          options={coa}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          onChange={(e) => {
                            let new_item = [...item];
                            new_item[index].coa_id = e.id;
                            setItem(new_item);
                          }}
                          value={coa.find((obj) => obj.id === i.coa_id)}
                          styles={{
                            control: (base) => ({
                              ...base,
                              height: "36px",
                              minHeight: "36px",
                              // marginTop: "15px",
                              padding: "0 10px",
                              fontSize: "12px",
                              alignItems: "center",
                              borderRadius: "5px",
                            }),
                          }}
                        />
                      </td>
                      <td className="border-x border-gray-400 py-2 px-6">
                        <input
                          className="border-b border-gray-400 py-1 px-4 w-full text-right"
                          type="number"
                          value={i.debit}
                          onChange={(e) => {
                            let new_item = [...item];
                            new_item[index].debit = e.target.value;
                            setItem(new_item);
                          }}
                        />
                      </td>
                      <td className="border-x border-gray-400 py-2 px-6">
                        <input
                          className="border-b border-gray-400 py-1 px-4 w-full text-right"
                          type="number"
                          value={i.credit}
                          onChange={(e) => {
                            let new_item = [...item];
                            new_item[index].credit = e.target.value;
                            setItem(new_item);
                          }}
                        />
                      </td>
                      <td className="border-x border-gray-400 py-2 px-6">
                        <ButtonSm
                          label="Hapus"
                          onClick={() => {
                            console.log("delete item of index " + index + "  ");
                            let new_item = [...item];
                            new_item.splice(index, 1);
                            setItem(new_item);
                          }}
                        />
                      </td>
                    </tr>
                  ))}

                  <tr>
                    <td className="border-x border-gray-400 py-2 px-6">
                      <Button
                        label="Tambah baris"
                        onClick={() => {
                          addItem();
                        }}
                      />
                    </td>
                    <td className="border-x border-gray-400 py-2 px-6"></td>
                    <td className="border-x border-gray-400 py-2 px-6"></td>
                    <td className="border-x border-gray-400 py-2 px-6"></td>
                  </tr>
                </tbody>
              </table>

              <div className="py-2 border-t"></div>

              <table className="table-auto w-full">
                <thead>
                  <tr>
                    <th className="text-left px-2 w-1/2">Total</th>
                    <th className="text-left px-2 w-1/5 text-center">
                      <NumericFormat
                        value={totalDebit}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                    </th>
                    <th className="text-left px-2  w-1/5 text-center">
                      <NumericFormat
                        value={totalCredit}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                    </th>
                    <th className="text-left px-2"></th>
                  </tr>
                </thead>
              </table>

              <div className="py-2"></div>
              {loading ? (
                <LoadingBackdrop />
              ) : (
                <>
                  <ButtonBack
                    label="Kembali"
                    onClick={() => {
                      navigate("/journal");
                    }}
                  />
                  <ButtonSuccess label="Simpan" onClick={submit} />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default JournalAdd;

import React from "react";
import TopHead from "../Layouts/TopHead";
import Sidemenu from "../Layouts/Sidemenu";
import TableGrid from "../../components/TableGrid";
import Select from "react-select";

import { NumericFormat } from "react-number-format";

import {
  Input,
  InputXs,
  Button,
  ButtonSm,
  ButtonSmGray,
  ButtonSuccess,
  ButtonSuccessFull,
  ButtonBackFull,
} from "../../components/Form";

import Modal from "../../components/Modal";

import { useState, useEffect } from "react";

import Config from "../../config/Config";
import Api from "../../services/Api";

import { useNavigate } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function POS() {
  const navigate = useNavigate();

  const columns = [
    { label: "ID", field: "id" },
    { label: "Name", field: "name" },
  ];

  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);

  const [products, setProducts] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(100);
  const [filter, setFilter] = useState("");
  const [lastRefresh, setLastRefresh] = useState(new Date());

  const [warehouses, setWarehouses] = useState([]);
  const [warehouse, setWarehouse] = useState("");

  const [discounts, setDiscounts] = useState([]);
  const [taxes, setTaxes] = useState([]);

  const [total, setTotal] = useState(0);

  const [modalCheckout, setModalCheckout] = useState(false);
  const [modalCustomer, setModalCustomer] = useState(false);
  const [modalAddCustomer, setModalAddCustomer] = useState(false);
  const [modalDiscount, setModalDiscount] = useState(false);
  const [modalTax, setModalTax] = useState(false);
  const [modalCheckoutSuccess, setModalCheckoutSuccess] = useState(false);

  const [customer, setCustomer] = useState({
    id: "",
    name: "",
    phone: "",
    email: "",
    group: { id: "", name: "" },
  });
  const [customers, setCustomers] = useState([]);
  const [customerFilter, setCustomerFilter] = useState("");
  const [customerPage, setCustomerPage] = useState(1);
  const [customerLimit, setCustomerLimit] = useState(100);

  const [customerGroups, setCustomerGroups] = useState([]);

  const [customerObject, setCustomerObject] = useState({});

  // payment method grup
  const [transferTotal, setTransferTotal] = useState(0);
  const [transferDate, setTransferDate] = useState("");
  const [transferName, setTransferName] = useState("");
  const [transferBank, setTransferBank] = useState("");

  const [cardTotal, setCardTotal] = useState(0);
  const [cardType, setCardType] = useState("");
  const [cardName, setCardName] = useState("");
  const [cardApproval, setCardApproval] = useState("");
  const [cardEdc, setCardEdc] = useState("");

  const [ecommerceTotal, setEcommerceTotal] = useState(0);
  const [ecommerceType, setEcommerceType] = useState("");
  const [ecommerceName, setEcommerceName] = useState("");
  const [ecommerceInvoice, setEcommerceInvoice] = useState("");

  const getCustomerGroup = async () => {
    const response = await Api.get(Config.api_url + "/data/customergroup");
    console.log(response);
    setCustomerGroups(response);
  };

  const [customerAdd, setCustomerAdd] = useState({
    name: "",
    phone: "",
    email: "",
    customer_group_id: "",
  });

  const [subtotal, setSubtotal] = useState(0);
  const [discount, setDiscount] = useState("");
  const [discountAmount, setDiscountAmount] = useState(0);
  const [discountPercentage, setDiscountPercentage] = useState(0);

  const [tax, setTax] = useState("");
  const [taxAmount, setTaxAmount] = useState(0);
  const [taxPercentage, setTaxPercentage] = useState(0);

  const [paymentMethod, setPaymentMethod] = useState("cash");
  const [paymentAmount, setPaymentAmount] = useState(0);
  const [paymentReference, setPaymentReference] = useState("");
  const [note, setNote] = useState("");

  const [checkout, setCheckout] = useState({
    customer_id: "",
    warehouse_id: "",
    items: [],
    payment_method: "cash", // cash, transfer, debit, credit, ecommerce
    payment_reference: "",
  });

  const [orderSuccess, setOrderSuccess] = useState({});

  const getDiscount = async () => {
    const response = await Api.get(Config.api_url + "/data/discount");
    console.log(response);
    setDiscounts(response);
  };

  const getTax = async () => {
    const response = await Api.get(Config.api_url + "/data/tax");
    console.log(response);
    setTaxes(response);
  };

  const getWarehouse = async () => {
    setLoading(true);

    const response = await Api.get(
      Config.api_url + "/data/warehouse?page=1&limit=100"
    );

    console.log(response);
    setWarehouses(response);
  };

  useEffect(() => {
    getWarehouse();
    getCustomerGroup();
    getDiscount();
    getTax();
  }, []);

  useEffect(() => {
    setLoading(true);

    // check filter all number
    if (filter.match(/^[0-9]+$/)) {
      return;
    }

    // get data product
    Api.get(
      Config.api_url +
        "/data/product?limit=" +
        limit +
        "&page=" +
        page +
        "&filter=" +
        filter
    )
      .then((response) => {
        //setData(response);

        // set data
        let showData = [];
        response.map((item) => {
          showData.push({
            id: item.id,
            name: item.name,
            image: item.image,
            category: item.category,
            brand: item.brand,
            subbrand: item.subbrand,
            price: item.price,
            discounts: item.discounts,
          });
        });

        setProducts(showData);

        console.log("Data", response);
      })
      .catch((error) => {
        console.log(error);
      });

    setLoading(false);
  }, [page, limit, filter, lastRefresh]);

  // get customer data
  useEffect(() => {
    setLoading(true);

    // get data customer
    Api.get(
      Config.api_url +
        "/data/pos/customer?limit=" +
        customerLimit +
        "&page=" +
        customerPage +
        "&filter=" +
        customerFilter
    )
      .then((response) => {
        setCustomers(response);
        console.log("Data", response);
      })
      .catch((error) => {
        console.log(error);
      });

    setLoading(false);
  }, [customerPage, customerLimit, customerFilter, lastRefresh]);

  const chooseCustomer = (item) => {
    setCustomer({
      id: item.id,
      name: item.name,
      phone: item.phone,
      customer_group_id: item.customer_group_id,
      group: item.group,
    });

    setModalCustomer(false);
    checkDiscount(items, item.customer_group_id);
  };

  const checkDiscount = (discountItems, customer_group_id) => {
    Api.post(Config.api_url + "/data/pos/checkdiscount", {
      items: discountItems,
      customer_group_id: customer_group_id,
    })
      .then((response) => {
        console.log(response);
        setItems(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const addCustomer = () => {
    setLoading(true);

    Api.post(Config.api_url + "/data/customer", customerAdd)
      .then((response) => {
        console.log(response);
        toast.success("Pelanggan baru ditambahkan");
        setModalAddCustomer(false);
        setCustomer({
          id: response.id,
          name: response.name,
          phone: response.phone,
          customer_group_id: response.customer_group_id,
        });

        checkDiscount(items, response.customer_group_id);
        setLastRefresh(new Date());
      })
      .catch((error) => {
        console.log(error);
        toast.error("Failed to add customer");
      });
  };

  const deleteData = (id) => {
    toast("Delete " + id, {
      type: "error",
    });
  };

  const enterScan = async () => {
    if (filter.match(/^[0-9]+$/)) {
      const response = await Api.get(
        Config.api_url + "/data/product/getbysku/" + filter
      );
      console.log(response);

      if (response) {
        addItems(response);
      } else {
        toast("Produk tidak ditemukan", {
          type: "error",
        });
      }

      setFilter("");
    }
    // get by SKU
  };

  const addItems = (product) => {
    let additem = {
      id: product.id,
      name: product.name,
      image: product.image,
      price: product.price,
      discount: 0,
      qty: 1,
      discounts: product.discounts,
    };

    // check if item already exist
    let itemExist = items.find((item) => item.id === product.id);

    if (itemExist) {
      // update qty
      let newItems = [];
      items.map((item) => {
        if (item.id === product.id) {
          item.qty = parseInt(item.qty) + 1;
        }

        newItems.push(item);
      });

      console.log("newItems", newItems);
      setItems(newItems);
    } else {
      // add new item
      setItems([...items, additem]);
    }
  };

  const deleteItem = (id) => {
    let newItems = [];

    items.map((item) => {
      if (item.id !== id) {
        newItems.push(item);
      }
    });

    setItems(newItems);
  };

  // recalculating total

  const recalculateTotal = () => {
    let total = 0;
    let subtotal = 0;
    console.log("customer object 2: ", customerObject.customer_group_id);
    items.forEach((item) => {
      item.finalPrice = item.price;

      // check if discount exist
      if (item.discounts !== null) {
        item.discounts.map((discount) => {
          if (discount.customer_group_id === customerObject.customer_group_id) {
            console.log("discount found");

            let discountAmount = item.finalPrice * (discount.discount / 100);
            item.finalPrice = item.finalPrice - discountAmount;
            item.discountPercentage = discount.discount;

            let discountAmountPlus = 0;

            item.discountPercentagePlus = 0;
            if (discount.discount_plus > 0) {
              discountAmountPlus =
                item.finalPrice * (discount.discount_plus / 100);

              item.discountPercentagePlus = discount.discount_plus;
            }

            console.log("discountAmount: " + discountAmount);

            item.discount = discountAmount + discountAmountPlus;
          }
        });
      }

      item.subtotal = item.finalPrice * item.qty;

      subtotal += item.subtotal;
      total += item.subtotal;
    });

    if (discount != "") {
      setDiscountAmount(total * (discountPercentage / 100));
      total = total - total * (discountPercentage / 100);
    }

    if (tax != "") {
      setTaxAmount(total * (taxPercentage / 100));
      total = total + total * (taxPercentage / 100);
    }

    setItems(items);
    setSubtotal(subtotal);
    setTotal(total);

    setPaymentAmount(total);
  };

  const calculateTotal = () => {
    setTotal(0);

    let thisTotal = 0;
    items.map((item) => {
      item.finalPrice = item.price;
      item.discountPercentage = 0;
      item.discountPercentagePlus = 0;
      item.subtotalDiscount = 0;

      // check if discount exist
      if (item.discounts !== null) {
        item.discounts.map((discount) => {
          let subtotalDiscount = 0;
          if (discount.customer_group_id === customer.customer_group_id) {
            console.log("discount found");

            let discountAmount = item.finalPrice * (discount.discount / 100);
            item.finalPrice = item.finalPrice - discountAmount;
            item.discountPercentage = discount.discount;
            item.discountAmount = discountAmount;
            item.subtotalDiscount = discountAmount;

            let discountAmountPlus = 0;

            item.discountPercentagePlus = 0;
            if (discount.discount_plus > 0) {
              discountAmountPlus =
                item.finalPrice * (discount.discount_plus / 100);

              item.discountPercentagePlus = discount.discount_plus;
              item.discountAmountPlus = discountAmountPlus;
              item.subtotalDiscount += discountAmountPlus;
            }

            console.log("discountAmount: " + discountAmount);

            item.discount = discountAmount + discountAmountPlus;
          }
        });
      }

      item.subtotal = item.finalPrice * item.qty;

      thisTotal += item.subtotal;
    });

    setSubtotal(thisTotal);

    // find discount
    if (discount !== "") {
      let discountObj = discounts.find((item) => item.id === discount);
      let discountAmount = (thisTotal * discountObj.discount_percentage) / 100;
      setDiscountAmount(discountAmount);
      setDiscountPercentage(discountObj.discount_percentage);
      thisTotal = thisTotal - discountAmount;

      console.log("discount", discountAmount);
    }

    if (tax !== "") {
      let taxObj = taxes.find((item) => item.id === tax);
      let taxAmount = (thisTotal * taxObj.tax_percentage) / 100;
      setTaxAmount(taxAmount);
      setTaxPercentage(taxObj.tax_percentage);
      thisTotal = thisTotal + taxAmount;

      console.log("tax", taxAmount);
    }

    setTotal(thisTotal);

    console.log("total", total);
  };

  useEffect(() => {
    calculateTotal();
    // recalculateTotal();
  }, [items, discount, tax]);

  const actions = [
    {
      label: "Edit",
      method: {
        type: "url",
        url: "/delete",
      },
    },
    {
      label: "Delete",
      method: {
        type: "function",
        function: deleteData,
      },
      confirm: {
        title: "Konfirmasi hapus?",
        message: "Yakin menghapus data ini",
      },
    },
  ];

  const submit = () => {
    // check if customer is empty

    if (customer.id === "") {
      toast("Pelanggan harus diisi", {
        type: "error",
      });
      return;
    }

    setLoading(true);

    // set checkout
    let checkout = {
      customer_id: customer.id,
      warehouse_id: warehouse,
      subtotal: subtotal,
      tax: tax,
      tax_percentage: taxPercentage,
      tax_amount: taxAmount,
      discount: discount,
      discount_percentage: discountPercentage,
      discount_amount: discountAmount,
      payment_method: paymentMethod,
      payment_amount: paymentAmount,
      payment_reference: paymentReference,

      transfer_total: paymentAmount,
      transfer_bank: transferBank,
      transfer_date: transferDate,
      transfer_name: transferName,

      card_total: paymentAmount,
      card_type: cardType,
      card_name: cardName,
      card_approval: cardApproval,

      ecommerce_total: paymentAmount,
      ecommerce_type: ecommerceType,
      ecommerce_name: ecommerceName,
      ecommerce_invoice: ecommerceInvoice,

      note: note,
      items: items,
      total: total,
    };

    console.log("checkout", checkout);

    // post checkout
    Api.post(Config.api_url + "/data/pos/checkout", checkout)
      .then((response) => {
        if (response) {
          if (response.status == "error") {
            toast.error(response.message);
            setLoading(false);
            return;
          }

          console.log(response);
          toast.success("Checkout berhasil");
          setItems([]);
          setCustomer({
            id: "",
            name: "",
            phone: "",
          });
          setDiscount("");
          setDiscountAmount(0);
          setTax("");
          setTaxAmount(0);
          setSubtotal(0);
          setTotal(0);
          setModalCheckout(false);
          setModalCheckoutSuccess(true);

          setOrderSuccess(response);
        } else {
          toast.error("Checkout gagal");
        }

        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Checkout gagal");
        setLoading(false);
      });
  };

  return (
    <div>
      {/* side menu tailwind */}
      <TopHead title="POS" />
      <ToastContainer />
      <div className="flex flex-col md:flex-row h-screen">
        {/* <Sidemenu /> */}
        <div className="flex-1 overflow-hidden">
          <div className="pt-1 flex-1">
            <div className="flex">
              <div className="flex-1 ">
                <div className="pt-8 w-full">
                  <div className="py-4 px-2 bg-gray-50">
                    <div className="flex gap-2">
                      <div className="w-32">
                        <ButtonBackFull
                          type="primary"
                          label="Dashboard"
                          onClick={() => {
                            navigate("/dashboard");
                          }}
                        />
                      </div>
                      <div className="flex-1">
                        <Input
                          type="text"
                          placeholder="Cari atau scan SKU"
                          value={filter}
                          onChange={(e) => {
                            //check if all is number

                            setFilter(e.target.value);
                          }}
                          onKeyUp={(e) => {
                            if (e.key === "Enter") {
                              // do something
                              enterScan();
                              console.log("enter");
                            }
                          }}
                        />
                      </div>
                      <div className="w-56 pt-4">
                        <select
                          onChange={(e) => setWarehouse(e.target.value)}
                          className="w-full block px-2 py-1 border border-gray-300 text-sm rounded-md focus:outline-none focus:border-rose-500">
                          <option value="">- Pilih gudang -</option>
                          {warehouses.length > 0 &&
                            warehouses.map((item) => (
                              <option value={item.id}>{item.name}</option>
                            ))}
                        </select>
                      </div>

                      <div className="w-48">
                        <ButtonSmGray
                          type="primary"
                          label="Pilih Pelanggan"
                          onClick={() => {
                            setModalCustomer(true);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="h-screen overflow-auto pb-32">
                  <div class="grid lg:grid-cols-5  md:grid-cols-4 gap-2 px-2">
                    {products.map((item, index) => (
                      <div
                        className="h-52 border border-dark-800 cursor-pointer rounded-md"
                        onClick={() => {
                          addItems(item);
                        }}>
                        <div className="h-32">
                          <img
                            src={Config.api_url + "/" + item.image}
                            className="w-full h-full object-cover"
                          />
                        </div>
                        <div className="h-12 text-xs text-rose-900  px-4 text-center pt-2">
                          {item.name}
                        </div>
                        <div className="h-4 text-xs font-bold text-center">
                          {/* {item.price} */}
                          <NumericFormat
                            value={item.price}
                            displayType={"text"}
                            thousandSeparator={true}
                            decimalSeparator={"."}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              <div className="w-80 bg-gray-100 ">
                <div className="">
                  <div className="flex flex-col h-screen">
                    <div className="h-28 bg-gray-600 text-white pt-16 text-lg text-center">
                      {customer.name
                        ? customer.name + " (" + customer.group.name + ")"
                        : "-"}
                    </div>

                    <div className="grow h-96 overflow-auto pt-4">
                      {items.map((item, index) => (
                        <div className="flex flex-1 gap-2 border-b border-gray-300">
                          <div className="ml-2 mb-2 w-16 h-16 rounded-lg overflow-hidden">
                            <img
                              src={Config.api_url + "/" + item.image}
                              className="w-16 h-16"
                            />
                          </div>
                          <div className="flex-1 grow text-xs py-2">
                            <div className="font-bold mb-1">{item.name}</div>
                            {item.discount > 0 ? (
                              <>
                                <div className="text-red-800 line-through">
                                  {item.price}
                                </div>
                                <div className="">
                                  {item.subtotalDiscount}
                                  {item.discountPercentage > 0 && (
                                    <span className="text-red-800">
                                      {" "}
                                      ({item.discountPercentage}%)
                                      {item.discountPercentagePlus > 0 && (
                                        <span className="text-red-800">
                                          {" "}
                                          + {item.discountPercentagePlus}%
                                        </span>
                                      )}
                                    </span>
                                  )}
                                </div>
                              </>
                            ) : (
                              <div className="">{item.price}</div>
                            )}
                          </div>
                          <div className="w-16 h-12 pr-4 text-center">
                            <InputXs
                              type="number"
                              value={item.qty}
                              onChange={(e) => {
                                let newItems = [];
                                items.map((items) => {
                                  if (items.id === item.id) {
                                    items.qty = e.target.value;
                                  }

                                  newItems.push(items);
                                });

                                console.log("newItems", newItems);

                                setItems(newItems);
                              }}
                            />
                            <p
                              class="text-xs mt-1 text-red-700 cursor-pointer"
                              onClick={() => {
                                deleteItem(item.id);
                              }}>
                              Hapus
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="h-52 bg-gray-300 px-4 py-4">
                      {/* <ButtonSmGray type="primary" label="Pilih Pelanggan" /> */}

                      <div className="flex justify-between py-2 border-b border-gray-400">
                        <div className="w-1/2 text-sm">Diskon</div>
                        <div className="w-1/2 text-right text-sm">
                          <p
                            class="text-teal-800 font-bold cursor-pointer hover:text-teal-600"
                            onClick={() => {
                              if (items.length > 0) {
                                setModalDiscount(true);
                              } else {
                                // set toast error
                                toast.error("Tidak ada item yang dipilih");
                              }
                            }}>
                            {discountAmount > 0
                              ? discountAmount +
                                " (" +
                                discountPercentage +
                                "%)"
                              : "Tambahkan diskon"}
                          </p>
                        </div>
                      </div>
                      <div className="flex justify-between py-2 border-b border-gray-400">
                        <div className="w-1/2 text-sm">Pajak</div>
                        <div className="w-1/2 text-right text-sm">
                          <p
                            class="text-teal-800 font-bold cursor-pointer hover:text-teal-600"
                            onClick={() => {
                              if (items.length > 0) {
                                setModalTax(true);
                              } else {
                                // set toast error
                                toast.error("Tidak ada item yang dipilih");
                              }
                            }}>
                            {taxAmount > 0
                              ? taxAmount + " (" + taxPercentage + "%)"
                              : "Tambahkan pajak"}
                          </p>
                        </div>
                      </div>
                      <div className="flex py-2 justify-between">
                        <div className="w-1/2">Total</div>
                        <div className="w-1/2 text-right text-xl">
                          <NumericFormat
                            value={total}
                            displayType={"text"}
                            thousandSeparator={true}
                            decimalSeparator={"."}
                            decimalScale={2}
                          />
                        </div>
                      </div>

                      <ButtonSuccessFull
                        type="primary"
                        label="Checkout"
                        onClick={() => {
                          if (items.length > 0) {
                            if (warehouse === "") {
                              toast.error("Pilih gudang terlebih dahulu");
                            } else {
                              setModalCheckout(true);
                            }
                          } else {
                            toast.error("Belum ada item barang di keranjang");
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/** modal customer list */}
          {modalCustomer && (
            <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50 flex items-center justify-center">
              <div className="bg-white md:w-1/3 w-1/2 rounded-md shadow-lg">
                <div className="flex justify-between items-center px-4 py-2 border-b border-gray-200 gap-2">
                  <div className="h-12 grow">
                    <Input
                      type="text"
                      placeholder="Cari pelanggan"
                      value={customerFilter}
                      onChange={(e) => {
                        setCustomerFilter(e.target.value);
                      }}
                    />
                  </div>
                  <div className="grow pt-1">
                    <ButtonSmGray
                      type="primary"
                      label="Tambah"
                      onClick={() => {
                        setModalCustomer(false);
                        setModalAddCustomer(true);
                      }}
                    />
                  </div>
                </div>

                <div className="h-96 overflow-auto py-2">
                  {customers.length > 0 &&
                    customers.map((item) => (
                      <div
                        className="px-4 py-2 flex text-xs hover:bg-gray-100 cursor-pointer"
                        onClick={() => {
                          chooseCustomer(item);
                          setCustomerObject(item);
                        }}>
                        <div className="grow pr-4">
                          <p className="font-bold">{item.name} </p>
                          {item.phone}
                        </div>
                        <div className="w-8 pr-4 items-center align-middle py-2">
                          &rarr;
                        </div>
                      </div>
                    ))}
                </div>

                <div className="px-4 py-2 flex">
                  <div className="w-full pr-4"></div>
                </div>
                <div className="flex gap-2 items-center  justify-end px-4 py-2 border-t border-gray-200">
                  <Button
                    label="Tutup"
                    onClick={() => setModalCustomer(false)}
                  />
                </div>
              </div>
            </div>
          )}

          {/** modal customer list */}
          {modalAddCustomer && (
            <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50 flex items-center justify-center">
              <div className="bg-white md:w-1/3 w-1/2 rounded-md shadow-lg">
                <div className="flex justify-between items-center px-4 py-2 text-sm border-b border-gray-200 gap-2">
                  Tambah pelanggan baru
                </div>

                <div className="h-96 overflow-auto py-2">
                  <div className="px-4">
                    <Input
                      label="Nama"
                      type="text"
                      value={customerAdd.name}
                      onChange={(e) =>
                        setCustomerAdd({ ...customerAdd, name: e.target.value })
                      }
                    />
                    <Input
                      label="Telepon"
                      type="tel"
                      value={customerAdd.phone}
                      onChange={(e) =>
                        setCustomerAdd({
                          ...customerAdd,
                          phone: e.target.value,
                        })
                      }
                    />
                    <Input
                      label="Email"
                      type="text"
                      value={customerAdd.email}
                      onChange={(e) =>
                        setCustomerAdd({
                          ...customerAdd,
                          email: e.target.value,
                        })
                      }
                    />
                    <label className="block py-2 text-sm">Grup</label>
                    <select
                      onChange={(e) =>
                        setCustomerAdd({
                          ...customerAdd,
                          customer_group_id: e.target.value,
                        })
                      }
                      className="w-full block px-2 py-1 border border-gray-300 text-sm rounded-md focus:outline-none focus:border-rose-500">
                      <option value="">- Pilih Grup pelanggan -</option>
                      {customerGroups.length > 0 &&
                        customerGroups.map((item) => (
                          <option value={item.id}>{item.name}</option>
                        ))}
                    </select>
                  </div>
                </div>

                <div className="px-4 py-2 flex">
                  <div className="w-full pr-4"></div>
                </div>
                <div className="flex gap-2 items-center  justify-end px-4 py-2 border-t border-gray-200">
                  <ButtonSuccess label="Simpan" onClick={() => addCustomer()} />
                </div>
              </div>
            </div>
          )}

          {/** modal discount */}
          {modalDiscount && (
            <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50 flex items-center justify-center">
              <div className="bg-white md:w-64 w-64 rounded-md shadow-lg">
                <div className="flex justify-between items-center px-4 py-2 border-b border-gray-200 gap-2">
                  <div className="h-6 grow">Diskon</div>
                </div>

                <div className="h-24 overflow-scroll z-100 py-2 px-4">
                  <div className="flex justify-between items-center">
                    <div className="grow">
                      <select
                        className="w-full block px-2 py-1 border border-gray-300 text-sm rounded-md focus:outline-none focus:border-rose-500"
                        onChange={(e) => {
                          setDiscount(e.target.value);
                        }}>
                        <option value="">- Pilih Diskon -</option>
                        {discounts.map((item) => (
                          <option value={item.id}>
                            {item.name} ({item.discount_percentage}%)
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>

                <div className="px-4 py-2 flex">
                  <div className="w-full pr-4"></div>
                </div>
                <div className="flex gap-2 items-center  justify-end px-4 py-2 border-t border-gray-200">
                  <Button
                    label="Tutup"
                    onClick={() => setModalDiscount(false)}
                  />
                  <ButtonSuccess
                    label="Simpan"
                    onClick={() => {
                      setModalDiscount(false);
                      setDiscount(discount);
                    }}
                  />
                </div>
              </div>
            </div>
          )}

          {/** modal tax */}
          {modalTax && (
            <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50 flex items-center justify-center">
              <div className="bg-white md:w-64 w-64 rounded-md shadow-lg">
                <div className="flex justify-between items-center px-4 py-2 border-b border-gray-200 gap-2">
                  <div className="h-6 grow">Pajak</div>
                </div>

                <div className="h-16 overflow-auto py-2 px-4">
                  <div className="flex justify-between items-center">
                    <div className="grow">
                      <select
                        className="w-full block px-2 py-1 border border-gray-300 text-sm rounded-md focus:outline-none focus:border-rose-500"
                        onChange={(e) => setTax(e.target.value)}>
                        <option value="">- Pilih Pajak -</option>
                        {taxes.map((item) => (
                          <option value={item.id}>
                            {item.name} ({item.tax_percentage}%)
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>

                <div className="px-4 py-2 flex">
                  <div className="w-full pr-4"></div>
                </div>
                <div className="flex gap-2 items-center  justify-end px-4 py-2 border-t border-gray-200">
                  <Button label="Tutup" onClick={() => setModalTax(false)} />
                  <ButtonSuccess
                    label="Simpan"
                    onClick={() => {
                      setModalTax(false);
                      setTax(tax);
                    }}
                  />
                </div>
              </div>
            </div>
          )}

          {/** modal checkout */}
          {modalCheckout && (
            <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50 flex items-center justify-center">
              <div className="bg-white w-1/2 rounded-md shadow-lg">
                <div className="flex justify-between items-center px-4 py-2 border-b border-gray-200">
                  Bayar Pesanan
                </div>
                <div className="flex justify-start items-center px-4 py-2 gap-2">
                  {paymentMethod == "cash" ? (
                    <ButtonSuccess label="Tunai" />
                  ) : (
                    <Button
                      label="Tunai"
                      onClick={() => {
                        setPaymentMethod("cash");
                      }}
                    />
                  )}

                  {paymentMethod == "transfer" ? (
                    <ButtonSuccess label="Transfer" />
                  ) : (
                    <Button
                      label="Transfer"
                      onClick={() => {
                        setPaymentMethod("transfer");
                      }}
                    />
                  )}

                  {paymentMethod == "card" ? (
                    <ButtonSuccess label="Kartu" />
                  ) : (
                    <Button
                      label="Kartu"
                      onClick={() => {
                        setPaymentMethod("card");
                      }}
                    />
                  )}

                  {paymentMethod == "ecommerce" ? (
                    <ButtonSuccess label="ECommerce" />
                  ) : (
                    <Button
                      label="Ecommerce"
                      onClick={() => {
                        setPaymentMethod("ecommerce");
                      }}
                    />
                  )}
                </div>
                <div>
                  {paymentMethod == "cash" && (
                    <div className="px-4 py-2 flex h-48">
                      <div className="w-1/2 pr-4">
                        <Input
                          label="Jumlah pembayaran"
                          type="text"
                          value={paymentAmount}
                          onChange={(e) => setPaymentAmount(e.target.value)}
                        />
                      </div>
                      <div className="w-1/2 pr-4">
                        <Input
                          label="Referensi pembayaran"
                          type="text"
                          value={paymentReference}
                          onChange={(e) => setPaymentReference(e.target.value)}
                        />
                      </div>
                    </div>
                  )}
                  {paymentMethod == "transfer" && (
                    <>
                      <div className="px-4 py-2 flex">
                        <div className="w-1/2 pr-4">
                          <Input
                            label="Jumlah pembayaran"
                            type="text"
                            value={paymentAmount}
                            onChange={(e) => setPaymentAmount(e.target.value)}
                          />
                        </div>
                        <div className="w-1/2 pr-4">
                          <Input
                            label="Tanggal transfer"
                            type="date"
                            value={transferDate}
                            onChange={(e) => setTransferDate(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="px-4 py-2 flex h-48">
                        <div className="w-1/2 pr-4">
                          <Input
                            label="Atas nama"
                            type="text"
                            value={transferName}
                            onChange={(e) => setTransferName(e.target.value)}
                          />
                        </div>
                        <div className="w-1/2 pr-4">
                          <Input
                            label="Transfer Bank"
                            type="text"
                            value={transferBank}
                            onChange={(e) => setTransferBank(e.target.value)}
                          />
                        </div>
                      </div>
                    </>
                  )}
                  {paymentMethod == "card" && (
                    <>
                      <div className="px-4 py-2 flex">
                        <div className="w-1/2 pr-4">
                          <Input
                            label="Jumlah pembayaran"
                            type="text"
                            value={paymentAmount}
                            onChange={(e) => setPaymentAmount(e.target.value)}
                          />
                        </div>
                        <div className="w-1/2 pr-4">
                          <Input
                            label="Tipe"
                            type="text"
                            value={cardType}
                            onChange={(e) => setCardType(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="px-4 py-2 flex h-48">
                        <div className="w-1/2 pr-4">
                          <Input
                            label="Nama"
                            type="text"
                            value={cardName}
                            onChange={(e) => setCardName(e.target.value)}
                          />
                        </div>
                        <div className="w-1/2 pr-4">
                          <Input
                            label="Approval"
                            type="text"
                            value={cardApproval}
                            onChange={(e) => setCardApproval(e.target.value)}
                          />
                        </div>
                      </div>
                    </>
                  )}
                  {paymentMethod == "ecommerce" && (
                    <>
                      <div className="px-4 py-2 flex">
                        <div className="w-1/2 pr-4">
                          <Input
                            label="Jumlah pembayaran"
                            type="text"
                            value={paymentAmount}
                            onChange={(e) => setPaymentAmount(e.target.value)}
                          />
                        </div>
                        <div className="w-1/2 pr-4">
                          <Input
                            label="Tipe"
                            type="text"
                            value={ecommerceType}
                            onChange={(e) => setEcommerceType(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="px-4 py-2 flex h-48">
                        <div className="w-1/2 pr-4">
                          <Input
                            label="Nama"
                            type="text"
                            value={ecommerceName}
                            onChange={(e) => setEcommerceName(e.target.value)}
                          />
                        </div>
                        <div className="w-1/2 pr-4">
                          <Input
                            label="Invoice"
                            type="text"
                            value={ecommerceInvoice}
                            onChange={(e) =>
                              setEcommerceInvoice(e.target.value)
                            }
                          />
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div className="px-4 py-2 flex">
                  <div className="w-full pr-4"></div>
                </div>
                <div className="flex gap-2 items-center  justify-end px-4 py-2 border-t border-gray-200">
                  <Button
                    label="Tutup"
                    onClick={() => setModalCheckout(false)}
                  />
                  <ButtonSuccess
                    label="Simpan pembayaran"
                    onClick={() => {
                      submit();
                      setModalCheckout(false);
                    }}
                  />
                </div>
              </div>
            </div>
          )}

          {modalCheckoutSuccess && (
            <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50 flex items-center justify-center">
              <div className="bg-white w-96 rounded-md shadow-lg">
                <div className="flex justify-between items-center px-4 py-2 border-b border-gray-200">
                  Transaksi berhasil
                </div>
                <div className="flex justify-start items-center px-4 py-2 gap-2"></div>
                <div className="px-4 py-2 text-sm">
                  <div className="flex w-full">
                    <div className="w-1/2 pr-4">No Pesanan</div>
                    <div className="w-1/2 pr-4">{orderSuccess.number}</div>
                  </div>
                  <div className="flex w-full">
                    <div className="w-1/2 pr-4">Pelanggan</div>
                    <div className="w-1/2 pr-4">{orderSuccess.total}</div>
                  </div>
                  <div className="flex w-full">
                    <div className="w-1/2 pr-4">Gudang/Toko</div>
                    <div className="w-1/2 pr-4">
                      {orderSuccess.warehouse.name}
                    </div>
                  </div>
                  <div className="flex w-full">
                    <div className="w-1/2 pr-4">Pelanggan</div>
                    <div className="w-1/2 pr-4">
                      {orderSuccess.customer.name}
                    </div>
                  </div>
                </div>

                <div className="px-4 py-2 mt-4 text-sm border-t ">
                  <div className="flex w-full">
                    <div className="w-1/2 pr-4 font-bold">Item</div>
                  </div>
                  {orderSuccess.items.map((item, index) => (
                    <div className="flex w-full py-1">
                      <div className="w-1/2 pr-4">
                        {item.product.name}
                        <div className="text-xs">
                          {item.qty} x {item.price}{" "}
                          {item.discount_percentage > 0 &&
                            "(Disc " + item.discount_percentage + "%)"}
                        </div>
                      </div>
                      <div className="w-1/2 pr-4 text-right">
                        {item.subtotal}
                      </div>
                    </div>
                  ))}
                </div>

                <div className="px-4 py-1 flex text-sm ">
                  <div className=" w-1/2 font-bold">Subtotal</div>
                  <div className="w-1/2 pr-4 text-right">
                    {orderSuccess.subtotal}
                  </div>
                </div>
                <div className="px-4 flex text-sm ">
                  <div className=" w-1/2 ">Subtotal</div>
                  <div className="w-1/2 pr-4 text-right">
                    {orderSuccess.subtotal}
                  </div>
                </div>
                <div className="px-4 flex text-sm ">
                  <div className=" w-1/2 ">
                    Diskon tambahan
                    {orderSuccess.discount_percentage > 0 && (
                      <span className="text-xs">
                        {" "}
                        ({orderSuccess.discount_percentage}%)
                      </span>
                    )}
                  </div>
                  <div className="w-1/2 pr-4 text-right">
                    {orderSuccess.discount_amount}
                  </div>
                </div>
                <div className="px-4 flex text-sm ">
                  <div className=" w-1/2 ">
                    Pajak
                    {orderSuccess.tax_percentage > 0 && (
                      <span className="text-xs">
                        {" "}
                        ({orderSuccess.tax_percentage}%)
                      </span>
                    )}
                  </div>
                  <div className="w-1/2 pr-4 text-right">
                    {orderSuccess.tax_amount}
                  </div>
                </div>
                <div className="px-4 mt-4 py-4 flex text-sm border-t ">
                  <div className=" w-1/2 font-bold">Total</div>
                  <div className="w-1/2 pr-4 text-right">
                    {orderSuccess.total}
                  </div>
                </div>

                <div className="py-2"></div>

                <div className="flex gap-2 items-center  justify-end px-4 py-2 border-t border-gray-200 bg-gray-100">
                  <Button
                    label="Tutup"
                    onClick={() => setModalCheckoutSuccess(false)}
                  />
                  <ButtonSuccess
                    label="Cetak nota"
                    onClick={() => {
                      // open new tab
                      window.open(`sales/receipt/${orderSuccess.id}`, "_blank");
                      setModalCheckoutSuccess(false);
                    }}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default POS;

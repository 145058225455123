import React from "react";

import TopHead from "../Layouts/TopHead";
import Sidemenu from "../Layouts/Sidemenu";
import TableGrid from "../../components/TableGrid";
import LoadingBackdrop from "../../components/LoadingBackdrop";
import {
  Input,
  InputDisabled,
  Button,
  ButtonError,
  ButtonSuccess,
  ButtonBack,
} from "../../components/Form";

import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Config from "../../config/Config";
import Api from "../../services/Api";

function CustomerEdit() {
  const navigate = useNavigate();
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [attn, setAttn] = useState("");
  const [area, setArea] = useState("");
  const [npwp, setNpwp] = useState("");
  const [plafon, setPlafon] = useState(0);
  const [piutangAwal, setPiutangAwal] = useState(0);

  const [sales, setSales] = useState(0);
  const [salesReturn, setSalesReturn] = useState(0);
  const [cndn, setCndn] = useState(0);
  const [payment, setPayment] = useState(0);
  const [outstanding, setOutstanding] = useState(0);

  const [discount, setDiscount] = useState(0);

  const [type, setType] = useState("Personal");
  const [customerGroup, setCustomerGroup] = useState(null);
  const [customerGroups, setCustomerGroups] = useState([]);

  console.log(id);

  const getCustomerGroup = async () => {
    const response = await Api.get(Config.api_url + "/data/customergroup");
    console.log(response);
    setCustomerGroups(response);
  };

  useEffect(() => {
    setLoading(true);
    getCustomerGroup();

    // show data by id
    Api.get(Config.api_url + "/data/customer/" + id).then((response) => {
      console.log(response);
      setName(response.name);
      setPhone(response.phone);
      setEmail(response.email);
      setType(response.type);
      setAddress(response.address);
      setAttn(response.attn);
      setArea(response.area);
      setNpwp(response.npwp);
      setCustomerGroup(response.customer_group_id);
      setLoading(false);
    });
  }, []);

  const submit = () => {
    setLoading(true);

    // create post customer
    Api.put(Config.api_url + "/data/customer/" + id, {
      name: name,
      phone: phone,
      email: email,
      type: type,
      address: address,
      attn: attn,
      area: area,
      npwp: npwp,
      piutang_awal: piutangAwal,
      plafon_credit: plafon,
      customer_group_id: customerGroup,
    }).then((response) => {
      console.log(response);
      setLoading(false);
    });

    toast("Data berhasil disimpan", {
      type: "success",
    });
  };

  return (
    <div>
      {/* side menu tailwind */}
      <TopHead />
      <ToastContainer />
      <div className="flex flex-col md:flex-row h-screen">
        <Sidemenu />
        <div className="flex-1 overflow-scroll">
          <div className="p-4 pt-16">
            <div className="flex justify-between items-center mb-4">
              <div>
                <h3 className="py-2 font-bold text-rose-700">Edit pelanggan</h3>
              </div>
            </div>

            <div className="flex flex-col md:flex-row gap-8">
              <div className="w-1/3">
                <Input
                  label="Nama"
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <Input
                  label="Telepon"
                  type="tel"
                  value={[phone]}
                  onChange={(e) => setPhone(e.target.value)}
                />
                <Input
                  label="Email"
                  type="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <Input
                  label="Address"
                  type="text"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
                <Input
                  label="Attn"
                  type="text"
                  value={attn}
                  onChange={(e) => setAttn(e.target.value)}
                />
                <label className="block py-2 text-sm">Tipe</label>
                <select
                  onChange={(e) => setType(e.target.value)}
                  className="w-full block px-2 py-1 border border-gray-300 text-sm rounded-md focus:outline-none focus:border-rose-500">
                  <option
                    value="Personal"
                    selected={type == "Personal" ? true : false}>
                    Personal
                  </option>
                  <option
                    value="Company"
                    selected={type == "Company" ? true : false}>
                    Company
                  </option>
                </select>
                <label className="block py-2 text-sm">Grup</label>
                <select
                  onChange={(e) => setCustomerGroup(e.target.value)}
                  className="w-full block px-2 py-1 border border-gray-300 text-sm rounded-md focus:outline-none focus:border-rose-500">
                  {customerGroups.length > 0 &&
                    customerGroups.map((item) => (
                      <option
                        value={item.id}
                        selected={customerGroup == item.id ? true : false}>
                        {item.name}
                      </option>
                    ))}
                </select>

                <Input
                  label="Area"
                  type="text"
                  value={area}
                  onChange={(e) => setArea(e.target.value)}
                />
                <Input
                  label="NPWP"
                  type="text"
                  value={npwp}
                  onChange={(e) => setNpwp(e.target.value)}
                />
                <div className="py-2"></div>
                {loading ? (
                  <LoadingBackdrop />
                ) : (
                  <>
                    <ButtonBack
                      label="Kembali"
                      onClick={() => {
                        navigate("/customer");
                      }}
                    />
                    <ButtonSuccess label="Simpan" onClick={submit} />
                  </>
                )}
              </div>

              <div className="w-1/3">
                <Input
                  label="Plafon kredit"
                  type="number"
                  value={plafon}
                  onChange={(e) => setPlafon(e.target.value)}
                />
                <InputDisabled
                  label="Piutang awal"
                  type="number"
                  value={piutangAwal}
                  onChange={(e) => setPiutangAwal(e.target.value)}
                />
                <InputDisabled
                  label="Penjualan"
                  type="number"
                  value={sales}
                  onChange={(e) => setSales(e.target.value)}
                />
                <InputDisabled
                  label="Retur"
                  type="number"
                  value={salesReturn}
                  onChange={(e) => setSalesReturn(e.target.value)}
                />
                <InputDisabled
                  label="CN/DN"
                  type="number"
                  value={cndn}
                  onChange={(e) => setCndn(e.target.value)}
                />
                <InputDisabled
                  label="Pelunasan"
                  type="number"
                  value={payment}
                  onChange={(e) => setPayment(e.target.value)}
                />
                <InputDisabled
                  label="Piutang Akhir"
                  type="number"
                  value={outstanding}
                  onChange={(e) => setOutstanding(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomerEdit;

import React from "react";

import TopHead from "../Layouts/TopHead";
import Sidemenu from "../Layouts/Sidemenu";
import TableGrid from "../../components/TableGrid";
import LoadingBackdrop from "../../components/LoadingBackdrop";
import Modal from "../../components/Modal";
import { NumericFormat } from "react-number-format";
import { PatternFormat } from "react-number-format";

import {
  Input,
  InputXs,
  Button,
  ButtonError,
  ButtonSuccess,
  ButtonBack,
  Textarea,
} from "../../components/Form";

import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Config from "../../config/Config";
import Api from "../../services/Api";

function ItemRequestAdd() {
  const navigate = useNavigate();
  const { id } = useParams();

  const [loading, setLoading] = useState(true);
  const [itemrequest, setItemRequest] = useState({});

  const [modalAddPayment, setModalAddPayment] = useState(false);

  const [totalQty, setTotalQty] = useState(0);
  const [totalCollected, setTotalCollected] = useState(0);

  const [total, setTotal] = useState(0);

  const [items, setItems] = useState([]);

  const today = new Date();

  const [modalCompleted, setModalCompleted] = useState(false);

  const [payment, setPayment] = useState({
    purchase_order_id: id,
    date:
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate(),
    amount: "",
    note: "",
  });

  const getItemRequest = async () => {
    setLoading(true);

    const response = await Api.get(Config.api_url + "/data/itemrequest/" + id);
    console.log(response);

    // set total qty and total collected
    let totalQty = 0;
    let totalCollected = 0;
    response.items.map((item) => {
      totalQty += item.qty;
      totalCollected += item.collected;
    });

    setTotalQty(totalQty);
    setTotalCollected(totalCollected);

    setItemRequest(response);
    setItems(response.items);
    setLoading(false);
  };

  useEffect(() => {
    getItemRequest();
  }, []);

  const updateItemCollected = (value, id) => {
    const qty = value;
    console.log(qty);

    const newItems = items.map((item) =>
      item.id === id ? { ...item, collected: qty } : item
    );
    setItems(newItems);
  };

  const recalculateTotal = () => {
    let total = 0;
    items.forEach((item) => {
      total += parseInt(item.collected);
    });

    setItems(items);
    setTotalCollected(total);
  };

  useEffect(() => {
    recalculateTotal();
  }, [items]);

  const changeStatus = async (status) => {
    setLoading(true);

    const response = await Api.post(
      Config.api_url + "/data/itemrequest/changestatus/",
      {
        id: id,
        status: status,
      }
    );

    if (response) {
      toast("Status berhasil diubah", {
        type: "success",
      });
      getItemRequest();
      setModalCompleted(false);
    } else {
      toast("Status gagal diubah", {
        type: "error",
      });
    }

    setLoading(false);
  };

  const submit = async (status) => {
    // validation
    if (items.length === 0) {
      toast.error("Item harus diisi");
      return false;
    }

    let error = false;
    items.map((item) => {
      if (item.collected < 1) {
        toast.error("Qty tersedia tidak boleh 0");
        error = true;
        return;
      }

      if (item.collected > item.qty) {
        toast.error("Qty tersedia tidak boleh melebihi qty permintaan");
        error = true;
        return;
      }
    });
    if (error) return;

    setLoading(true);

    setTimeout(() => {
      // create post itemrequest

      Api.put(Config.api_url + "/data/itemrequest/" + id, {
        items: items,
        status: status,
      }).then((response) => {
        console.log(response);
        setLoading(false);

        if (response) {
          toast("Data berhasil disimpan", {
            type: "success",
          });

          setTimeout(() => {
            navigate("/itemrequest");
          }, 1000);
        } else {
          toast("Data gagal disimpan", {
            type: "error",

            autoClose: 2000,
          });
        }
      });
    }, 500);
  };

  return (
    <div>
      {/* side menu tailwind */}
      <TopHead />
      <ToastContainer />
      <div className="flex flex-col md:flex-row h-screen">
        <Sidemenu />
        <div className="flex-1 overflow-scroll">
          <div className="p-4 pt-16">
            {loading ? (
              <LoadingBackdrop />
            ) : (
              <>
                <div className="flex justify-between items-center mb-4">
                  <div className="flex flex-1 flex-row">
                    <div className="grow">
                      <h3 className="py-2 font-bold text-rose-700">
                        ItemRequest : {itemrequest.number}
                      </h3>
                    </div>

                    <div className=""></div>
                  </div>
                </div>

                <div className="flex flex-col md:flex-row mt-4">
                  <div className="w-full md:w-3/4 pr-4">
                    <div className="flex flex-col md:flex-row mb-4">
                      <div className="w-full md:w-1/4 pr-4">
                        <label className="block py-1 text-sm">Tanggal</label>
                        {new Date(itemrequest.created_at).toLocaleDateString()}
                      </div>
                      <div className="w-full md:w-1/4 pr-4">
                        <label className="block py-1 text-sm">
                          Gudang asal
                        </label>
                        {itemrequest.template.from_warehouse && (
                          <>{itemrequest.template.from_warehouse.name}</>
                        )}
                      </div>
                      <div className="w-full md:w-1/4 pr-4">
                        <label className="block py-1 text-sm">
                          Gudang tujuan
                        </label>
                        {itemrequest.template.to_warehouse && (
                          <>{itemrequest.template.to_warehouse.name}</>
                        )}
                      </div>
                      <div className="w-full md:w-1/4 pr-4">
                        <label className="block py-1 text-sm">Status</label>
                        {itemrequest.status}
                      </div>
                    </div>

                    <div className="border">
                      <table className="table-auto text-xs w-full">
                        <thead className="bg-gray-100">
                          <tr>
                            <th className="w-2 px-4 py-2">No</th>
                            <th className="text-left px-4 py-2">Nama</th>
                            <th className="w-32 text-left  px-4 py-2">SKU</th>
                            <th className="w-28 text-left  px-4 py-2">
                              Qty permintaan
                            </th>
                            <th className="w-32 text-right  px-4 py-2">
                              Qty tersedia
                            </th>
                          </tr>
                        </thead>

                        <tbody>
                          {items.map((item, index) => (
                            <tr className="">
                              <td className="text-center py-1  px-4">
                                {index + 1}
                              </td>
                              <td className="py-1 px-4">{item.product.name}</td>
                              <td className="py-1 px-4">{item.product.sku}</td>
                              <td className="py-1 px-4">{item.qty}</td>
                              <td className="py-1 px-4 text-right">
                                <InputXs
                                  type="number"
                                  value={item.collected}
                                  onChange={(e) =>
                                    updateItemCollected(e.target.value, item.id)
                                  }
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div className=" my-4 py-2">
                      <table className="table-auto text-sm w-full">
                        <tbody>
                          <tr className="">
                            <td className="text-right px-4 font-bold">Total</td>
                            <td className="w-48 text-right px-4 ">
                              <NumericFormat
                                decimalSeparator=","
                                thousandsGroupStyle="thousand"
                                thousandSeparator="."
                                value={totalQty}
                                className="font-bold text-right"
                              />
                            </td>
                            <td className="w-32 text-right px-4 ">
                              <NumericFormat
                                decimalSeparator=","
                                thousandsGroupStyle="thousand"
                                thousandSeparator="."
                                value={totalCollected}
                                className="font-bold text-right"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className="my-4">
                      <label className="block py-1 text-sm">Keterangan</label>
                      {itemrequest.note}
                    </div>

                    <div className="my-4 text-right justify-between flex">
                      <div>
                        <Button
                          label="Kembali"
                          onClick={() => {
                            navigate("/itemrequest");
                          }}
                        />
                      </div>
                      <div>
                        <a
                          href={"/itemrequest/pdf/" + id}
                          target="_blank"
                          className="px-3 my-2 py-2 border border-gray-200 rounded-lg text-sm text-gray-800"
                        >
                          Print
                        </a>
                        {itemrequest.status === "Draft" && (
                          <>
                            <span className="px-1"></span>
                            <Button
                              label="Simpan Draf"
                              onClick={() => {
                                {
                                  submit("Draft");
                                }
                              }}
                            />
                            <span className="px-1"></span>
                            <ButtonSuccess
                              label="Selesaikan"
                              onClick={() => {
                                submit("Completed");
                              }}
                            />
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}

            <div className="flex-1"></div>
          </div>
        </div>
      </div>

      {/** modal discount */}
      {modalCompleted && (
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50 flex items-center justify-center">
          <div className="bg-white md:w-64 w-64 rounded-md shadow-lg">
            <div className="flex justify-between items-center px-4 py-2 border-b border-gray-200 gap-2">
              <div className="h-6 grow">Konfirmasi</div>
            </div>

            <div className="px-4 py-2 flex">
              <div className="w-full pr-4">
                Yakin akan selesaikan transfer barang?
              </div>
            </div>
            <div className="flex gap-2 items-center  justify-end px-4 py-2 border-t border-gray-200">
              <Button label="Tutup" onClick={() => setModalCompleted(false)} />
              <ButtonSuccess
                label="Simpan"
                onClick={() => {
                  changeStatus("Completed");
                }}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ItemRequestAdd;

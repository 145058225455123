import React from "react";

import TopHead from "../Layouts/TopHead";
import Sidemenu from "../Layouts/Sidemenu";
import TableGrid from "../../components/TableGrid";
import LoadingBackdrop from "../../components/LoadingBackdrop";
import Select from "react-select";
import { NumericFormat } from "react-number-format";

import {
  Input,
  Button,
  ButtonError,
  ButtonSuccess,
  ButtonBack,
  ButtonSm,
  Textarea,
} from "../../components/Form";

import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Config from "../../config/Config";
import Api from "../../services/Api";

function JournalView() {
  const navigate = useNavigate();
  const { id } = useParams();

  const [data, setData] = useState({});

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    // show data by id
    Api.get(Config.api_url + "/data/journal/" + id).then((response) => {
      console.log(response);
      setData(response);
      setLoading(false);
    });
  }, []);

  return (
    <div>
      {/* side menu tailwind */}
      <TopHead />
      <ToastContainer />
      <div className="flex flex-col md:flex-row h-screen">
        <Sidemenu />
        <div className="flex-1 overflow-scroll">
          <div className="p-4 pt-16">
            <div className="flex justify-between items-center mb-4">
              <div>
                <h3 className="py-2 font-bold text-rose-700">Lihat jurnal</h3>
              </div>
            </div>

            <div className="w-full">
              <div className="flex flex-col md:flex-row mb-8">
                <div className="w-full md:w-1/4 pr-4">
                  <p className="font-bold">Tanggal</p>
                  {data.date && data.date}
                </div>
                <div className="w-full md:w-3/4 pr-4">
                  <p className="font-bold">Referensi</p>
                  {data.note && data.note}
                </div>
              </div>

              <p className="my-2 text-sm">Ayat jurnal</p>

              <table class="border w-full border-gray-400 text-sm">
                <thead>
                  <tr className="bg-gray-50">
                    <th class="border border-gray-400 py-2 px-6 w-1/2">Akun</th>
                    <th class="border border-gray-400 py-2 px-6">Debit</th>
                    <th class="border border-gray-400 py-2 px-6">Credit</th>
                    <th class="border border-gray-400 py-2 px-6"></th>
                  </tr>
                </thead>
                <tbody>
                  {data.items &&
                    data.items.map((i, index) => (
                      <tr key={index}>
                        <td className="border-x border-gray-400 py-2 px-6 ">
                          {i.debit > 0 && (
                            <span className="">{i.account.name}</span>
                          )}
                          {i.credit > 0 && (
                            <span className="ml-4">{i.account.name}</span>
                          )}
                        </td>
                        <td className="border-x border-gray-400 py-2 px-6 text-right">
                          {i.debit > 0 && (
                            <>
                              <NumericFormat
                                value={i.debit}
                                displayType={"text"}
                                thousandSeparator={true}
                              />
                            </>
                          )}
                        </td>
                        <td className="border-x border-gray-400 py-2 px-6 text-right">
                          {i.credit > 0 && (
                            <>
                              <NumericFormat
                                value={i.credit}
                                displayType={"text"}
                                thousandSeparator={true}
                              />
                            </>
                          )}
                        </td>
                        <td className="border-x border-gray-400 py-2 px-6"></td>
                      </tr>
                    ))}
                </tbody>
              </table>

              <div className="py-2 border-t"></div>

              <table className="table-auto w-full">
                <thead>
                  <tr>
                    <th className="text-left px-2 w-1/2">Total</th>
                    <th className="text-left px-2 w-1/5 text-center">
                      <NumericFormat
                        value={data.total}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                    </th>
                    <th className="text-left px-2  w-1/5 text-center">
                      <NumericFormat
                        value={data.total}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                    </th>
                    <th className="text-left px-2"></th>
                  </tr>
                </thead>
              </table>

              <div className="py-2"></div>
              {loading ? (
                <LoadingBackdrop />
              ) : (
                <>
                  <ButtonBack
                    label="Kembali"
                    onClick={() => {
                      navigate("/journal");
                    }}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default JournalView;

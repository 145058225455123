import React from "react";

import TopHead from "../Layouts/TopHead";
import Sidemenu from "../Layouts/Sidemenu";
import TableGrid from "../../components/TableGrid";
import LoadingBackdrop from "../../components/LoadingBackdrop";
import { NumericFormat } from "react-number-format";
import { PatternFormat } from "react-number-format";

import {
  Input,
  InputXs,
  Button,
  ButtonError,
  ButtonSuccess,
  ButtonBack,
  ButtonSm,
  Textarea,
} from "../../components/Form";

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Config from "../../config/Config";
import Api from "../../services/Api";

function PackingListAdd() {
  const navigate = useNavigate();

  const today = new Date();

  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(
    today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate()
  );

  // get purchase order
  const [purchaseOrders, setPurchaseOrders] = useState([]);

  const [totalReceived, setTotalReceived] = useState(0);

  const [purchaseOrder, setPurchaseOrder] = useState(null);
  const [purchaseOrderItems, setPurchaseOrderItems] = useState([]);

  const [warehouses, setWarehouses] = useState([]);
  const [warehouse, setWarehouse] = useState("");

  const [note, setNote] = useState("");

  const [modalAddPo, setModalAddPo] = useState(false);

  const [searchPo, setSearchPo] = useState("");
  const [itemsPo, setItemsPo] = useState([]);

  const getPurchaseOrders = async () => {
    const response = await Api.get(
      Config.api_url + "/data/purchaseorder?limit=100"
    );
    console.log(response);
    setPurchaseOrders(response);
  };

  const getWarehouse = async () => {
    setLoading(true);

    const response = await Api.get(
      Config.api_url + "/data/warehouse?page=1&limit=100"
    );

    console.log(response);
    setWarehouses(response);

    setLoading(false);
  };

  useEffect(() => {
    getPurchaseOrders();
    getWarehouse();
  }, []);

  const getPurchaseOrderItems = async (id) => {
    const response = await Api.get(
      Config.api_url + "/data/purchaseorder/" + id
    );
    console.log(response);

    let items = [];

    response.items.map((item) => {
      console.log(item);
      items.push({
        id: item.id,
        product_id: item.product_id,
        product_name: item.product.name,
        purchase_order_id: id,
        qty: item.qty,
        qty_delivered: item.qty_delivered,
        qty_received: 0,
      });
    });

    setPurchaseOrderItems([...purchaseOrderItems, ...items]);
  };

  const addPo = (id) => {
    // setPurchaseOrder(id);

    // check if PO already added
    if (itemsPo.includes(id)) {
      toast.error("Purchase order sudah ditambahkan");
      return;
    }

    // add po to itemsPo
    setItemsPo([...itemsPo, id]);

    // add po to purchaseOrderItems
    getPurchaseOrderItems(id);

    toast.success("Purchase order berhasil ditambahkan");
    // setModalAddPo(false);
  };

  const recalculateTotal = () => {
    let total = 0;
    purchaseOrderItems.forEach((item) => {
      total += parseInt(item.qty_received);
    });

    setTotalReceived(total);
  };

  // search po
  useEffect(() => {
    const searchData = async () => {
      if (searchPo !== "") {
        // if search po > 3 char
        if (searchPo.length >= 3) {
          const response = await Api.get(
            Config.api_url + "/data/purchaseorder?limit=100&filter=" + searchPo
          );
          console.log(response);
          setPurchaseOrders(response);
        }
      } else {
        getPurchaseOrders();
      }
    };

    searchData();
  }, [searchPo]);

  useEffect(() => {
    recalculateTotal();
  }, [purchaseOrderItems]);

  const submit = () => {
    // validation

    if (purchaseOrderItems.length === 0) {
      toast.error("Purchase order tidak memiliki item");
      return;
    }

    if (warehouse === "") {
      toast.error("Pilih dahulu gudang");
      return;
    }

    let valid = true;
    purchaseOrderItems.forEach((item) => {
      let balance = item.qty - item.qty_delivered;
      if (item.qty_received > balance) {
        valid = false;
      }
    });

    if (!valid) {
      toast.error("Qty received tidak boleh melebihi sisanya");
      return;
    }

    setLoading(true);

    // create post packinglist
    Api.post(Config.api_url + "/data/packinglist", {
      date: date,
      purchase_order_id: purchaseOrder,
      warehouse_id: warehouse,
      note: note,
      items: purchaseOrderItems,
      po: itemsPo,
    }).then((response) => {
      console.log(response);

      if (response) {
        toast.success("Packing list berhasil dibuat");

        setTimeout(() => {
          navigate("/packinglist");
        }, 1000);
      } else {
        toast.error("Packing list gagal dibuat");
      }
      setLoading(false);
    });
  };

  const autocompleteOn = () => {
    const dropdown = document.querySelector(`#autocomplete`);
    dropdown.classList.remove("hidden");
  };

  const autocompleteOff = () => {
    const dropdown = document.querySelector(`#autocomplete`);
    dropdown.classList.add("hidden");
  };

  return (
    <div>
      {/* side menu tailwind */}
      <TopHead />
      <ToastContainer />
      <div className="flex flex-col md:flex-row h-screen">
        <Sidemenu />
        <div className="flex-1 overflow-scroll">
          <div className="p-4 pt-16">
            <div className="flex justify-between items-center mb-4">
              <div>
                <h3 className="py-2 font-bold text-rose-700">
                  Tambah packinglist
                </h3>
              </div>
            </div>

            <div className="flex flex-col md:flex-row">
              <div className="w-full md:w-1/4 pr-4">
                <Input
                  label="Tanggal"
                  type="date"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                />
              </div>

              <div className="w-full md:w-1/4 pr-4">
                <label className="block py-2 text-sm">Gudang</label>
                <select
                  onChange={(e) => setWarehouse(e.target.value)}
                  className="w-full block px-2 py-2 border border-gray-300 text-xs rounded-md focus:outline-none focus:border-rose-500">
                  <option value="">- Pilih gudang -</option>
                  {warehouses.length > 0 &&
                    warehouses.map((item) => (
                      <option value={item.id}>{item.name}</option>
                    ))}
                </select>
              </div>
              <div className="w-full md:w-1/4 pr-4">
                <p></p>
                <br />
                <Button
                  label="Tambah PO +"
                  onClick={() => setModalAddPo(true)}></Button>
              </div>
            </div>

            <div className="flex flex-col md:flex-row mt-4 ">
              <div className="w-full md:w-3/4 pr-4">
                <table className="table-auto text-xs w-full">
                  <thead className="bg-gray-100">
                    <tr>
                      <th className="w-2 px-4 py-2">No</th>
                      <th className="w-96 text-left px-4 py-2">Nama barang</th>
                      <th className="w-24 text-left  px-4 py-2">Qty PO</th>
                      <th className="w-24 text-left  px-4 py-2">
                        Sudah diterima
                      </th>
                      <th className="w-24 text-left  px-4 py-2">Sisa</th>
                      <th className="w-24 text-right  px-4 py-2">Qty Masuk </th>
                    </tr>
                  </thead>
                </table>
                <div className="border h-80 overflow-scroll">
                  <table className="table-auto text-xs w-full">
                    <tbody>
                      {purchaseOrderItems.map((item, index) => (
                        <tr className="">
                          <td className=" w-2 text-center px-4">{index + 1}</td>
                          <td className=" w-96 px-4">{item.product_name}</td>
                          <td className=" w-24 px-4">{item.qty}</td>
                          <td className=" w-24 px-4">{item.qty_delivered}</td>
                          <td className=" w-24 px-4">
                            {item.qty - item.qty_delivered}
                          </td>
                          <td className=" w-24 px-4">
                            <InputXs
                              type="number"
                              value={item.qty_received}
                              onChange={(e) => {
                                const qty = parseInt(e.target.value);

                                let newItems = [];
                                purchaseOrderItems.map((items) => {
                                  if (items.id === item.id) {
                                    items.qty_received = qty;
                                  }
                                  newItems.push(items);
                                });

                                console.log(newItems);

                                setPurchaseOrderItems(newItems);
                              }}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                <div className="my-4 py-2">
                  <table className="table-auto text-sm w-full">
                    <tbody>
                      <tr className="">
                        <td className="text-right px-4 font-bold">Total</td>
                        <td className="w-32 text-right px-4">
                          <NumericFormat
                            decimalSeparator=","
                            thousandsGroupStyle="thousand"
                            thousandSeparator="."
                            className="font-bold text-right"
                            value={totalReceived}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div className="my-4">
                  <Textarea
                    label="Catatan"
                    placeholder="Tulis catatan Packing List"
                    value={note}
                    onChange={(e) => setNote(e.target.value)}></Textarea>
                </div>
              </div>
            </div>

            <div className="flex-1"></div>

            {loading ? (
              <LoadingBackdrop />
            ) : (
              <>
                <ButtonBack label="Kembali" />
                <ButtonSuccess label="Simpan" onClick={submit} />
              </>
            )}
          </div>
        </div>
      </div>

      {modalAddPo && (
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50 flex items-center justify-center">
          <div className="flex flex-col bg-white w-1/2 rounded-md shadow-lg h-96 ">
            <div className="h-18 justify-between items-center py-2">
              <div className="flex flex-row justify-between">
                <div className="w-1/2">
                  <h3 className="font-bold text-rose-700 px-4 py-1 pb-2 text-sm">
                    Tambah PO
                  </h3>
                </div>
                <div className="py-2 px-4">
                  <input
                    type="text"
                    className="border border-gray-300 rounded-md px-2 py-1 text-xs focus:outline-none focus:border-rose-500"
                    placeholder="Cari PO"
                    onChange={(e) => setSearchPo(e.target.value)}
                    value={searchPo}
                  />
                </div>
              </div>
              <table className="table-auto text-xs w-full">
                <thead className="bg-gray-100">
                  <tr>
                    <th className="w-32 px-4 py-2">No</th>
                    <th className="w-32 text-left px-4 py-2">Tanggal</th>
                    <th className="text-left px-4 py-2">Jumlah PO</th>
                    <th className="text-left px-4 py-2 text-right">Tambah</th>
                  </tr>
                </thead>
              </table>
            </div>
            <div className="flex-1 h-64 overflow-scroll">
              <table className="table-auto text-xs w-full">
                <tbody>
                  {purchaseOrders.length > 0 &&
                    purchaseOrders.map((item, index) => (
                      <tr className="">
                        <td className="w-32 text-center px-4">{item.number}</td>
                        <td className="w-32 px-4">{item.date}</td>
                        <td className=" px-4">{item.total}</td>
                        <td className=" px-4 text-right">
                          <Button
                            label="Tambah"
                            onClick={() => addPo(item.id)}></Button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            <div className="h-18 justify-between items-center py-2">
              <div className="flex flex-row justify-end">
                <div className="w-full px-4 text-right">
                  <ButtonError
                    label="Tutup"
                    onClick={() => setModalAddPo(false)}></ButtonError>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default PackingListAdd;

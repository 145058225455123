import Config from "../config/Config";

const getSavedToken = async () => {
  try {
    const value = localStorage.getItem("token");
    if (value !== null) {
      // value previously stored
      console.log(value);
      return value;
    } else {
      return null;
    }
  } catch (e) {
    // error reading value
    return null;
  }
};

// application/json headers

const Api = {
  // Get using fetch

  get: async (url) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    let token = await getSavedToken();
    myHeaders.append("Authorization", "Bearer " + token);
    console.log("Get token", token);
    let options = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    return fetch(url, options)
      .then((response) => response.json())
      .catch((error) => {
        console.log(error);

        if (error.status == 401) {
          window.location.href = Config.app_url + "/login";
        }
      });
  },
  // post using fetch
  post: async (url, data) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    let token = await getSavedToken();
    myHeaders.append("Authorization", "Bearer " + token);

    console.log("Post token", token);

    let options = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(data),
    };
    return fetch(url, options)
      .then((response) => response.json())
      .catch((error) => console.log(error));
  },
  // put using fetch
  put: async (url, data) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    let token = await getSavedToken();
    myHeaders.append("Authorization", "Bearer " + token);

    let options = {
      method: "PUT",
      headers: myHeaders,
      body: JSON.stringify(data),
    };
    return fetch(url, options)
      .then((response) => response.json())
      .catch((error) => console.log(error));
  },

  // delete using fetch
  delete: async (url) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    let token = await getSavedToken();
    myHeaders.append("Authorization", "Bearer " + token);

    let options = {
      method: "DELETE",
      headers: myHeaders,
    };
    return fetch(url, options)
      .then((response) => response.json())
      .catch((error) => console.log(error));
  },

  sendPushNotification: async (data) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "key=" + Config.fcmKey);

    let options = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(data),
    };
    return fetch("https://fcm.googleapis.com/fcm/send", options)
      .then((response) => response.json())
      .catch((error) => console.log(error));
  },
};

export default Api;

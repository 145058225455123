import React from "react";
import { useState, useEffect } from "react";
import Spinner from "../../components/Spinner";
import LoadingBackdrop from "../../components/LoadingBackdrop";
import { useNavigate } from "react-router-dom";
import Config from "../../config/Config";
import Api from "../../services/Api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import logo from "../../assets/logo.png";

function Login() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [Email, setEmail] = useState("");
  const [Password, setPassword] = useState("");

  const submitLogin = (e) => {
    e.preventDefault();
    setLoading(true);

    let data = {
      email: Email,
      password: Password,
    };

    Api.post(Config.api_url + "/auth/login", data).then((response) => {
      console.log(response);
      if (response.access_token) {
        localStorage.setItem("token", response.access_token);
        navigate("/dashboard");
      } else {
        toast("Login Gagal, email dan password tidak benar!", {
          type: "error",
        });
      }

      setLoading(false);
    });
  };

  return (
    <div className="h-screen w-full bg-red-700 fixed">
      <ToastContainer />

      <div className="flex justify-center px-6 my-12">
        <div className="md:basis-1/4 basis-1/2">
          <img src={logo} alt="logo" className="w-32 mx-auto mb-12" />

          <div className="bg-slate-100 p-8 rounded-xl">
            <h2 className="text-center text-lg font-bold text-gray-700 mb-2">
              Login
            </h2>

            <label className="block text-sm font-medium text-gray-700 my-2">
              Email
            </label>
            <input
              type="text"
              className="w-full px-3 py-2 text-sm text-gray-700 placeholder-gray-600 border rounded-lg focus:shadow-outline"
              placeholder="Email"
              value={Email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <label className="block text-sm font-medium text-gray-700 my-2">
              Password
            </label>
            <input
              type="password"
              className="w-full px-3 py-2 text-sm text-gray-700 placeholder-gray-600 border rounded-lg focus:shadow-outline"
              placeholder=""
              value={Password}
              onChange={(e) => setPassword(e.target.value)}
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  submitLogin(e);
                }
              }}
            />

            <div className="my-2">
              <a
                href="/dashboard"
                className="text-xs text-rose-700 hover:text-gray-600"
              >
                Forgot Password?
              </a>
            </div>
            <button
              className="w-full block bg-black hover:bg-gray-900 focus:bg-rose-400 text-white font-semibold rounded-lg px-4 py-3 mt-6"
              onClick={submitLogin}
            >
              {loading ? (
                <div className="flex justify-center">
                  <Spinner />
                </div>
              ) : (
                "Login"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;

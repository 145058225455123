import React from "react";

import TopHead from "../Layouts/TopHead";
import Sidemenu from "../Layouts/Sidemenu";
import TableGrid from "../../components/TableGrid";
import LoadingBackdrop from "../../components/LoadingBackdrop";
import { NumericFormat } from "react-number-format";
import { PatternFormat } from "react-number-format";

import {
  Input,
  InputXs,
  Button,
  ButtonError,
  ButtonSuccess,
  ButtonBack,
  Textarea,
} from "../../components/Form";

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Config from "../../config/Config";
import Api from "../../services/Api";

function TemplateTransferAdd() {
  const navigate = useNavigate();

  const today = new Date();

  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(
    today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate()
  );

  const [suppliers, setSuppliers] = useState([]);
  const [supplier, setSupplier] = useState("");

  const [currencies, setCurrencies] = useState([]);
  const [currency, setCurrency] = useState("");

  const [products, setProducts] = useState([]);
  const [productFilteredList, setProductFilteredList] = useState([]);

  const [items, setItems] = useState([]);

  const [note, setNote] = useState("");

  const [total, setTotal] = useState(0);

  const [warehouses, setWarehouses] = useState([]);
  const [fromWarehouse, setFromWarehouse] = useState("");
  const [toWarehouse, setToWarehouse] = useState("");

  const [filter, setFilter] = useState("");

  const getWarehouse = async () => {
    const response = await Api.get(Config.api_url + "/data/warehouse");
    console.log(response);
    setWarehouses(response);
  };

  useEffect(() => {
    getWarehouse();
  }, []);

  const addItem = (product) => {
    const item = {
      id: product.id,
      name: product.name,
      sku: product.sku,
      stock: product.stock,
      qty: 1,
    };

    // check if item already exist
    const exist = items.find((x) => x.id === item.id);
    if (exist) {
      toast.error("Item sudah ada");
    } else {
      console.log(item);

      const newItems = [...items, item];
      setItems(newItems);
      autocompleteOff();
    }
  };

  const filterProductList = (e) => {
    const response = Api.get(
      Config.api_url +
        "/data/product/getbywarehouse?warehouse_id=" +
        fromWarehouse +
        "&filter=" +
        e.target.value
    ).then((response) => {
      console.log(response);
      setProducts(response);
      setProductFilteredList(response);
    });
  };

  const updateItemQty = (value, id) => {
    const qty = value;
    console.log(qty);

    const newItems = items.map((item) =>
      item.id === id ? { ...item, qty: qty } : item
    );
    setItems(newItems);
  };

  const recalculateTotal = () => {
    let total = 0;
    items.forEach((item) => {
      total += parseInt(item.qty);
    });

    setItems(items);
    setTotal(total);
  };

  useEffect(() => {
    recalculateTotal();
  }, [items]);

  const submit = () => {
    // validation
    if (items.length === 0) {
      toast.error("Item harus diisi");
      return;
    }

    items.map((item) => {
      if (item.qty < 1) {
        toast.error("Qty tidak boleh 0");
        return;
      }

      if (item.qty > item.stock) {
        toast.error("Qty tidak boleh melebihi stok");
        return;
      }
    });

    setLoading(true);

    // create post template
    Api.post(Config.api_url + "/data/template", {
      date: date,
      from_warehouse_id: fromWarehouse,
      to_warehouse_id: toWarehouse,
      note: note,
      items: items,
    }).then((response) => {
      console.log(response);
      setLoading(false);

      if (response) {
        toast("Data berhasil disimpan", {
          type: "success",
        });

        setTimeout(() => {
          navigate("/templatetransfer");
        }, 1000);
      } else {
        toast("Data gagal disimpan", {
          type: "error",

          autoClose: 2000,
        });
      }
    });
  };

  const removeItem = (id) => {
    const newItems = items.filter((item) => item.id !== id);
    setItems(newItems);
  };

  const autocompleteOn = () => {
    // check if supplier is selected
    if (fromWarehouse === "") {
      toast.error("Pilih gudang asal terlebih dahulu");
      return;
    }

    // search product
    const response = Api.get(
      Config.api_url +
        "/data/product/getbywarehouse?warehouse_id=" +
        fromWarehouse +
        "&filter=" +
        filter
    ).then((response) => {
      console.log(response);
      setProducts(response);
      setProductFilteredList(response);
    });

    const dropdown = document.querySelector(`#autocomplete`);
    dropdown.classList.remove("hidden");
  };

  const autocompleteOff = () => {
    setTimeout(() => {
      const dropdown = document.querySelector(`#autocomplete`);
      dropdown.classList.add("hidden");
    }, 50);
  };

  return (
    <div>
      {/* side menu tailwind */}
      <TopHead />
      <ToastContainer />
      <div className="flex flex-col md:flex-row h-screen">
        <Sidemenu />
        <div className="flex-1 overflow-scroll">
          <div className="p-4 pt-16">
            <div className="flex justify-between items-center mb-4">
              <div>
                <h3 className="py-2 font-bold text-rose-700">
                  Tambah template
                </h3>
              </div>
            </div>

            <div className="flex flex-col md:flex-row">
              <div className="w-full md:w-1/4 pr-4">
                <label className="block py-2 text-sm">Dari gudang</label>
                <select
                  onChange={(e) => {
                    setItems([]);
                    setFromWarehouse(e.target.value);
                  }}
                  className="w-full block px-2 py-1 border border-gray-300 text-sm rounded-md focus:outline-none focus:border-rose-500">
                  <option value="">- Dari gudang -</option>
                  {warehouses.length > 0 &&
                    warehouses.map((item) => (
                      <option value={item.id}>{item.name}</option>
                    ))}
                </select>
              </div>
              <div className="w-full md:w-1/4 pr-4">
                <label className="block py-2 text-sm">Ke Gudang</label>
                <select
                  onChange={(e) => setToWarehouse(e.target.value)}
                  className="w-full block px-2 py-1 border border-gray-300 text-sm rounded-md focus:outline-none focus:border-rose-500">
                  <option value="">- Dari gudang -</option>
                  {warehouses.length > 0 &&
                    warehouses.map((item) => (
                      <option value={item.id}>{item.name}</option>
                    ))}
                </select>
              </div>
            </div>

            <div className="flex flex-col md:flex-row mt-2">
              <div className="w-full md:w-2/4 pr-4">
                <label className="block py-2 text-sm">Tambah Item barang</label>

                <input
                  type="text"
                  onFocus={autocompleteOn}
                  onChange={(e) => filterProductList(e)}
                  onBlur={autocompleteOff}
                  placeholder="Cari nama produk untuk menambahkan item"
                  className="w-full px-2 py-1 border border-gray-300 text-sm rounded-md focus:outline-none focus:border-rose-500"
                />

                <div id="autocomplete" className="relative hidden">
                  <div className="absolute w-full overflow-y-scroll h-48 top-100 p-4 border  text-xs rounded z-10 bg-white">
                    <ul>
                      {productFilteredList.length > 0 &&
                        productFilteredList.map((item) => (
                          <li className="py-1 ">
                            <button
                              class="flex flex-col text-indigo-800"
                              onClick={() => {
                                addItem(item);
                              }}>
                              <div className="flex justify-start items-center">
                                <img
                                  src={Config.api_url + "/" + item.image}
                                  width="30"
                                  className="mr-2"
                                />{" "}
                                {item.name}
                              </div>
                            </button>
                          </li>
                        ))}
                    </ul>
                    <div className="flex justify-end">
                      <button
                        onClick={autocompleteOff}
                        className="bg-gray-200 px-2 py-1 rounded text-xs">
                        Tutup
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col md:flex-row mt-4">
              <div className="w-full md:w-3/4 pr-4">
                <div className="border h-80">
                  <table className="table-auto text-xs w-full">
                    <thead className="bg-gray-100">
                      <tr>
                        <th className="w-2 px-4 py-2">No</th>
                        <th className="w-48 text-left px-4 py-2">Nama</th>
                        <th className="w-32 text-left  px-4 py-2">SKU</th>
                        <th className="w-24 text-center  px-4 py-2">
                          Stok tersedia
                        </th>
                        <th className="w-24 text-center  px-4 py-2">
                          Qty transfer
                        </th>
                        <th className="w-32 px-4 py-2">Aksi</th>
                      </tr>
                    </thead>

                    <tbody>
                      {items.map((item, index) => (
                        <tr className="">
                          <td className="text-center  px-4">{index + 1}</td>
                          <td className=" px-4">{item.name}</td>
                          <td className=" px-4">{item.sku}</td>
                          <td className="text-center px-4">
                            <NumericFormat
                              decimalSeparator=","
                              thousandsGroupStyle="thousand"
                              thousandSeparator="."
                              value={item.stock}
                              className="text-center"
                            />
                          </td>
                          <td className=" px-4 text-center">
                            <InputXs
                              type="number"
                              value={item.qty}
                              onChange={(e) =>
                                updateItemQty(e.target.value, item.id)
                              }
                            />
                          </td>
                          <td className=" px-4 text-center">
                            <button
                              onClick={() => removeItem(item.id)}
                              className="bg-red-500 text-xs hover:bg-red-700 text-white font-bold py-1 px-2 rounded">
                              Hapus
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                <div className=" my-4 py-2">
                  <table className="table-auto text-sm w-full">
                    <tbody>
                      <tr className="">
                        <td className="text-right px-4 font-bold " colSpan="4">
                          Total
                        </td>
                        <td className="w-32 text-right px-4 ">
                          <NumericFormat
                            decimalSeparator=","
                            thousandsGroupStyle="thousand"
                            thousandSeparator="."
                            value={total}
                            className="font-bold text-right"
                          />
                        </td>
                        <td className="w-32 px-4"></td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div className="my-4">
                  <Textarea
                    label="Catatan"
                    placeholder="Tulis catatan"
                    value={note}
                    onChange={(e) => setNote(e.target.value)}></Textarea>
                </div>
              </div>
            </div>

            <div className="flex-1"></div>

            {loading ? (
              <LoadingBackdrop />
            ) : (
              <>
                <ButtonBack
                  label="Kembali"
                  onClick={() => {
                    navigate("/templatetransfer");
                  }}
                />
                <ButtonSuccess label="Simpan" onClick={submit} />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TemplateTransferAdd;

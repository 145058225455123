import React from "react";
import TopHead from "../Layouts/TopHead";
import Sidemenu from "../Layouts/Sidemenu";
import TableGrid from "../../components/TableGrid";
import LoadingBackdrop from "../../components/LoadingBackdrop";

import { useState, useEffect } from "react";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Config from "../../config/Config";
import Api from "../../services/Api";

function Coa() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [filter, setFilter] = useState("");
  const [lastRefresh, setLastRefresh] = useState(new Date());

  const columns = [
    { label: "ID", field: "id" },
    { label: "Kode", field: "code" },
    { label: "Name", field: "name" },
    { label: "Kategori", field: "category.name" },
  ];

  const deleteData = (id) => {
    // delete from API
    Api.delete(Config.api_url + "/data/coa/" + id).then((response) => {
      console.log(response);
      toast("Data berhasil dihapus", {
        type: "success",
      });

      setLastRefresh(new Date());
    });
  };

  const actions = [
    {
      label: "Edit",
      method: {
        type: "url",
        url: "/coa/edit",
      },
    },
    {
      label: "Delete",
      class: "text-rose-500 bg-rose-800",
      method: {
        type: "function",
        function: deleteData,
      },
      confirm: {
        title: "Konfirmasi hapus?",
        message: "Yakin menghapus data ini",
      },
    },
  ];

  useEffect(() => {
    setLoading(true);

    // get data coa
    Api.get(
      Config.api_url +
        "/data/coa?limit=" +
        limit +
        "&page=" +
        page +
        "&filter=" +
        filter
    )
      .then((response) => {
        setData(response);
        console.log("Data", response);
      })
      .catch((error) => {
        console.log(error);
      });

    setLoading(false);
  }, [page, limit, filter, lastRefresh]);

  return (
    <div>
      {/* side menu tailwind */}
      <TopHead />
      <ToastContainer />
      <div className="flex flex-col md:flex-row h-screen">
        <Sidemenu />

        <div className="flex-1 overflow-scroll">
          <div className="p-4 pt-16">
            <div class="flex justify-between items-center mb-4">
              <div>
                <h3 className="py-2 font-bold text-rose-700">Coa</h3>
              </div>
              <div>
                <input
                  coa="text"
                  placeholder="search"
                  onChange={(e) => setFilter(e.target.value)}
                  className="w-64 px-2 py-1 border border-gray-300 text-sm rounded-md focus:outline-none focus:border-rose-500"
                />
              </div>
              <div>
                <span class="float-right">
                  <a
                    href="/coa/add"
                    className="px-3 py-2 border border-gray-200 rounded-lg ml-2 text-sm text-white bg-teal-400"
                  >
                    Tambah Coa
                  </a>
                </span>
              </div>
            </div>

            <TableGrid columns={columns} data={data} actions={actions} />

            <div className="mt-4 flex justify-end">
              <button
                className="bg-gray-400 text-sm text-white px-3 py-1 rounded-md"
                onClick={() => {
                  if (page > 1) {
                    setPage(page - 1);
                  }
                }}
              >
                Previous
              </button>
              <span className="px-3 py-1">{page}</span>
              <button
                className="bg-gray-400 text-sm text-white px-3 py-1 rounded-md"
                onClick={() => {
                  setPage(page + 1);
                }}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Coa;

import React from "react";

import TopHead from "../Layouts/TopHead";
import Sidemenu from "../Layouts/Sidemenu";
import TableGrid from "../../components/TableGrid";
import LoadingBackdrop from "../../components/LoadingBackdrop";
import { NumericFormat } from "react-number-format";
import { PatternFormat } from "react-number-format";

import {
  Input,
  InputXs,
  Button,
  ButtonError,
  ButtonSuccess,
  ButtonBack,
  Textarea,
} from "../../components/Form";

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Config from "../../config/Config";
import Api from "../../services/Api";

function PurchasingAdd() {
  const navigate = useNavigate();

  const today = new Date();

  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(
    today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate()
  );

  const [dueDate, setDueDate] = useState(
    today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate()
  );

  const [shippingMethod, setShippingMethod] = useState("Sea");

  const [suppliers, setSuppliers] = useState([]);
  const [supplier, setSupplier] = useState("");

  const [packinglists, setPackingLists] = useState([]);
  const [packinglist, setPackingList] = useState("");

  const [currencies, setCurrencies] = useState([]);
  const [currency, setCurrency] = useState("");

  const [warehouses, setWarehouses] = useState([]);
  const [warehouse, setWarehouse] = useState("");

  const [products, setProducts] = useState([]);
  const [productFilteredList, setProductFilteredList] = useState([]);

  const [items, setItems] = useState([]);

  const [note, setNote] = useState("");

  const [total, setTotal] = useState(0);

  const getSupplier = async () => {
    const response = await Api.get(Config.api_url + "/data/supplier");
    console.log(response);
    setSuppliers(response);
  };

  const getPackingList = async () => {
    const response = await Api.get(Config.api_url + "/data/packinglist");
    console.log(response);
    setPackingLists(response);
  };

  const getWarehouse = async () => {
    const response = await Api.get(Config.api_url + "/data/warehouse");
    console.log(response);
    setWarehouses(response);
  };

  const getCurrency = async () => {
    const response = await Api.get(Config.api_url + "/data/currency");
    console.log(response);
    setCurrencies(response);

    response.map((item) => {
      if (item.is_default === 1) {
        setCurrency(item.id);
      }
    });
  };

  const getProductBySupplier = async (supplier_id) => {
    setSupplier(supplier_id);

    const response = await Api.get(
      Config.api_url + "/data/product/supplier/" + supplier_id
    );
    console.log(response);
    setProducts(response);
    setProductFilteredList(response);
  };

  const getPurchasingItems = async (packinglistId) => {
    const response = await Api.get(
      Config.api_url +
        "/data/purchasing/packinglist?supplier=" +
        supplier +
        "&packinglist=" +
        packinglistId
    );

    console.log(response);
    const thisItem = response.map((item) => {
      return {
        id: item.id,
        product_id: item.product_id,
        name: item.product.name,
        price: item.price,
        qty: item.qty,
        subtotal: item.price * item.qty,
        shippingCost: 0,
        discount: 0,
      };
    });

    setItems(thisItem);
  };

  useEffect(() => {
    getSupplier();
    getPackingList();
    getCurrency();
    getWarehouse();
  }, []);

  const addItem = (product) => {
    const item = {
      id: product.id,
      name: product.name,
      price: product.cost,
      qty: 1,
      subtotal: product.cost,
    };

    // check if item already exist
    const exist = items.find((x) => x.id === item.id);
    if (exist) {
      toast.error("Item sudah ada");
    } else {
      console.log(item);

      const newItems = [...items, item];
      setItems(newItems);
      autocompleteOff();
    }
  };

  const filterProductList = (e) => {
    const keyword = e.target.value;
    console.log(keyword);

    const filteredList = products.filter((product) =>
      product.name.toLowerCase().includes(keyword.toLowerCase())
    );
    console.log(filteredList);

    setProductFilteredList(filteredList);

    if (keyword === "") {
      setProductFilteredList(products);
    }
  };

  const updateItemPrice = (value, id) => {
    const price = value;
    console.log(price);

    const newItems = items.map((item) =>
      item.id === id ? { ...item, price: price } : item
    );
    setItems(newItems);
  };

  const updateItemQty = (value, id) => {
    const qty = value;
    console.log(qty);

    const newItems = items.map((item) =>
      item.id === id ? { ...item, qty: qty } : item
    );
    setItems(newItems);
  };
  const updateItemDiscount = (value, id) => {
    const discount = value;
    console.log(discount);

    const newItems = items.map((item) =>
      item.id === id ? { ...item, discount: discount } : item
    );
    setItems(newItems);
  };
  const updateItemShippingCost = (value, id) => {
    const cost = value;
    console.log(cost);

    const newItems = items.map((item) =>
      item.id === id ? { ...item, shippingCost: cost } : item
    );
    setItems(newItems);
  };

  const recalculateTotal = () => {
    let total = 0;
    items.forEach((item) => {
      item.subtotal = item.price * item.qty;
      item.subtotal = item.subtotal - (item.subtotal * item.discount) / 100;
      item.total = parseInt(item.subtotal) + parseInt(item.shippingCost);
      total += item.total;
    });

    setItems(items);
    setTotal(total);
  };

  useEffect(() => {
    recalculateTotal();
  }, [items]);

  const submit = () => {
    // validation
    if (date === "") {
      toast.error("Tanggal harus diisi");
      return;
    }

    if (supplier === "") {
      toast.error("Supplier harus diisi");
      return;
    }

    if (currency === "") {
      toast.error("Mata uang harus diisi");
      return;
    }

    if (items.length === 0) {
      toast.error("Item harus diisi");
      return;
    }

    if (warehouse === "") {
      toast.error("Gudang harus diisi");
      return;
    }

    if (shippingMethod === "") {
      toast.error("Metode pengiriman harus diisi");
      return;
    }

    setLoading(true);

    // create post purchasing
    Api.post(Config.api_url + "/data/purchasing", {
      date: date,
      due_date: dueDate,
      packing_list_id: packinglist,
      supplier_id: supplier,
      currency_id: currency,
      warehouse_id: warehouse,
      shipping_method: shippingMethod,
      note: note,
      total: total,
      items: items,
    }).then((response) => {
      console.log(response);
      setLoading(false);
    });

    toast("Data berhasil disimpan", {
      type: "success",
    });

    setTimeout(() => {
      navigate("/purchasing");
    }, 1000);
  };

  const removeItem = (id) => {
    const newItems = items.filter((item) => item.id !== id);
    setItems(newItems);
  };

  const autocompleteOn = () => {
    // check if supplier is selected
    if (supplier === "") {
      toast.error("Pilih supplier dahulu");
      return;
    }

    const dropdown = document.querySelector(`#autocomplete`);
    dropdown.classList.remove("hidden");
  };

  const autocompleteOff = () => {
    setTimeout(() => {
      const dropdown = document.querySelector(`#autocomplete`);
      dropdown.classList.add("hidden");
    }, 50);
  };

  return (
    <div>
      {/* side menu tailwind */}
      <TopHead />
      <ToastContainer />
      <div className="flex flex-col md:flex-row h-screen">
        <Sidemenu />
        <div className="flex-1 overflow-scroll">
          <div className="p-4 pt-16">
            <div className="flex justify-between items-center mb-4">
              <div>
                <h3 className="py-2 font-bold text-rose-700">
                  Tambah pembelian
                </h3>
              </div>
            </div>

            <div className="flex flex-col md:flex-row">
              <div className="w-full md:w-1/4 pr-4">
                <Input
                  label="Tanggal"
                  type="date"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                />
              </div>
              <div className="w-full md:w-1/4 pr-4">
                <label className="block py-2 text-sm">Supplier</label>
                <select
                  onChange={(e) => {
                    setSupplier(e.target.value);
                  }}
                  className="w-full block px-2 py-1 border border-gray-300 text-sm rounded-md focus:outline-none focus:border-rose-500"
                >
                  <option value="">- Pilih supplier -</option>
                  {suppliers.length > 0 &&
                    suppliers.map((item) => (
                      <option value={item.id}>{item.name}</option>
                    ))}
                </select>
              </div>
              <div className="w-full md:w-1/4 pr-4">
                <label className="block py-2 text-sm">Packing List</label>
                <select
                  onChange={(e) => {
                    setPackingList(e.target.value);
                    getPurchasingItems(e.target.value);
                  }}
                  className="w-full block px-2 py-1 border border-gray-300 text-sm rounded-md focus:outline-none focus:border-rose-500"
                >
                  <option value="">- Nomor packing list -</option>
                  {packinglists.length > 0 &&
                    packinglists.map((item) => (
                      <option value={item.id}>{item.number}</option>
                    ))}
                </select>
              </div>
              <div className="w-full md:w-1/4 pr-4">
                <label className="block py-2 text-sm">Mata uang</label>
                <select
                  defaultValue={currency}
                  onChange={(e) => setCurrency(e.target.value)}
                  className="w-full block px-2 py-1 border border-gray-300 text-sm rounded-md focus:outline-none focus:border-rose-500"
                >
                  {currencies.length > 0 &&
                    currencies.map((item) => (
                      <option
                        value={item.id}
                        selected={item.is_default == 1 ? "selected" : ""}
                      >
                        {item.code}
                      </option>
                    ))}
                </select>
              </div>
            </div>
            <div className="flex flex-col md:flex-row">
              <div className="w-full md:w-1/4 pr-4">
                <Input
                  label="Jatuh tempo"
                  type="date"
                  value={dueDate}
                  onChange={(e) => setDueDate(e.target.value)}
                />
              </div>
              <div className="w-full md:w-1/4 pr-4">
                <label className="block py-2 text-sm">Pengiriman</label>
                <select
                  onChange={(e) => getProductBySupplier(e.target.value)}
                  className="w-full block px-2 py-1 border border-gray-300 text-sm rounded-md focus:outline-none focus:border-rose-500"
                >
                  <option value="Sea">Sea</option>
                  <option value="Air">Air</option>
                </select>
              </div>
              <div className="w-full md:w-1/4 pr-4">
                <label className="block py-2 text-sm">Gudang</label>
                <select
                  onChange={(e) => {
                    setWarehouse(e.target.value);
                  }}
                  className="w-full block px-2 py-1 border border-gray-300 text-sm rounded-md focus:outline-none focus:border-rose-500"
                >
                  <option value="">- Pilih gudang -</option>
                  {warehouses.length > 0 &&
                    warehouses.map((item) => (
                      <option value={item.id}>{item.name}</option>
                    ))}
                </select>
              </div>
            </div>

            <div className="flex flex-col md:flex-row mt-2">
              <div className="w-full md:w-2/4 pr-4">
                <div id="autocomplete" className="relative hidden">
                  <div className="absolute w-full overflow-y-scroll h-48 top-100 p-4 border  text-xs rounded z-10 bg-white">
                    <ul>
                      {productFilteredList.length > 0 &&
                        productFilteredList.map((item) => (
                          <li className="py-1 ">
                            <button
                              class="flex flex-col text-indigo-800"
                              onClick={() => {
                                addItem(item);
                              }}
                            >
                              <div className="flex justify-start items-center">
                                <img
                                  src={Config.api_url + "/" + item.image}
                                  width="30"
                                  className="mr-2"
                                />{" "}
                                {item.name}
                              </div>
                            </button>
                          </li>
                        ))}
                    </ul>
                    <div className="flex justify-end">
                      <button
                        onClick={autocompleteOff}
                        className="bg-gray-200 px-2 py-1 rounded text-xs"
                      >
                        Tutup
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col md:flex-row mt-4">
              <div className="w-full md:full pr-4">
                <div className="border h-80">
                  <table className="table-auto text-xs w-full">
                    <thead className="bg-gray-100">
                      <tr>
                        <th className="w-2 px-4 py-2">No</th>
                        <th className="text-left px-4 py-2">Nama</th>
                        <th className="w-32 text-left  px-4 py-2">Harga</th>
                        <th className="w-32 text-left  px-4 py-2">Qty</th>
                        <th className="w-32 text-left  px-4 py-2">
                          Diskon (%)
                        </th>
                        <th className="w-32 text-right  px-4 py-2">Subtotal</th>
                        <th className="w-32 text-right  px-4 py-2">Ongkir</th>
                        <th className="w-32 text-right  px-4 py-2">Total</th>
                        <th className="w-32 px-4 py-2">Aksi</th>
                      </tr>
                    </thead>

                    <tbody>
                      {items.map((item, index) => (
                        <tr className="">
                          <td className="text-center  px-4">{index + 1}</td>
                          <td className=" px-4">{item.name}</td>
                          <td className=" px-4">
                            <InputXs
                              type="number"
                              value={item.price}
                              onChange={(e) =>
                                updateItemPrice(e.target.value, item.id)
                              }
                            />
                          </td>
                          <td className=" px-4">
                            <InputXs
                              type="number"
                              value={item.qty}
                              onChange={(e) =>
                                updateItemQty(e.target.value, item.id)
                              }
                            />
                          </td>
                          <td className=" px-4">
                            <InputXs
                              type="number"
                              value={item.discount}
                              onChange={(e) =>
                                updateItemDiscount(e.target.value, item.id)
                              }
                            />
                          </td>
                          <td className=" px-4 text-right">
                            <NumericFormat
                              decimalSeparator=","
                              thousandsGroupStyle="thousand"
                              thousandSeparator="."
                              value={item.subtotal}
                              className="text-right"
                            />
                          </td>
                          <td className=" px-4">
                            <InputXs
                              type="number"
                              value={item.shippingCost}
                              onChange={(e) =>
                                updateItemShippingCost(e.target.value, item.id)
                              }
                            />
                          </td>
                          <td className=" px-4 text-right">
                            <NumericFormat
                              decimalSeparator=","
                              thousandsGroupStyle="thousand"
                              thousandSeparator="."
                              value={item.total}
                              className="text-right"
                            />
                          </td>
                          <td className=" px-4 text-center">
                            <button
                              onClick={() => removeItem(item.id)}
                              className="bg-red-500 text-xs hover:bg-red-700 text-white font-bold py-1 px-2 rounded"
                            >
                              Hapus
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                <div className=" my-4 py-2">
                  <table className="table-auto text-sm w-full">
                    <tbody>
                      <tr className="">
                        <td className="text-right px-4 font-bold " colSpan="4">
                          Total
                        </td>
                        <td className="w-32 text-right px-4 ">
                          <NumericFormat
                            decimalSeparator=","
                            thousandsGroupStyle="thousand"
                            thousandSeparator="."
                            value={total}
                            className="font-bold text-right"
                          />
                        </td>
                        <td className="w-32 px-4"></td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div className="my-4">
                  <Textarea
                    label="Catatan"
                    placeholder="Tulis catatan PO"
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                  ></Textarea>
                </div>
              </div>
            </div>

            <div className="flex-1"></div>

            {loading ? (
              <LoadingBackdrop />
            ) : (
              <>
                <ButtonBack
                  label="Kembali"
                  onClick={() => {
                    navigate("/purchasing");
                  }}
                />
                <ButtonSuccess label="Simpan" onClick={submit} />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PurchasingAdd;

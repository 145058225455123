import React from "react";
import { useNavigate } from "react-router-dom";

function Input(props) {
  return (
    <div>
      <label className="block py-2 text-sm">{props.label}</label>
      <input
        type={props.type}
        placeholder={props.placeholder}
        defaultValue={props.defaultValue}
        onChange={props.onChange}
        onKeyUp={props.onKeyUp}
        value={props.value}
        max={props.max}
        min={props.min}
        maxLength={props.maxLength}
        className="w-full px-2 py-1 h-9 border border-gray-300 text-sm rounded-md focus:outline-none focus:border-rose-500"
      />
    </div>
  );
}
function InputDisabled(props) {
  return (
    <div>
      <label className="block py-2 text-sm">{props.label}</label>
      <input
        type={props.type}
        placeholder={props.placeholder}
        defaultValue={props.defaultValue}
        onChange={props.onChange}
        onKeyUp={props.onKeyUp}
        value={props.value}
        max={props.max}
        min={props.min}
        maxLength={props.maxLength}
        disabled
        className="w-full px-2 py-1 h-9 border border-gray-300 text-sm rounded-md focus:outline-none focus:border-rose-500 bg-gray-50"
      />
    </div>
  );
}
function InputXs(props) {
  return (
    <div>
      <label className="block py-2 text-sm">{props.label}</label>
      <input
        type={props.type}
        placeholder={props.placeholder}
        onChange={props.onChange}
        value={props.value}
        className="w-full px-2 py-1 border border-gray-300 text-xs rounded-md focus:outline-none focus:border-rose-500"
      />
    </div>
  );
}

function Textarea(props) {
  return (
    <div>
      <label className="block py-2 text-sm">{props.label}</label>
      <textarea
        placeholder={props.placeholder}
        onChange={props.onChange}
        value={props.value}
        className="w-full px-2 py-1 border border-gray-300 text-sm rounded-md focus:outline-none focus:border-rose-500"
      />
    </div>
  );
}

function Select(props) {
  return (
    <div>
      <input
        type={props.type}
        placeholder={props.placeholder}
        onChange={props.onChange}
        className={props.className}
      />
    </div>
  );
}

function Button(props) {
  return (
    <button
      onClick={props.onClick}
      className="px-3 my-2 py-2 border border-gray-200 rounded-lg text-sm text-gray-800">
      {props.label}
    </button>
  );
}
function ButtonSm(props) {
  return (
    <button
      onClick={props.onClick}
      className="px-3  mt-4 mb-2 w-full font-bold  py-1 border border-gray-200 rounded-lg text-sm bg-gray-100 text-gray-800">
      {props.label}
    </button>
  );
}
function ButtonSmGray(props) {
  return (
    <button
      onClick={props.onClick}
      className="px-3  mt-4 mb-2 w-full font-bold  py-1 border border-gray-600 rounded-lg text-sm bg-gray-600 text-white">
      {props.label}
    </button>
  );
}

function ButtonSuccess(props) {
  return (
    <button
      onClick={props.onClick}
      className="px-3 my-2 py-2 border border-gray-200 rounded-lg text-sm text-white bg-teal-600">
      {props.label}
    </button>
  );
}
function ButtonGray(props) {
  return (
    <button
      onClick={props.onClick}
      className="px-3 my-2 py-2 border border-gray-200 rounded-lg text-sm text-white  bg-gray-600">
      {props.label}
    </button>
  );
}
function ButtonSuccessFull(props) {
  return (
    <button
      onClick={props.onClick}
      className="px-3 my-2 py-2 border border-gray-200 w-full rounded-lg text-sm text-white bg-teal-600 font-bold">
      {props.label}
    </button>
  );
}

function ButtonError(props) {
  return (
    <button
      onClick={props.onClick}
      className="px-3 my-2 py-2 border border-gray-200 rounded-lg text-sm text-white bg-rose-400">
      {props.label}
    </button>
  );
}
function ButtonBack(props) {
  return (
    <button
      onClick={props.onClick}
      className="px-3 my-2 py-2 border border-gray-200 rounded-lg text-sm text-white bg-rose-400">
      {props.label}
    </button>
  );
}

function ButtonBackFull(props) {
  return (
    <button
      onClick={props.onClick}
      className="px-3 my-4 py-1 border border-gray-200 w-full rounded-lg text-sm text-white bg-rose-400 font-bold">
      {props.label}
    </button>
  );
}

export {
  Input,
  InputDisabled,
  InputXs,
  Textarea,
  Select,
  Button,
  ButtonSm,
  ButtonSmGray,
  ButtonSuccess,
  ButtonError,
  ButtonBack,
  ButtonSuccessFull,
  ButtonBackFull,
  ButtonGray,
};

import React from "react";
import TopHead from "../Layouts/TopHead";
import Sidemenu from "../Layouts/Sidemenu";
import TableGrid from "../../components/TableGrid";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Dashboard() {
  const columns = [
    { label: "ID", field: "id" },
    { label: "Name", field: "name" },
  ];

  const data = [
    {
      id: 1,
      name: "John Doe",
    },
    { id: 2, name: "Jacob" },
  ];

  const deleteData = (id) => {
    toast("Delete " + id, {
      type: "error",
    });
  };

  const actions = [
    {
      label: "Edit",
      method: {
        type: "url",
        url: "/delete",
      },
    },
    {
      label: "Delete",
      method: {
        type: "function",
        function: deleteData,
      },
      confirm: {
        title: "Konfirmasi hapus?",
        message: "Yakin menghapus data ini",
      },
    },
  ];

  return (
    <div>
      {/* side menu tailwind */}
      <TopHead />
      <ToastContainer />
      <div className="flex flex-col md:flex-row h-screen">
        <Sidemenu />
        <div className="flex-1 overflow-hidden">
          <div className="p-8 pt-12"></div>

          <div className="p-4 pt-2">Dashboard</div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;

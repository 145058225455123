import React from "react";

import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
} from "@react-pdf/renderer";

import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Config from "../../config/Config";
import Api from "../../services/Api";

// Create styles
const styles = StyleSheet.create({
  page: {
    // flexDirection: "row",
    width: "100%",
    backgroundColor: "#E4E4E4",
    fontSize: 11,
  },
  section: {
    flexDirection: "row",
    margin: 15,
  },
  sectionList: {
    flexDirection: "row",
    marginHorizontal: 15,
    marginVertical: 5,
  },

  full: {
    width: "100%",
    margin: 15,
  },
  half: {
    width: "50%",
  },
});

// Create Document Component
function ItemRequestPdf() {
  const { id } = useParams();

  const [itemRequest, setItemRequest] = useState({
    number: "",
    status: "",
    template: {
      number: "",
      from_warehouse: {
        name: "",
      },
      to_warehouse: {
        name: "",
      },
    },
  });

  const getItemRequest = async () => {
    const response = await Api.get(Config.api_url + "/data/itemrequest/" + id);

    setItemRequest(response);
  };

  useEffect(() => {
    getItemRequest();
  }, []);

  return (
    <PDFViewer style={{ width: "100%", height: 800 }}>
      <Document title="Item Request" author="Multitoys">
        <Page size="A4" style={styles.page}>
          <View style={styles.section}>
            <View style={styles.half}>
              <Text>Permintaan Barang</Text>
              <Text>{itemRequest.number}</Text>
              <Text>Status: {itemRequest.status}</Text>
            </View>
            <View>
              <Text>Template: {itemRequest.template.number}</Text>
              <Text>
                Gudang asal: {itemRequest.template.from_warehouse.name}
              </Text>
              <Text>
                Gudang tujuan: {itemRequest.template.to_warehouse.name}
              </Text>
            </View>
          </View>
          <View style={{ borderBottom: "1px #333 solid" }}></View>
          <View style={styles.section}>
            <View style={styles.half}>
              <Text>Barang</Text>
            </View>
            <View style={styles.half}>
              <Text>Qty permintaan</Text>
            </View>
            <View style={styles.half}>
              <Text>Qty tersedia</Text>
            </View>
          </View>
          <View style={{ borderBottom: "1px #333 solid" }}></View>
          {itemRequest.items &&
            itemRequest.items.map((item) => (
              <View style={styles.sectionList}>
                <View style={styles.half}>
                  <Text>{item.product.name}</Text>
                </View>
                <View style={styles.half}>
                  <Text>{item.qty}</Text>
                </View>
                <View style={styles.half}>
                  {itemRequest.status === "Completed" ? (
                    <Text>{item.collected}</Text>
                  ) : (
                    <View
                      style={{
                        width: 100,
                        border: "1px solid #666",
                        padding: 10,
                      }}
                    ></View>
                  )}
                </View>
              </View>
            ))}
        </Page>
      </Document>
    </PDFViewer>
  );
}

export default ItemRequestPdf;

import React from "react";

import TopHead from "../Layouts/TopHead";
import Sidemenu from "../Layouts/Sidemenu";
import TableGrid from "../../components/TableGrid";
import LoadingBackdrop from "../../components/LoadingBackdrop";
import Modal from "../../components/Modal";
import { NumericFormat } from "react-number-format";
import { PatternFormat } from "react-number-format";

import {
  Input,
  InputXs,
  Button,
  ButtonError,
  ButtonSuccess,
  ButtonBack,
  Textarea,
} from "../../components/Form";

import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Config from "../../config/Config";
import Api from "../../services/Api";

function OpnameView() {
  const navigate = useNavigate();
  const { id } = useParams();

  const [loading, setLoading] = useState(true);
  const [opname, setOpname] = useState({});

  const today = new Date();

  const getOpname = async () => {
    setLoading(true);

    const response = await Api.get(Config.api_url + "/data/opname/" + id);
    console.log(response);

    setOpname(response);
    setLoading(false);
  };

  useEffect(() => {
    getOpname();
  }, []);

  const submit = async () => {
    setLoading(true);

    const response = await Api.put(Config.api_url + "/data/opname/" + id, {
      note: opname.note,
      items: opname.items,
      status: "In Counting",
    });

    setLoading(false);

    if (response) {
      toast.success("Berhasil disimpan");
      await getOpname();
    } else {
      toast.error("Gagal disimpan");
    }
  };

  const submitConfirm = async () => {
    const response = await Api.put(Config.api_url + "/data/opname/" + id, {
      note: opname.note,
      items: opname.items,
      status: "Completed",
    });

    setLoading(false);

    if (response) {
      toast.success("Opname Berhasil diselesaikan");
      await getOpname();
    } else {
      toast.error("Gagal disimpan");
    }
  };

  return (
    <div>
      {/* side menu tailwind */}
      <TopHead />
      <ToastContainer />
      <div className="flex flex-col md:flex-row h-screen">
        <Sidemenu />
        <div className="flex-1 overflow-scroll">
          <div className="p-4 pt-16">
            {loading ? (
              <LoadingBackdrop />
            ) : (
              <>
                <div className="flex justify-between items-center mb-4">
                  <div>
                    <h3 className="py-2 font-bold text-rose-700">
                      Opname : {opname.number}
                    </h3>
                  </div>
                </div>

                <div className="flex flex-col md:flex-row">
                  <div className="w-full md:w-1/4 pr-4">
                    <label className="block py-1 text-sm">Tanggal</label>
                    {new Date(opname.created_at).toLocaleDateString()}
                  </div>
                  <div className="w-full md:w-1/4 pr-4">
                    <label className="block py-1 text-sm">Gudang</label>
                    {opname.warehouse.name}
                  </div>
                  <div className="w-full md:w-1/4 pr-4">
                    <label className="block py-1 text-sm">Status</label>
                    {opname.status}
                  </div>
                </div>

                <div className="flex flex-col md:flex-row mt-4">
                  <div className="w-full md:w-3/4 pr-4">
                    <div className="border h-96 overflow-scroll">
                      <table className="table-auto text-xs w-full">
                        <thead className="bg-gray-100">
                          <tr>
                            <th className="w-2 px-4 py-2">No</th>
                            <th className="text-left px-4 py-2">Nama</th>
                            <th className="text-left px-4 py-2">SKU</th>
                            <th className="w-32 text-left text-right  px-4 py-2">
                              Stok Sistem
                            </th>
                            <th className="w-32 text-left text-right  px-4 py-2">
                              Stok
                            </th>
                          </tr>
                        </thead>

                        <tbody>
                          {opname.items.map((item, index) => (
                            <tr className="">
                              <td className="text-center py-1  px-4">
                                {index + 1}
                              </td>
                              <td className="py-1 px-4">{item.product.name}</td>
                              <td className="py-1 px-4">{item.product.sku}</td>
                              <td className="py-1 px-4 text-right">
                                {item.qty}
                              </td>
                              <td className="py-1 px-4 text-right">
                                {opname.status == "In Counting" ? (
                                  <input
                                    type="number"
                                    name="qty"
                                    value={item.count_qty}
                                    onChange={(e) => {
                                      let newItems = [...opname.items];
                                      newItems[index].count_qty =
                                        e.target.value;
                                      setOpname({
                                        ...opname,
                                        items: newItems,
                                      });
                                    }}
                                    className="w-16 text-right border border-gray-300 rounded-md py-1 px-2 focus:outline-none focus:ring-2 focus:ring-rose-600 focus:border-transparent"
                                  />
                                ) : (
                                  item.count_qty
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div className="my-4">
                      <label className="block py-1 text-sm">Keterangan</label>
                      {opname.status == "In Counting" ? (
                        <Input
                          type="text"
                          name="Catatan"
                          value={opname.note}
                          onChange={(e) => {
                            setOpname({
                              ...opname,
                              note: e.target.value,
                            });
                          }}
                        />
                      ) : (
                        opname.note
                      )}
                    </div>
                  </div>
                </div>
                <div className="w-3/4 flex gap-2 justify-between pr-4">
                  <ButtonBack
                    label="Kembali"
                    onClick={() => {
                      navigate("/opname");
                    }}
                  />
                  <div className="flex gap-2">
                    {opname.status == "In Counting" && (
                      <>
                        <Button
                          label="Simpan"
                          onClick={() => {
                            submit();
                          }}
                        />

                        <ButtonSuccess
                          label="Selesaikan Opname"
                          onClick={() => {
                            submitConfirm();
                          }}
                        />
                      </>
                    )}
                  </div>
                </div>
              </>
            )}

            <div className="flex-1"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OpnameView;

import React from "react";

import TopHead from "../Layouts/TopHead";
import Sidemenu from "../Layouts/Sidemenu";
import TableGrid from "../../components/TableGrid";
import LoadingBackdrop from "../../components/LoadingBackdrop";
import {
  Input,
  Button,
  ButtonError,
  ButtonSuccess,
  ButtonBack,
} from "../../components/Form";

import Select from "react-select";
import AsyncSelect from "react-select/async";

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Config from "../../config/Config";
import Api from "../../services/Api";

function SubBrandAdd() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [brand, setBrand] = useState(null);
  const [brands, setBrands] = useState([]);

  const getBrand = async () => {
    const response = await Api.get(Config.api_url + "/data/brand?limit=1000");
    console.log(response);
    setBrands(response);
    setBrand(response[0].id);
  };

  useEffect(() => {
    getBrand();
  }, []);

  const submit = () => {
    setLoading(true);

    // create post subbrand
    Api.post(Config.api_url + "/data/subbrand", {
      name: name,
      brand_id: brand,
    }).then((response) => {
      console.log(response);
      setLoading(false);
    });

    toast("Data berhasil disimpan", {
      type: "success",
    });

    setName("");
  };

  return (
    <div>
      {/* side menu tailwind */}

      <TopHead />
      <ToastContainer />

      <div className="flex flex-col md:flex-row h-screen">
        <Sidemenu />
        <div className="flex-1 overflow-hidden">
          <div className="p-4 pt-16">
            <div className="flex justify-between items-center mb-4">
              <div>
                <h3 className="py-2 font-bold text-rose-700">
                  Tambah sub-brand
                </h3>
              </div>
            </div>

            <div className="w-1/4">
              <Input
                label="Nama"
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />

              <label className="block py-2 text-sm">Brand</label>
              <select
                onChange={(e) => setBrand(e.target.value)}
                className="w-full block px-2 py-1 border border-gray-300 text-sm rounded-md focus:outline-none focus:border-rose-500">
                {brands.length > 0 &&
                  brands.map((item, index) => (
                    <option value={item.id}>{item.name}</option>
                  ))}
              </select>

              <div className="py-2"></div>

              {loading ? (
                <LoadingBackdrop />
              ) : (
                <>
                  <ButtonBack
                    label="Kembali"
                    onClick={() => {
                      navigate("/subbrand");
                    }}
                  />
                  <ButtonSuccess label="Simpan" onClick={submit} />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubBrandAdd;

import React from "react";

import TopHead from "../Layouts/TopHead";
import Sidemenu from "../Layouts/Sidemenu";
import TableGrid from "../../components/TableGrid";
import LoadingBackdrop from "../../components/LoadingBackdrop";
import {
  Input,
  Button,
  ButtonError,
  ButtonSuccess,
  ButtonBack,
} from "../../components/Form";

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Config from "../../config/Config";
import Api from "../../services/Api";

function GroupAdd() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");

  const [permissions, setPermissions] = useState([]);

  const menuList = [
    {
      name: "Dashboard",
      label: "Dashboard",
    },
    {
      name: "User",
      label: "User",
    },
    {
      name: "Group",
      label: "Group",
    },
    {
      name: "Product",
      label: "Product",
    },
    {
      name: "Category",
      label: "Category",
    },
    {
      name: "Brand",
      label: "Brand",
    },
    {
      name: "SubBrand",
      label: "Sub Brand",
    },
    {
      name: "Series",
      label: "Series",
    },
    {
      name: "License",
      label: "Lisensi",
    },
    {
      name: "Type",
      label: "Tipe",
    },
    {
      name: "Sales",
      label: "Penjualan",
    },
    {
      name: "PurchaseOrder",
      label: "Purchase Order",
    },
    {
      name: "PackingList",
      label: "Packing List",
    },
    {
      name: "Purchasing",
      label: "Pembelian",
    },
    {
      name: "Stock",
      label: "Stok",
    },
    {
      name: "Template",
      label: "Template",
    },
    {
      name: "ItemRequest",
      label: "Permintaan Barang",
    },
    {
      name: "Warehouse",
      label: "Gudang",
    },
    {
      name: "Supplier",
      label: "Supplier",
    },
    {
      name: "Customer",
      label: "Pelanggan",
    },
  ];

  useEffect(() => {
    setLoading(true);

    var menus = [];
    menuList.map((menu) => {
      menus.push({
        name: menu.name,
        label: menu.label,
        can_view: false,
        can_add: false,
        can_edit: false,
        can_delete: false,
      });
    });

    setPermissions(menus);
    setLoading(false);
  }, []);

  const submit = () => {
    setLoading(true);

    // create post group
    Api.post(Config.api_url + "/data/group", {
      name: name,
      permissions: permissions,
    })
      .then((response) => {
        console.log(response);

        if (response) {
          toast("Data berhasil disimpan", {
            type: "success",
          });
        } else {
          toast("Data gagal disimpan", {
            type: "error",
          });
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        toast("Data gagal disimpan", {
          type: "error",
        });
        setLoading(false);
      });

    setName("");
  };

  return (
    <div>
      {/* side menu tailwind */}
      <TopHead />
      <ToastContainer />
      <div className="flex flex-col md:flex-row h-screen">
        <Sidemenu />
        <div className="flex-1 overflow-auto">
          <div className="p-4 pt-16">
            <div className="flex justify-between items-center mb-4">
              <div>
                <h3 className="py-2 font-bold text-rose-700">Tambah group</h3>
              </div>
            </div>

            <div className="w-1/3 pb-8">
              <Input
                label="Nama"
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />

              <div className="py-4">
                <table className="table-auto w-full">
                  <thead>
                    <tr>
                      <th className="border px-4 py-2">Menu</th>
                      <th className="border px-4 py-2">View</th>
                      <th className="border px-4 py-2">Add</th>
                      <th className="border px-4 py-2">Edit</th>
                      <th className="border px-4 py-2">Delete</th>
                    </tr>
                  </thead>

                  <tbody>
                    {permissions.map((permission, index) => (
                      <tr key={index}>
                        <td className="border px-4 py-2 w-96">
                          {permission.label}
                        </td>
                        <td className="border px-4 py-2">
                          <input
                            type="checkbox"
                            checked={permission.can_view}
                            onChange={(e) => {
                              var newPermissions = [...permissions];
                              newPermissions[index].can_view = e.target.checked;
                              setPermissions(newPermissions);
                            }}
                          />
                        </td>
                        <td className="border px-4 py-2">
                          <input
                            type="checkbox"
                            checked={permission.can_add}
                            onChange={(e) => {
                              var newPermissions = [...permissions];
                              newPermissions[index].can_add = e.target.checked;
                              setPermissions(newPermissions);
                            }}
                          />
                        </td>
                        <td className="border px-4 py-2">
                          <input
                            type="checkbox"
                            checked={permission.can_edit}
                            onChange={(e) => {
                              var newPermissions = [...permissions];
                              newPermissions[index].can_edit = e.target.checked;
                              setPermissions(newPermissions);
                            }}
                          />
                        </td>
                        <td className="border px-4 py-2">
                          <input
                            type="checkbox"
                            checked={permission.can_delete}
                            onChange={(e) => {
                              var newPermissions = [...permissions];
                              newPermissions[index].can_delete =
                                e.target.checked;
                              setPermissions(newPermissions);
                            }}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="py-2"></div>
              {loading ? (
                <LoadingBackdrop />
              ) : (
                <>
                  <ButtonBack
                    label="Kembali"
                    onClick={() => {
                      navigate("/group");
                    }}
                  />
                  <ButtonSuccess label="Simpan" onClick={submit} />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GroupAdd;

import React from "react";

import TopHead from "../Layouts/TopHead";
import Sidemenu from "../Layouts/Sidemenu";
import TableGrid from "../../components/TableGrid";
import LoadingBackdrop from "../../components/LoadingBackdrop";
import { NumericFormat } from "react-number-format";
import { PatternFormat } from "react-number-format";

import {
  Input,
  InputXs,
  Button,
  ButtonError,
  ButtonSuccess,
  ButtonBack,
  ButtonSm,
  Textarea,
} from "../../components/Form";

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Config from "../../config/Config";
import Api from "../../services/Api";

function OpnameAdd() {
  const navigate = useNavigate();

  const today = new Date();

  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(
    today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate()
  );

  // get purchase order
  const [purchaseOrders, setPurchaseOrders] = useState([]);

  const [totalReceived, setTotalReceived] = useState(0);

  const [purchaseOrder, setPurchaseOrder] = useState(null);
  const [purchaseOrderItems, setPurchaseOrderItems] = useState([]);

  const [warehouses, setWarehouses] = useState([]);
  const [warehouse, setWarehouse] = useState("");

  const [note, setNote] = useState("");

  const [modalAddPo, setModalAddPo] = useState(false);

  const [searchPo, setSearchPo] = useState("");
  const [itemsPo, setItemsPo] = useState([]);

  const getWarehouses = async () => {
    setLoading(true);
    Api.get(Config.api_url + "/data/warehouse")

      .then((response) => {
        setWarehouses(response);
      })
      .catch((error) => {
        console.log(error);
      });
    setLoading(false);
  };

  useEffect(() => {
    getWarehouses();
  }, []);

  const submit = () => {
    // validation

    if (warehouse === "") {
      toast.error("Pilih dahulu gudang");
      return;
    }

    setLoading(true);

    // create post opname
    Api.post(Config.api_url + "/data/opname", {
      warehouse_id: warehouse,
    }).then((response) => {
      console.log(response);

      if (response) {
        toast.success("Opname berhasil dibuat");

        setTimeout(() => {
          navigate("/opname/view/" + response.id);
        }, 1000);
      } else {
        toast.error("Opname gagal dibuat");
      }
      setLoading(false);
    });
  };

  return (
    <div>
      {/* side menu tailwind */}
      <TopHead />
      <ToastContainer />
      <div className="flex flex-col md:flex-row h-screen">
        <Sidemenu />
        <div className="flex-1 overflow-scroll">
          <div className="p-4 pt-16">
            <div className="flex justify-between items-center mb-4">
              <div>
                <h3 className="py-2 font-bold text-rose-700">Tambah opname</h3>
              </div>
            </div>

            <div className="flex flex-col md:flex-row">
              <div className="w-full md:w-1/4 pr-4">
                <label className="block py-2 text-sm">Gudang</label>
                <select
                  onChange={(e) => setWarehouse(e.target.value)}
                  className="w-full block px-2 py-2 border border-gray-300 text-xs rounded-md focus:outline-none focus:border-rose-500">
                  <option value="">- Pilih gudang -</option>
                  {warehouses.length > 0 &&
                    warehouses.map((item) => (
                      <option value={item.id}>{item.name}</option>
                    ))}
                </select>
              </div>
            </div>

            <div className="flex flex-col md:flex-row">
              <div className="w-full md:w-1/2 pr-4 my-8 text-sm p-8 bg-orange-200 rounded">
                Stock opname akan membuat gudang yang dipilih tidak dapat
                menerima transaksi perubahan stok hingga status opname selesai
              </div>
            </div>

            <div className="flex-1"></div>

            {loading ? (
              <LoadingBackdrop />
            ) : (
              <>
                <ButtonBack label="Kembali" />
                <ButtonSuccess label="Buat Form Opname" onClick={submit} />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default OpnameAdd;

import React from "react";

import TopHead from "../Layouts/TopHead";
import Sidemenu from "../Layouts/Sidemenu";
import TableGrid from "../../components/TableGrid";
import LoadingBackdrop from "../../components/LoadingBackdrop";
import Modal from "../../components/Modal";
import { NumericFormat } from "react-number-format";
import { PatternFormat } from "react-number-format";

import {
  Input,
  InputXs,
  Button,
  ButtonError,
  ButtonSuccess,
  ButtonBack,
  Textarea,
} from "../../components/Form";

import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Config from "../../config/Config";
import Api from "../../services/Api";

function PackingListView() {
  const navigate = useNavigate();
  const { id } = useParams();

  const [loading, setLoading] = useState(true);
  const [packinglist, setPackingList] = useState({});

  const [modalAddPayment, setModalAddPayment] = useState(false);

  const today = new Date();

  const [payment, setPayment] = useState({
    purchase_order_id: id,
    date:
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate(),
    amount: "",
    note: "",
  });

  const getPackingList = async () => {
    setLoading(true);

    const response = await Api.get(Config.api_url + "/data/packinglist/" + id);
    console.log(response);

    setPackingList(response);
    setLoading(false);
  };

  const submitPayment = async () => {
    setLoading(true);

    const response = await Api.post(
      Config.api_url + "/data/packinglistpayment",
      payment
    );
    console.log(response);

    if (response) {
      toast.success("Payment added successfully");
      setModalAddPayment(false);
      getPackingList();

      setPayment({
        purchase_order_id: id,
        date:
          today.getFullYear() +
          "-" +
          (today.getMonth() + 1) +
          "-" +
          today.getDate(),
        amount: "",
        note: "",
      });
    } else {
      toast.error("Failed to add payment");
    }

    setLoading(false);
  };

  useEffect(() => {
    getPackingList();
  }, []);

  return (
    <div>
      {/* side menu tailwind */}
      <TopHead />
      <ToastContainer />
      <div className="flex flex-col md:flex-row h-screen">
        <Sidemenu />
        <div className="flex-1 overflow-scroll">
          <div className="p-4 pt-16">
            {loading ? (
              <LoadingBackdrop />
            ) : (
              <>
                <div className="flex justify-between items-center mb-4">
                  <div>
                    <h3 className="py-2 font-bold text-rose-700">
                      Packing List : {packinglist.number}
                    </h3>
                  </div>
                </div>

                <div className="flex flex-col md:flex-row">
                  <div className="w-full md:w-1/4 pr-4">
                    <label className="block py-1 text-sm">Tanggal</label>
                    {new Date(packinglist.date).toLocaleDateString()}
                  </div>
                  <div className="w-full md:w-1/4 pr-4">
                    <label className="block py-1 text-sm">Nomor PO</label>
                    {packinglist.po.map((po) => (
                      <div className="py-1 text-xs">{po.po.number}</div>
                    ))}
                  </div>
                </div>

                <div className="flex flex-col md:flex-row mt-4">
                  <div className="w-full md:w-3/4 pr-4">
                    <div className="border">
                      <table className="table-auto text-xs w-full">
                        <thead className="bg-gray-100">
                          <tr>
                            <th className="w-2 px-4 py-2">No</th>
                            <th className="text-left px-4 py-2">Nama</th>
                            <th className="w-32 text-left text-right  px-4 py-2">
                              QTY diterima
                            </th>
                          </tr>
                        </thead>

                        <tbody>
                          {packinglist.items.map((item, index) => (
                            <tr className="">
                              <td className="text-center py-1  px-4">
                                {index + 1}
                              </td>
                              <td className="py-1 px-4">{item.product.name}</td>
                              <td className="py-1 px-4 text-right">
                                {item.qty}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div className=" my-4 py-2">
                      <table className="table-auto text-sm w-full">
                        <tbody>
                          <tr className="">
                            <td
                              className="text-right px-4 font-bold "
                              colSpan="4">
                              Total
                            </td>
                            <td className="w-32 text-right px-4 ">
                              <NumericFormat
                                decimalSeparator=","
                                thousandsGroupStyle="thousand"
                                thousandSeparator="."
                                value={packinglist.total_collected}
                                className="font-bold text-right"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className="my-4">
                      <label className="block py-1 text-sm">Keterangan</label>
                      {packinglist.note}
                    </div>
                  </div>
                </div>
              </>
            )}

            <div className="flex-1"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PackingListView;

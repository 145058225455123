import React from "react";

import TopHead from "../Layouts/TopHead";
import Sidemenu from "../Layouts/Sidemenu";
import TableGrid from "../../components/TableGrid";
import LoadingBackdrop from "../../components/LoadingBackdrop";
import {
  Input,
  Button,
  ButtonError,
  ButtonSuccess,
  ButtonBack,
} from "../../components/Form";

import Select from "react-select";
import AsyncSelect from "react-select/async";

import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Config from "../../config/Config";
import Api from "../../services/Api";

function ProductEdit() {
  const navigate = useNavigate();

  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [sku, setSku] = useState("");
  const [brand, setBrand] = useState(null);
  const [brands, setBrands] = useState([]);
  const [subbrand, setSubbrand] = useState(null);
  const [subbrands, setSubbrands] = useState([]);
  const [category, setCategory] = useState(null);
  const [categories, setCategories] = useState([]);
  const [type, setType] = useState(null);
  const [types, setTypes] = useState([]);
  const [license, setLicense] = useState(null);
  const [licenses, setLicenses] = useState([]);
  const [series, setSeries] = useState(null);
  const [serieses, setSerieses] = useState([]);
  const [grade, setGrade] = useState(null);
  const [grades, setGrades] = useState(["Exclusive", "Regular", "Special"]);

  const [supplier, setSupplier] = useState(null);
  const [suppliers, setSuppliers] = useState([]);

  const [cost, setCost] = useState(0);
  const [price, setPrice] = useState(0);
  const [currency, setCurrency] = useState("IDR");
  const [currencies, setCurrencies] = useState([]);

  const [description, setDescription] = useState("");
  const [image, setImage] = useState("uploads/no-image.png");

  const [discount, setDiscount] = useState(0);
  const [discountFromDate, setDiscountFromDate] = useState(null);
  const [discountToDate, setDiscountToDate] = useState(null);

  const [warehouses, setWarehouses] = useState([]);

  const [productDiscounts, setProductDiscounts] = useState([]);
  const [productDiscountLocations, setProductDiscountLocations] = useState([]);

  const [consignment, setConsignment] = useState(0);

  const getSupplier = async () => {
    const response = await Api.get(
      Config.api_url + "/data/supplier?limit=1000"
    );
    console.log("suplier", response);
    setSuppliers(response);
  };

  const getBrand = async () => {
    const response = await Api.get(Config.api_url + "/data/brand?limit=1000");
    console.log("brand", response);
    setBrands(response);
  };

  // getcategory
  const getCategory = async () => {
    const response = await Api.get(
      Config.api_url + "/data/category?limit=1000"
    );
    console.log("category", response);
    setCategories(response);
  };

  // getsubbrand
  const getSubBrand = async (brandId) => {
    const response = await Api.get(
      Config.api_url + "/data/subbrand?limit=1000&filter=" + brandId
    );
    console.log("subbrands", response);
    setSubbrands(response);
  };

  // get series
  const getSeries = async () => {
    const response = await Api.get(Config.api_url + "/data/series?limit=1000");
    console.log("series", response);
    setSerieses(response);
  };

  // get type
  const getType = async () => {
    const response = await Api.get(Config.api_url + "/data/type?limit=1000");
    console.log("type", response);
    setTypes(response);
  };

  // get license
  const getLicense = async () => {
    const response = await Api.get(Config.api_url + "/data/license?limit=1000");
    console.log("license", response);
    setLicenses(response);
  };

  // get currency
  const getCurrency = async () => {
    const response = await Api.get(Config.api_url + "/data/currency");
    console.log("currency", response);
    setCurrencies(response);
  };

  const getWarehouse = async () => {
    const response = await Api.get(Config.api_url + "/data/warehouse");
    console.log("warehouse", response);
    setWarehouses(response);
  };

  useEffect(() => {
    getSupplier();
    getBrand();
    getCategory();

    getSeries();
    getType();
    getLicense();
    getCurrency();
    getWarehouse();

    Api.get(Config.api_url + "/data/product/" + id).then((response) => {
      console.log(response);
      setName(response.name);
      setSku(response.sku);
      setSupplier(response.supplier_id);
      setBrand(response.brand_id);

      // get subbrand
      getSubBrand(response.brand_id);

      setSubbrand(response.subbrand_id);
      setCategory(response.category_id);
      setType(response.type_id);
      setLicense(response.license_id);
      setSeries(response.series_id);
      setGrade(response.grade);
      setCost(response.cost);
      setPrice(response.price);
      setCurrency(response.currency_id);
      setDescription(response.description);
      setImage(response.image);
      setDiscount(response.discount);
      setDiscountFromDate(response.discount_from_date);
      setDiscountToDate(response.discount_to_date);
      setProductDiscounts(response.discounts);
      setProductDiscountLocations(response.discount_locations);
      setConsignment(response.is_consignment);
    });
  }, []);

  useEffect(() => {
    // get subbrand
    getSubBrand(brand);
  }, [brand]);

  const uploadImage = async (e) => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    const file = e.target.files[0];

    var formdata = new FormData();
    formdata.append("file", file, file.name);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(Config.api_url + "/data/product/upload", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        var data = JSON.parse(result);
        setImage(data.path);
      })
      .catch((error) => console.log("error", error));
  };

  const submit = () => {
    setLoading(true);

    // create post product
    Api.put(Config.api_url + "/data/product/" + id, {
      name: name,
      sku: sku,
      brand_id: brand,
      subbrand_id: subbrand,
      supplier_id: supplier,
      category_id: category,
      type_id: type,
      license_id: license,
      series_id: series,
      grade: grade,
      cost: cost,
      price: price,
      currency_id: currency,
      description: description,
      image: image,
      product_discounts: productDiscounts,
      product_discount_locations: productDiscountLocations,
      is_consignment: consignment,
    }).then((response) => {
      console.log(response);

      if (response) {
        toast("Data berhasil disimpan", {
          type: "success",
        });

        setTimeout(() => {
          navigate("/product");
        }, 1000);
      } else {
        toast("Data gagal disimpan", {
          type: "error",
        });
      }
      setLoading(false);
    });
  };

  return (
    <div>
      {/* side menu tailwind */}

      <TopHead />
      <ToastContainer />

      <div className="flex flex-col md:flex-row h-screen">
        <Sidemenu />
        <div className="flex-1 overflow-auto">
          <div className="p-4 pt-16">
            <div className="flex justify-between items-center mb-4">
              <div>
                <h3 className="py-2 font-bold text-rose-700">Edit produk</h3>
              </div>
            </div>

            <div>
              <div className="flex flex-col md:flex-row">
                <div className="w-full md:w-1/4 pr-4">
                  <Input
                    label="Nama"
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className="w-full md:w-1/4 pr-4">
                  <Input
                    label="SKU"
                    type="text"
                    value={sku}
                    onChange={(e) => setSku(e.target.value)}
                  />
                </div>
                <div className="w-full md:w-1/4 pr-4">
                  <label className="block py-2 text-sm">Supplier</label>
                  <select
                    onChange={(e) => {
                      setSupplier(e.target.value);
                      console.log(e.target.value);
                    }}
                    className="w-full block px-2 py-1 border border-gray-300 text-sm rounded-md focus:outline-none focus:border-rose-500">
                    {suppliers.length > 0 &&
                      suppliers.map((item) => (
                        <option
                          value={item.id}
                          selected={item.id == supplier ? true : false}>
                          {item.name}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="w-full md:w-1/4 pr-4">
                  <label className="block py-2 text-sm">Kategori</label>
                  <select
                    onChange={(e) => setCategory(e.target.value)}
                    className="w-full block px-2 py-1 border border-gray-300 text-sm rounded-md focus:outline-none focus:border-rose-500">
                    {categories.length > 0 &&
                      categories.map((item) => (
                        <option
                          value={item.id}
                          selected={item.id == category ? true : false}>
                          {item.name}
                        </option>
                      ))}
                  </select>
                </div>
              </div>

              <div className="flex flex-col md:flex-row">
                <div className="w-full md:w-1/4 pr-4">
                  <label className="block py-2 text-sm">Brand</label>
                  <select
                    onChange={(e) => setBrand(e.target.value)}
                    className="w-full block px-2 py-1 border border-gray-300 text-sm rounded-md focus:outline-none focus:border-rose-500">
                    {brands.length > 0 &&
                      brands.map((item) => (
                        <option
                          value={item.id}
                          selected={item.id == brand ? true : false}>
                          {item.name}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="w-full md:w-1/4 pr-4">
                  <label className="block py-2 text-sm">Sub-Brand</label>
                  <select
                    onChange={(e) => setSubbrand(e.target.value)}
                    className="w-full block px-2 py-1 border border-gray-300 text-sm rounded-md focus:outline-none focus:border-rose-500">
                    {subbrands.length > 0 &&
                      subbrands.map((item) => (
                        <option
                          value={item.id}
                          selected={item.id == subbrand ? true : false}>
                          {item.name}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="w-full md:w-1/4 pr-4">
                  <label className="block py-2 text-sm">Series</label>
                  <select
                    onChange={(e) => setSeries(e.target.value)}
                    className="w-full block px-2 py-1 border border-gray-300 text-sm rounded-md focus:outline-none focus:border-rose-500">
                    {serieses.length > 0 &&
                      serieses.map((item) => (
                        <option
                          value={item.id}
                          selected={item.id == series ? true : false}>
                          {item.name}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="w-full md:w-1/4 pr-4">
                  <label className="block py-2 text-sm">Lisensi</label>
                  <select
                    onChange={(e) => setLicense(e.target.value)}
                    className="w-full block px-2 py-1 border border-gray-300 text-sm rounded-md focus:outline-none focus:border-rose-500">
                    {licenses.length > 0 &&
                      licenses.map((item) => (
                        <option
                          value={item.id}
                          selected={item.id == license ? true : false}>
                          {item.name}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
              <div className="flex flex-col md:flex-row">
                <div className="w-full md:w-1/4 pr-4">
                  <label className="block py-2 text-sm">Tipe</label>
                  <select
                    onChange={(e) => setType(e.target.value)}
                    className="w-full block px-2 py-1 border border-gray-300 text-sm rounded-md focus:outline-none focus:border-rose-500">
                    {types.length > 0 &&
                      types.map((item) => (
                        <option
                          value={item.id}
                          selected={item.id == type ? true : false}>
                          {item.name}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="w-full md:w-1/4 pr-4">
                  <label className="block py-2 text-sm">Grade</label>
                  <select
                    onChange={(e) => setGrade(e.target.value)}
                    className="w-full block px-2 py-1 border border-gray-300 text-sm rounded-md focus:outline-none focus:border-rose-500">
                    {grades.length > 0 &&
                      grades.map((item) => (
                        <option
                          value={item}
                          selected={item == grade ? true : false}>
                          {item}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="w-full md:w-1/4 pr-4">
                  <label className="block py-2 text-sm">Konsinyasi</label>
                  <select
                    onChange={(e) => setConsignment(e.target.value)}
                    className="w-full block px-2 py-1 border border-gray-300 text-sm rounded-md focus:outline-none focus:border-rose-500">
                    <option value={0} selected={consignment == 0}>
                      {" "}
                      Tidak
                    </option>
                    <option value={1} selected={consignment == 1}>
                      Ya
                    </option>
                  </select>
                </div>
              </div>

              <h3 class="my-1 mt-8 font-bold">Harga</h3>
              <div className="flex flex-col md:flex-row">
                <div className="w-full md:w-1/4 pr-4">
                  <Input
                    label="HPP"
                    type="number"
                    value={cost}
                    onChange={(e) => setCost(e.target.value)}
                  />
                </div>
                <div className="w-full md:w-1/4 pr-4">
                  <Input
                    label="Harga jual"
                    type="number"
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                  />
                </div>
                <div className="w-full md:w-1/4 pr-4">
                  <label className="block py-2 text-sm">Mata uang</label>
                  <select
                    onChange={(e) => setCurrency(e.target.value)}
                    className="w-full block px-2 py-1 border border-gray-300 text-sm rounded-md focus:outline-none focus:border-rose-500">
                    {currencies.length > 0 &&
                      currencies.map((item) => (
                        <option
                          value={item.id}
                          selected={item.is_default ? "selected" : ""}>
                          {item.code}
                        </option>
                      ))}
                  </select>
                </div>
              </div>

              <h3 class="my-1 mt-8 font-bold">Deskripsi dan Gambar</h3>
              <div className="flex flex-col md:flex-row">
                <div className="w-full md:w-1/2 pr-4">
                  <label className="block py-2 text-sm">Deskripsi</label>
                  <textarea className="w-full h-40 block px-2 py-1 border border-gray-300 text-sm rounded-md focus:outline-none focus:border-rose-500"></textarea>
                </div>

                <div className="w-full md:w-1/4 pr-4">
                  <label className="block py-2 text-sm">Gambar</label>
                  <input
                    type="file"
                    onChange={uploadImage}
                    className="w-full block px-2 py-1 border border-gray-300 text-sm rounded-md focus:outline-none focus:border-rose-500"
                  />
                  <div className="border border-gray-300 rounded-md p-2 w-28 mt-2">
                    {image != "" ? (
                      <img src={Config.api_url + "/" + image} />
                    ) : (
                      <img src={Config.api_url + "/uploads/no-image.png"} />
                    )}
                  </div>
                </div>
              </div>

              <h3 class="my-1 mt-8 font-bold">Diskon Grup</h3>

              <div className="flex flex-col md:flex-row text-sm">
                <div className="w-full md:w-1/4 pr-4">Grup pelanggan</div>
                <div className="w-full md:w-32 pr-4">Diskon (%)</div>
                <div className="w-full md:w-32 pr-4"> + Diskon tambahan(%)</div>
                <div className="w-full md:w-32 pr-4">
                  {" "}
                  Tanggal Berlaku
                  <p className="text-xs mb-4 text-red-600">
                    Kosongan jika tidak ada masa berlaku
                  </p>
                </div>
                <div className="w-full md:w-1/4 pr-4"></div>
              </div>

              {productDiscounts.map((item, index) => (
                <div className="flex flex-col md:flex-row text-sm">
                  <div className="w-full md:w-1/4 pr-4">
                    {item.customer_group.name}
                  </div>
                  <div className="w-full md:w-32 pr-4">
                    <Input
                      type="number"
                      value={item.discount}
                      onChange={(e) => {
                        setProductDiscounts(
                          productDiscounts.map((item, i) => {
                            if (i === index) {
                              return {
                                ...item,
                                discount: e.target.value,
                              };
                            }
                            return item;
                          })
                        );
                      }}
                    />
                  </div>
                  <div className="w-full md:w-32 pr-4">
                    <Input
                      type="number"
                      value={item.discount_plus}
                      onChange={(e) => {
                        setProductDiscounts(
                          productDiscounts.map((item, i) => {
                            if (i === index) {
                              return {
                                ...item,
                                discount_plus: e.target.value,
                              };
                            }
                            return item;
                          })
                        );
                      }}
                    />
                  </div>
                  <div className="w-full md:w-32 pr-4">
                    <Input
                      value={item.from_date}
                      type="date"
                      onChange={(e) => {
                        setProductDiscounts(
                          productDiscounts.map((item, i) => {
                            if (i === index) {
                              return {
                                ...item,
                                from_date: e.target.value,
                              };
                            }
                            return item;
                          })
                        );
                      }}
                    />
                  </div>
                  <div className="w-full md:w-32 pr-4">
                    <Input
                      type="date"
                      value={item.to_date}
                      onChange={(e) => {
                        setProductDiscounts(
                          productDiscounts.map((item, i) => {
                            if (i === index) {
                              return {
                                ...item,
                                to_date: e.target.value,
                              };
                            }
                            return item;
                          })
                        );
                      }}
                    />
                  </div>
                  <div className="w-full md:w-1/4 pr-4"></div>
                </div>
              ))}

              <h3 class="my-1 mt-8 font-bold">Diskon Khusus Lokasi</h3>
              <p className="text-xs mb-4 text-red-600">
                Kosongan jika tidak ada diskon khusus
              </p>
              <div className="flex flex-col md:flex-row text-sm">
                <div className="w-full md:w-1/4 pr-4">Grup pelanggan</div>
                <div className="w-full md:w-32 pr-4">Diskon (%)</div>
                <div className="w-full md:w-32 pr-4"> + Diskon tambahan(%)</div>
                <div className="w-full md:w-48 pr-4">Lokasi gudang</div>
                <div className="w-full md:w-32 pr-4">
                  {" "}
                  Tanggal Berlaku
                  <p className="text-xs mb-4 text-red-600">
                    Kosongan jika tidak ada masa berlaku
                  </p>
                </div>
                <div className="w-full md:w-32 pr-4"></div>
              </div>

              {productDiscountLocations.map((item, index) => (
                <div className="flex flex-col md:flex-row text-sm">
                  <div className="w-full md:w-1/4 pr-4">
                    {item.customer_group.name}
                  </div>
                  <div className="w-full md:w-32 pr-4">
                    <Input
                      type="number"
                      value={item.discount}
                      onChange={(e) => {
                        setProductDiscountLocations(
                          productDiscountLocations.map((item, i) => {
                            if (i === index) {
                              return {
                                ...item,
                                discount: e.target.value,
                              };
                            }
                            return item;
                          })
                        );
                      }}
                    />
                  </div>
                  <div className="w-full md:w-32 pr-4">
                    <Input
                      type="number"
                      value={item.discount_plus}
                      onChange={(e) => {
                        setProductDiscountLocations(
                          productDiscountLocations.map((item, i) => {
                            if (i === index) {
                              return {
                                ...item,
                                discount_plus: e.target.value,
                              };
                            }
                            return item;
                          })
                        );
                      }}
                    />
                  </div>
                  <div className="w-full md:w-48 pr-4">
                    <label className="block py-2 text-sm"></label>
                    <select
                      onChange={(e) => {
                        setProductDiscountLocations(
                          productDiscountLocations.map((item, i) => {
                            if (i === index) {
                              return {
                                ...item,
                                warehouse_id: e.target.value,
                              };
                            }
                            return item;
                          })
                        );
                      }}
                      className="w-full block px-2 py-1 border border-gray-300 text-sm rounded-md focus:outline-none focus:border-rose-500">
                      {warehouses.length > 0 &&
                        warehouses.map((item) => (
                          <option value={item.id}>{item.name}</option>
                        ))}
                    </select>
                  </div>
                  <div className="w-full md:w-32 pr-4">
                    <Input
                      type="date"
                      value={item.from_date}
                      onChange={(e) => {
                        setProductDiscountLocations(
                          productDiscountLocations.map((item, i) => {
                            if (i === index) {
                              return {
                                ...item,
                                from_date: e.target.value,
                              };
                            }
                            return item;
                          })
                        );
                      }}
                    />
                  </div>
                  <div className="w-full md:w-32 pr-4">
                    <Input
                      type="date"
                      value={item.to_date}
                      onChange={(e) => {
                        setProductDiscountLocations(
                          productDiscountLocations.map((item, i) => {
                            if (i === index) {
                              return {
                                ...item,
                                to_date: e.target.value,
                              };
                            }
                            return item;
                          })
                        );
                      }}
                    />
                  </div>
                </div>
              ))}

              <div className="py-2"></div>

              {loading ? (
                <LoadingBackdrop />
              ) : (
                <>
                  <ButtonBack
                    label="Kembali"
                    onClick={() => {
                      navigate("/product");
                    }}
                  />
                  <ButtonSuccess label="Simpan" onClick={submit} />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductEdit;

import React from "react";
import ReactDOM from "react-dom";
import { Link, Outlet } from "react-router-dom";

function Layout() {
  return (
    <div>
      <Outlet />
    </div>
  );
}

export default Layout;

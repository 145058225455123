import React from "react";
import logo from "../../assets/logo.png";

function TopHead(props) {
  return (
    <div className="fixed w-full bg-gray-100 text-sm z-40">
      <div className="flex flex-col md:flex-row">
        <div className="bg-gray-800 text-gray-100 flex flex-col justify-between md:w-64 overflow-auto px-6 py-3">
          <a href="#" className="text-lg font-bold text-white flex">
            <img src={logo} alt="logo" className="w-12 mr-2" /> Multitoys
          </a>
        </div>
        <div className="flex-1 flex justify-between">
          <div className="px-4 pt-4">
            {" "}
            {props.title ? props.title : "Multitoys"}
          </div>
          <div className="px-4 pt-4">
            <a href="/login" className="text-rose-700 hover:text-gray-900">
              Logout
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TopHead;
